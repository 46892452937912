import {Checkbox, Input, RadioBox, Year, Month, Day, Select} from "../../../../Components";
import {FaStarOfLife} from "react-icons/fa";
import {useEffect} from "react";
import {setData} from "../reducer";
import {setRequest} from "../../../ShoppingCart/AddProduct/reducer";
import {useDispatch} from "react-redux";
import {useState} from "react";
import * as app from "../../../../Services/AppService";

let selectedId,
   colorId,
   colorSquareId,
   imgSquareId,
   fileN = "",
   fileInput = document.getElementById("uploadFile");

// validate uploaded file is a png or jpg file
function fileValidation(img) {
   if (img.target.files[0]) {
      let filePath = fileInput.value;
      let fileName = filePath.split("\\").pop().split("/").pop();
      let allowedExtensions = /(\.png|\.jpg)$/i;
      if (!allowedExtensions.exec(filePath)) {
         alert(fileName + " has an invalid extension.\nValid extension(s): .png or .jpg");
         fileInput.value = null;
         return false;
      } else {
         fileN = filePath.split("\\").pop();
         return true;
      }
   }
}

function resetImg(id, parentModel, changeParent) {
   let tempModel = {...parentModel};
   tempModel[`product_attribute_${id}`] = "";
   changeParent(tempModel);
   fileN = "";
   fileInput.value = null;
}

const originalPrice = [];
function priceAdjustment(valueAdjustment, AddedValue, valuesType, optionId, parentId, checked) {
   originalPrice.push(valueAdjustment.product_price.price_value);
   if (originalPrice.length > 1) {
      originalPrice.splice(1);
   }
   let tempProductPrice = {...valueAdjustment.product_price};
   let TotalPrice = tempProductPrice.price_value;
   switch (valuesType) {
      case 1:
         if (!selectedId) {
            valueAdjustment.product_attributes.map((att) => {
               if (att.id === parentId) {
                  att.values.map((item) => {
                     if (item.id === optionId) {
                        selectedId = optionId;
                        TotalPrice += AddedValue;
                     }
                  });
               }
            });
         } else {
            if (optionId !== selectedId) {
               valueAdjustment.product_attributes.map((att) => {
                  if (att.id === parentId) {
                     att.values.map((item) => {
                        if (item.id === selectedId) {
                           TotalPrice -= item.price_adjustment_value;
                           selectedId = optionId;
                           TotalPrice += AddedValue;
                        }
                     });
                  }
               });
            }
         }
         break;
      case 2:
         if (colorId) {
            if (optionId !== colorId) {
               valueAdjustment.product_attributes.map((att) => {
                  if (att.id === parentId) {
                     att.values.map((item) => {
                        if (item.id === colorId) {
                           TotalPrice -= item.price_adjustment_value;
                           colorId = optionId;
                           TotalPrice += AddedValue;
                        }
                     });
                  }
               });
            }
         } else {
            valueAdjustment.product_attributes.map((att) => {
               if (att.id === parentId) {
                  att.values.map((item) => {
                     if (item.id === optionId) {
                        colorId = optionId;
                        TotalPrice += AddedValue;
                     }
                  });
               }
            });
         }
         break;
      case 3:
         valueAdjustment.product_attributes.map((att) => {
            if (att.id === parentId) {
               att.values.map((item) => {
                  if (item.id === optionId) {
                     if (checked) {
                        TotalPrice = TotalPrice + AddedValue;
                     } else {
                        TotalPrice = TotalPrice - AddedValue;
                     }
                  }
               });
            }
         });
         break;
      case 40:
         if (colorSquareId) {
            if (optionId !== colorSquareId) {
               valueAdjustment.product_attributes.map((att) => {
                  if (att.id === parentId) {
                     att.values.map((item) => {
                        if (item.id === colorSquareId) {
                           TotalPrice -= item.price_adjustment_value;
                           colorSquareId = optionId;
                           TotalPrice += AddedValue;
                        }
                     });
                  }
               });
            }
         } else {
            valueAdjustment.product_attributes.map((att) => {
               if (att.id === parentId) {
                  att.values.map((item) => {
                     if (item.id === optionId) {
                        colorSquareId = optionId;
                        TotalPrice += AddedValue;
                     }
                  });
               }
            });
         }
         break;
      case 45:
         if (imgSquareId) {
            if (optionId !== imgSquareId) {
               valueAdjustment.product_attributes.map((att) => {
                  if (att.id === parentId) {
                     att.values.map((item) => {
                        if (item.id === imgSquareId) {
                           TotalPrice -= item.price_adjustment_value;
                           imgSquareId = optionId;
                           TotalPrice += AddedValue;
                        }
                     });
                  }
               });
            }
         } else {
            valueAdjustment.product_attributes.map((att) => {
               if (att.id === parentId) {
                  att.values.map((item) => {
                     if (item.id === optionId) {
                        imgSquareId = optionId;
                        TotalPrice += AddedValue;
                     }
                  });
               }
            });
         }
         break;
   }
   tempProductPrice.price_value = TotalPrice;
   return tempProductPrice;
}

// push all options into an array and return it
function Options(productAttributes, type, adjustmentModel, parentModel, changeParent, getFile) {
   const dispatch = useDispatch();
   let alloptions = [];
   if (productAttributes.values.length > 0) {
      productAttributes.values.map((value, subindex, parentIndex = 0) => {
         switch (productAttributes.attribute_control_type) {
            // Dropdown menu
            case 1:
               alloptions.push(value);
               break;
            // Radio Box
            case 2:
               alloptions.push(
                  <RadioBox
                     Name={`${productAttributes.name}-${type}-Name`}
                     Label={value.price_adjustment !== null ? value.name + " " + value.price_adjustment : value.name}
                     Key={`${productAttributes.name}-${value.id}-Key`}
                     Value={parentModel[`product_attribute_${productAttributes.id}`] === value.id}
                     OnChange={(checked) => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}`] = value.id;
                        dispatch(setRequest(tempModel));

                        changeParent(tempModel);
                        // price changer
                        let AddedValue = value.price_adjustment_value;
                        let valueAdjustment = {...adjustmentModel};
                        valueAdjustment.product_price = priceAdjustment(
                           valueAdjustment,
                           AddedValue,
                           productAttributes.attribute_control_type,
                           value.id,
                           productAttributes.id
                        );
                        valueAdjustment.product_price.price =
                           valueAdjustment.product_price.price_value.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                           }) + valueAdjustment.product_price.price.replace(/[0-9]|,|\./g, "");

                        // image changer
                        if (value.image_squares_picture_model.image_url !== null) {
                           valueAdjustment.default_picture_model = value.image_squares_picture_model;
                        }
                        dispatch(setData(valueAdjustment));
                     }}
                  />
               );
               break;
            // Check Box
            case 3:
               alloptions.push(
                  <Checkbox
                     Name={`${productAttributes.name}-${type}-Name-${subindex}`}
                     ID={`${productAttributes.name}-${value.id}`}
                     Label={value.name}
                     Key={`${productAttributes.name}-${value.id}-Key`}
                     Value={parentModel[`product_attribute_${productAttributes.id}`].includes(value.id)}
                     OnChange={(checked) => {
                        let tempModel = {...parentModel};
                        if (tempModel[`product_attribute_${productAttributes.id}`].includes(value.id)) {
                           tempModel[`product_attribute_${productAttributes.id}`] = tempModel[
                              `product_attribute_${productAttributes.id}`
                           ].filter((item) => item !== value.id);
                        } else {
                           let tempCheck = [...tempModel[`product_attribute_${productAttributes.id}`]];
                           tempCheck.push(value.id);
                           tempModel[`product_attribute_${productAttributes.id}`] = tempCheck;
                        }
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);

                        let valueAdjustment = {...adjustmentModel};

                        // price changer
                        let AddedValue = value.price_adjustment_value;
                        valueAdjustment.product_price = priceAdjustment(
                           valueAdjustment,
                           AddedValue,
                           productAttributes.attribute_control_type,
                           value.id,
                           productAttributes.id,
                           checked
                        );
                        valueAdjustment.product_price.price =
                           valueAdjustment.product_price.price_value.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                           }) + valueAdjustment.product_price.price.replace(/[0-9]|,|\./g, "");
                        if (value.image_squares_picture_model.image_url !== null) {
                           valueAdjustment.default_picture_model = value.image_squares_picture_model;
                        }
                        dispatch(setData(valueAdjustment));
                     }}
                  />
               );
               break;
            // Input file {in case there is more than one Input}
            case 4:
               alloptions.push(
                  <Input
                     Label={"testing"}
                     ContainerClass={"bg-dark vh-100"}
                     Placeholder={"test"}
                     key={`${productAttributes.name}-${value.id}-Key`}
                     Model={parentModel[`product_attribute_${productAttributes.id}`]}
                     OnChange={(newValue) => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.Id}`] = newValue;
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                     }}
                  />
               );
               break;
            // same goes for the textarea in case there is more than one
            case 10:
               alloptions.push(
                  <textarea
                     value={parentModel[`product_attribute_${productAttributes.id}`]}
                     key={`${productAttributes.id}` || ""}
                     onChange={(newValue) => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}`] = newValue.target.value;
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                     }}
                     className="form-control w-25 mx-auto"
                  />
               );
               break;
            // Color squares
            case 40:
               alloptions.push(
                  <div
                     key={`${subindex}-${value.id}`}
                     className="d-inline-block m-2 border-2 border-dark"
                     style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: value.color_squares_rgb,
                        cursor: "pointer",
                     }}
                     onClick={() => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}`] = value.id;
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                        // price changer
                        let AddedValue = value.price_adjustment_value;
                        let valueAdjustment = {...adjustmentModel};
                        valueAdjustment.product_price = priceAdjustment(
                           valueAdjustment,
                           AddedValue,
                           productAttributes.attribute_control_type,
                           value.id,
                           productAttributes.id
                        );
                        valueAdjustment.product_price.price =
                           valueAdjustment.product_price.price_value.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                           }) + valueAdjustment.product_price.price.replace(/[0-9]|,|\./g, "");
                        // image changer
                        if (value.image_squares_picture_model.image_url !== null) {
                           valueAdjustment.default_picture_model = value.image_squares_picture_model;
                        }
                        dispatch(setData(valueAdjustment));
                     }}
                  ></div>
               );
               break;
            // Image squares
            case 45:
               alloptions.push(
                  <div
                     key={`${subindex}-${value.id}`}
                     className="d-inline-block m-2 border-dark border-1 position-relative"
                     style={{width: "40px", height: "40px", cursor: "pointer"}}
                     onClick={() => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}`] = value.id;
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                        // price changer
                        let AddedValue = value.price_adjustment_value;
                        let valueAdjustment = {...adjustmentModel};
                        valueAdjustment.product_price = priceAdjustment(
                           valueAdjustment,
                           AddedValue,
                           productAttributes.attribute_control_type,
                           value.id,
                           productAttributes.id
                        );
                        valueAdjustment.product_price.price =
                           valueAdjustment.product_price.price_value.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                           }) + valueAdjustment.product_price.price.replace(/[0-9]|,|\./g, "");

                        // image changer
                        if (value.image_squares_picture_model.image_url !== null) {
                           valueAdjustment.default_picture_model = value.image_squares_picture_model;
                        }
                        dispatch(setData(valueAdjustment));
                     }}
                  >
                     <img src={value.image_squares_picture_model.thumb_image_url} className="w-100 h-100" alt={"Img"} />
                  </div>
               );
               break;
            // Read-only Check Box
            case 50:
               alloptions.push(
                  <Checkbox
                     Name={`${productAttributes.name}-${type}-Name-${subindex}`}
                     ID={`${productAttributes.name}-${value.id}`}
                     Label={value.name}
                     Key={`${productAttributes.name}-${value.id}-Key`}
                     Value={value.is_pre_selected}
                     Disabled={true}
                  />
               );
               break;
         }
      });
   }
   // for attributes that don't have values like:{input, file Upload ,date picker,...}
   else {
      switch (productAttributes.attribute_control_type) {
         // simple input {text}
         case 4:
            alloptions.push(
               <Input
                  LabelClass={"d-none"}
                  MainContainerClass={"w-25 mx-auto border-3"}
                  Model={parentModel[`product_attribute_${productAttributes.id}`] || ""}
                  OnChange={(newValue) => {
                     let tempModel = {...parentModel};
                     tempModel[`product_attribute_${productAttributes.id}`] = newValue;
                     dispatch(setRequest(tempModel));
                     changeParent(tempModel);
                  }}
               />
            );
            break;
         // textarea
         case 10:
            alloptions.push(
               <textarea
                  value={parentModel[`product_attribute_${productAttributes.id}`] || ""}
                  key={`${productAttributes.id}`}
                  onChange={(newValue) => {
                     let tempModel = {...parentModel};
                     tempModel[`product_attribute_${productAttributes.id}`] = newValue.target.value;
                     dispatch(setRequest(tempModel));
                     changeParent(tempModel);
                  }}
                  className="form-control w-25 mx-auto"
               />
            );
            break;
         // date picker
         case 20:
            alloptions.push(
               <div className="d-flex justify-content-center gap-2">
                  <Year
                     Model={parentModel[`product_attribute_${productAttributes.id}_year`]}
                     OnChange={(newYear) => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}_year`] = Number(newYear);
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                     }}
                  />
                  <Month
                     Model={parentModel[`product_attribute_${productAttributes.id}_month`]}
                     OnChange={(newMonth) => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}_month`] = Number(newMonth);
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                     }}
                  />
                  <Day
                     Model={parentModel[`product_attribute_${productAttributes.id}_day`]}
                     OnChange={(newDay) => {
                        let tempModel = {...parentModel};
                        tempModel[`product_attribute_${productAttributes.id}_day`] = Number(newDay);
                        dispatch(setRequest(tempModel));
                        changeParent(tempModel);
                     }}
                  />
               </div>
            );
            break;
         // file upload
         case 30:
            alloptions.push(
               <div>
                  <input
                     type="file"
                     id="uploadFile"
                     className="d-none"
                     onChange={(newValue) => {
                        if (fileValidation(newValue)) {
                           let tempModel = {...parentModel};
                           tempModel[`product_attribute_${productAttributes.id}`] = newValue.target.files[0];
                           changeParent(tempModel);
                        }
                     }}
                  />
                  <label
                     className="mx-1 py-1 px-3 btn text-light p-0 btn-sm"
                     style={{backgroundColor: "#800"}}
                     htmlFor="uploadFile"
                  >
                     {app.translate("common.fileuploader.upload")}
                  </label>
                  <br />
                  {fileN !== "" && (
                     <div>
                        <span className="fw-bold bg-light mt-2 p-1 rounded-4 shadow-sm">{fileN}</span>
                        <span
                           className="text-truncate d-inline-block"
                           style={{cursor: "pointer"}}
                           onClick={() => {
                              resetImg(productAttributes.id, parentModel, changeParent);
                           }}
                        >
                           x
                        </span>
                     </div>
                  )}
               </div>
            );
            break;
      }
   }
   return alloptions;
}

function ShowAttributes(productAttributes, type, adjustmentModel, parentModel, changeParent, getFile) {
   const dispatch = useDispatch();

   // attribute is a dropdown show values as options inside it
   if (type === 1) {
      return (
         <Select
            Options={Options(productAttributes, type, adjustmentModel, parentModel, getFile)}
            Key="id"
            Value="name"
            Model={parentModel[`product_attribute_${productAttributes.id}`]}
            LabelClass={"text-dark fw-bold mb-3"}
            containerClass={"w-25 mx-auto"}
            OnChange={(newValue) => {
               let tempModel = {...parentModel};
               tempModel[`product_attribute_${productAttributes.id}`] = Number(newValue);
               dispatch(setRequest(tempModel));
               changeParent(tempModel);
               productAttributes.values.map((selectedValue) => {
                  if (selectedValue.id === Number(newValue)) {
                     let valueAdjustment = {...adjustmentModel};
                     valueAdjustment.product_price = priceAdjustment(
                        valueAdjustment,
                        selectedValue.price_adjustment_value,
                        productAttributes.attribute_control_type,
                        selectedValue.id,
                        productAttributes.id
                     );
                     valueAdjustment.product_price.price =
                        valueAdjustment.product_price.price_value.toLocaleString(undefined, {
                           minimumFractionDigits: 2,
                           maximumFractionDigits: 2,
                        }) + valueAdjustment.product_price.price.replace(/[0-9]|,|\./g, "");
                     dispatch(setData(valueAdjustment));
                  }
               });
            }}
         />
      );
   }
   // show attribute as they come from options function
   else {
      return Options(productAttributes, type, adjustmentModel, parentModel, changeParent, getFile);
   }
}

export default function ProductAttributes(props) {
   const dispatch = useDispatch();
   const [firstRender, setFirstRender] = useState(true);
   const [shoppingCartModel, setShoppingCartModel] = useState();
   function setValues() {
      props.apiData.product_attributes.map((value) => {
         switch (value.attribute_control_type) {
            case 1:
               value.values.map((selectedValue) => {
                  if (selectedValue.is_pre_selected) {
                     selectedId = selectedValue.id;
                  }
               });
               break;
            case 2:
               value.values.map((color) => {
                  if (color.is_pre_selected) {
                     colorId = color.id;
                  }
               });
               break;
            case 40:
               value.values.map((colorSquare) => {
                  if (colorSquare.is_pre_selected) {
                     colorSquareId = colorSquare.id;
                  }
               });
               break;
            case 45:
               value.values.map((imgSquare) => {
                  if (imgSquare.is_pre_selected) {
                     imgSquareId = imgSquare.id;
                  }
               });
               break;
         }
      });
      let AllAttributes = props.apiData.product_attributes;
      let Value = props.apiData.product_attributes.map((item) => item.values);
      let defaultValues = {};
      let selected = [];
      for (let i = 0; i < Value.length; i++) {
         if (Value[i].length > 0) {
            Value[i].map((item) => {
               if (AllAttributes[i].attribute_control_type === 1) {
                  if (item.default_value !== null) {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = item.default_value;
                  }
               }
               if (AllAttributes[i].attribute_control_type === 2) {
                  if (item.is_pre_selected === true) {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = item.id;
                  }
               } else if (AllAttributes[i].attribute_control_type === 3) {
                  if (item.is_pre_selected === true) {
                     selected.push(item.id);
                  }
                  for (let j = 0; j < selected.length; j++) {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = [selected[j]];
                  }
               } else if (AllAttributes[i].attribute_control_type === 40) {
                  if (item.is_pre_selected === true) {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = item.id;
                  }
               } else if (AllAttributes[i].attribute_control_type === 45) {
                  if (item.is_pre_selected === true) {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = item.id;
                  } else {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = 0;
                  }
               } else if (AllAttributes[i].attribute_control_type === 50) {
                  let selected = [];
                  if (item.is_pre_selected === true) {
                     selected.push(item.id);
                  }
                  for (let j = 0; j < selected.length; j++) {
                     defaultValues[`product_attribute_${AllAttributes[i].id}`] = [selected[j]];
                  }
               }
            });
         } else {
            if (AllAttributes[i].attribute_control_type === 4) {
               defaultValues[`product_attribute_${AllAttributes[i].id}`] = AllAttributes[i].default_value;
            } else if (AllAttributes[i].attribute_control_type === 10) {
               defaultValues[`product_attribute_${AllAttributes[i].id}`] = AllAttributes[i].default_value;
            } else if (AllAttributes[i].attribute_control_type === 20) {
               defaultValues[`product_attribute_${AllAttributes[i].id}_day`] = Number(AllAttributes[i].selected_day);
               defaultValues[`product_attribute_${AllAttributes[i].id}_month`] = Number(
                  AllAttributes[i].selected_month
               );
               defaultValues[`product_attribute_${AllAttributes[i].id}_year`] = Number(AllAttributes[i].selected_year);
            } else if (AllAttributes[i].attribute_control_type === 30) {
               defaultValues[`product_attribute_${AllAttributes[i].id}`] = "";
            }
         }
      }
      defaultValues[`addtocart_${props.apiData.id}.EnteredQuantity`] = Number(
         props.apiData.add_to_cart.entered_quantity
      );
      // set default image and price
      initializeDefault(props.apiData);
      setShoppingCartModel(dispatch(setRequest(defaultValues)).payload);
   }

   useEffect(() => {
      if (props.apiData.id !== undefined) {
         if (firstRender) {
            setFirstRender(false);
            setValues();
         } else {
            let temp = {...shoppingCartModel};
            temp[`addtocart_${props.apiData.id}.EnteredQuantity`] = parseInt(
               props.apiData.add_to_cart.entered_quantity
            );
            dispatch(setRequest(temp));
            setShoppingCartModel(temp);
         }
      }
   }, [props.apiData.add_to_cart.entered_quantity, props.apiData.id]);

   function initializeDefault(tempData) {
      let data = {...tempData};
      let picture_model = {...data.default_picture_model};
      let price_model = {...data.product_price};
      let TotalPrice = data.product_price.price_value;
      tempData.product_attributes.length > 0 &&
         tempData.product_attributes.map((attribute) => {
            attribute.values.map((value, index) => {
               switch (attribute.attribute_control_type) {
                  case 2:
                     if (value.is_pre_selected && attribute.values[attribute.values.length - index]) {
                        TotalPrice += value.price_adjustment_value;
                     }
                     break;
                  case 3:
                     if (value.is_pre_selected) {
                        TotalPrice += value.price_adjustment_value;
                     }
                     break;
               }
               if (value.is_pre_selected) {
                  if (value.image_squares_picture_model.image_url !== null) {
                     picture_model = value.image_squares_picture_model;
                  }
               }
            });
         });
      price_model.price_value = TotalPrice;
      price_model.price =
         price_model.price_value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) +
         price_model.price.replace(/[0-9]|,|\./g, "");
      data.product_price = price_model;
      data.default_picture_model = picture_model;
      dispatch(setData(data));
   }
   if (shoppingCartModel) {
      let parentModel = shoppingCartModel;
      return props.Attributes.map((productAttributes, index) => {
         return (
            <div className="mt-4" key={index}>
               <p className="fw-bold small">
                  {productAttributes.text_prompt !== null ? productAttributes.text_prompt : productAttributes.name}
                  {productAttributes.is_required && <FaStarOfLife className="text-danger small mb-2 ms-2" />}
               </p>
               {ShowAttributes(
                  productAttributes,
                  productAttributes.attribute_control_type,
                  props.apiData,
                  parentModel,
                  (newModel) => {
                     setShoppingCartModel(newModel);
                  },
                  props.getFile
               )}
            </div>
         );
      });
   }
}
