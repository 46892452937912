import React from "react";
import Container from "react-bootstrap/Container";
import * as app from "../Services/AppService";
import "./Footer.css";
const Footer = () => {
   const Data = [
      {
         id: 1,
         title: "footer.information",
         links: [
            {title: "footer.PrivacyPolicy", href: "/PrivacyNotice"},
            {title: "footer.TermsUse", href: "/ConditionsOfUse"},
            {title: "footer.CompanyInfo", href: "/AboutUs"},
         ],
      },
      {
         id: 2,
         title: "footer.customerservice",
         links: [
            {title: "pagetitle.search", href: "/"},
            {title: "pagetitle.recentlyviewedproducts", href: "/recentlyViewedProducts"},
            {title: "pagetitle.newproducts", href: "/NewProducts"},
         ],
      },
      {
         id: 3,
         title: "footer.myaccount",
         links: [
            {title: "footer.myaccount", href: "/MyAccount"},
            {title: "shoppingcart", href: "/cart"},
            {title: "account.customerorders", href: "/Orders"},
            {title: "wishlist", href: "/wishList"},
            {title: "account.customeraddresses", href: "/Addresses"},
            {title: "pagetitle.vendors.apply", href: "/vendor/apply"},
         ],
      },
   ];

   const Follow = {
      title: "footer.followus",
      Icons: [
         {img: "https://sawa.express/Themes/OswaTech/Content/OswaTechImages/social-twitter.png", href: ""},
         {img: "https://sawa.express/Themes/OswaTech/Content/OswaTechImages/social-facebook.png", href: ""},
      ],
   };
   return (
      <footer
         style={{
            marginTop: "450px",
            background: "url(https://sawa.express/Themes/OswaTech/Content/OswaTechImages/Footer.png)",
         }}
      >
         <div className="footer  mt-auto py-3 position-relative" style={{background: "#272727d1"}}>
            <Container>
               <div className="row">
                  {Data.map((ele, index) => (
                     <div key={index} className="col-lg-3 col-12 my-2">
                        <ul className="row">
                           <div className=" text-primary mb-3 text-cente">
                              <strong>{app.translate(ele.title)}</strong>
                              <div className="border border-primary my-1 d-md-none"></div>
                           </div>
                           <div className="row">
                              {ele.links.length > 0 &&
                                 ele.links.map((item, index2) => (
                                    <li
                                       key={index2}
                                       className={"inactive list-unstyled my-1 " + (ele.id == 3 && "col-xxl-6 col-12")}
                                    >
                                       <a className="text-light text-decoration-none mt-3 link-info " href={item.href}>
                                          {app.translate(item.title)}
                                       </a>
                                    </li>
                                 ))}
                           </div>
                        </ul>
                     </div>
                  ))}
                  <div className="col-lg-3 col-12 my-2">
                     <ul className="d-block">
                        <div className=" text-primary mb-3 text-cente">
                           <strong className="">{app.translate(Follow.title)}</strong>
                           <div className="border border-primary my-1 d-md-none"></div>
                        </div>
                        {Follow.Icons &&
                           Follow.Icons.length > 0 &&
                           Follow.Icons.map((icon, index1) => (
                              <a key={index1} className="text-light mt-2" href={icon.href}>
                                 <img className="me-3" src={icon.img} />
                              </a>
                           ))}
                     </ul>
                  </div>
               </div>
               <div className="row border-top pt-2 text-white">
                  <p className="col-md-auto text-center">
                     {app
                     .translate("content.copyrightnotice")
                     .replace("{0}", "2023")
                     .replace("{1}", app.translate("sawaExpress"))}
                  </p>
                  <div className="col-md"></div>
                  <p className="col-md-auto text-center">
                     Powerd By
                     <a className="text-primary text-decoration-none" href="https://www.facebook.com/oswatech/">
                        {" " + app.translate("oswatech")}
                     </a>
                  </p>
                  <p></p>
               </div>
            </Container>
         </div>
      </footer>
   );
};

export default Footer;
