import {createAsyncThunk} from "@reduxjs/toolkit";
import GetProductsByTag from "./api";
let id;
export function setRequest(Id) {
   id = Id;
}
export const fetchData = createAsyncThunk("GetProductsByTag/fetchData", async () => {
   const response = await GetProductsByTag(id);
   return response.data;
});
