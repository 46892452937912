// No request model or parameters

export const Type = 'Object';
export const Model = {
    Params: {},
    Body: {
        is_guest: true,
        username: "",
        email: "",
        password: ""
    }
}