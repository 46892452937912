export const Type = 'ArrayOfObject';

export const Model = [
    {
        Disabled: false,
        Group: null,
        Selected: false,
        Text: "",
        Value: ""
    }
]



