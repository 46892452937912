import Button from "../Button";
import PropTypes from "prop-types";
import { BsFillHeartFill } from "react-icons/bs";
import { DiGitCompare } from "react-icons/di";
import { FaShoppingCart } from "react-icons/fa";
import * as app from "../../Services/AppService";
import Rating from "../Rating";
import { Link, useLocation } from "react-router-dom";
import ButtonHandling from "../../Services/UI/ButtonHandling";
import { AddProductButton } from "../../Features/ShoppingCart/AddProduct/component";
import COMPARE_PRODUCT from "../../Features/Products/CompareProducts";
import ApiStatusManager from "../../Services/ApiStatusManager";
import { useDispatch } from "react-redux";
import "./Card.css"
function CasuallyCard(props) {
   function handleImageOnClick(event) {
      if (props.ImgClicked && typeof props.ImgClicked == "function") props.ImgClicked(event);
   }
   const location = useLocation();
   const dispatch = useDispatch();

   async function AddToCompare(Id) {
      COMPARE_PRODUCT.ACTIONS.AddProductToCompareList(
         Id,
         (resSuccess) => {
            app.ShowTopMessageAlert("products.producthasbeenaddedtocomparelist.link", "compareProducts", "success")
            dispatch(ApiStatusManager("Products", "Compare_Prdoucts"))
         },
         (resError) => app.ShowTopMessageAlert(`Error ${resError}`, "", "danger"),
      );
   }
   return (
      <>
         <div className="m-2 shadow rounded-4">
            <div className="row p-3">
               <div className="col-md-4 d-flex justify-content-center">
                  <div className="position-relative">
                     <AddProductButton type="Wishlist" ProductID={props.productId} ProductName={props.se_name} />
                     <Link
                        to={`/ProductDetails/${props.LinkTo}/${props.productId}`}
                        state={{ id: props.productId, from: location.pathname }}
                     >
                        <img className="rounded w-100" height={"250px"} src={props.Picture} />
                     </Link>
                  </div>
               </div>
               <div className="col-md-7 p-3 d-flex flex-column justify-content-between" style={{ height: "250px" }}>
                  <div className="row card-text text-center">
                     <Link
                        to={`/ProductDetails/${props.LinkTo}/${props.productId}`}
                        state={{ id: props.productId, from: location.pathname }}
                        className="text-decoration-none text-dark"
                     >
                        <h5 className="mb-2">{props.Title}</h5>
                     </Link>
                     <Rating Class="d-flex justify-content-center" Model={props.Rating} />
                     <p className=" fs-6 text-dark d-none d-lg-block">{props.Details}</p>
                     <p> {props.Price}</p>
                  </div>
                  <div className={"row justify-content-center my-4"}>
                     <Button Icon="" Label="" Class=" mt-1 bg-light shadow mx-1 w-auto" Clicked={() => AddToCompare(props.productId)}>
                        <DiGitCompare className="m-1 fs-4" />
                     </Button>
                     <AddProductButton
                        type="ShoppingCart"
                        ProductID={props.productId}
                        ProductName={props.se_name}
                        Class="btn mt-1 btn-primary mx-1 d-flex pt-2 px-2  justify-content-center w-auto"
                     />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
export default CasuallyCard;

CasuallyCard.propTypes = {
   Clicked: PropTypes.func.isRequired,
   Class: PropTypes.string,
   Disabled: PropTypes.bool,
   Title: PropTypes.number,
   Icon: PropTypes.string,
   Rating: PropTypes.number,
   Price: PropTypes.string,
   Picture: PropTypes.string,
   ImgClicked: PropTypes.func,
   Details: PropTypes.string,
   LinkTo: PropTypes.string,
   productId: PropTypes.number,
   Product: PropTypes.bool,
};
CasuallyCard.defaultProps = {
   Class: "btn-primary shadow-sm bg-dark",
   Disabled: false,
   Title: "title",
   Label: "",
   Icon: "",
   Picture: "Group 4.png",
   Price: "",
   productId: 27,
};
