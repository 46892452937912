import React from 'react';
import ReactStars from "react-rating-stars-component";
import PropTypes from "prop-types";
import { FaStarHalfAlt, FaStar } from 'react-icons/fa';

export default function Rating(props) {

    return (
        <>
            <div className={`${props.Class}`}>
                <ReactStars
                    count={props.Count}
                    size={props.Size}
                    value={props.Model}
                    edit={false}
                    isHalf={true}
                    emptyIcon={<FaStarHalfAlt />}
                    halfIcon={<FaStarHalfAlt />}
                    fullIcon={<FaStar />}
                    activeColor="#ffd700"
                />
            </div>
        </>

    );
}


Rating.propTypes = {
    Count: PropTypes.number,
    Size: PropTypes.number,
    Model: PropTypes.number,
    Class: PropTypes.string
};
Rating.defaultProps = {
    Count: 5.0,
    Size: 24,
    Model: 0,
    Class: ""
};

