import CustomProperties from "../../../../Models/CustomProperties";
export const Type = 'Object';

export const GetModel = {
    model: {
        shipping_methods: [
            {
                shipping_rate_computation_method_system_name: "",
                name: "",
                description: "",
                fee: "",
                selected: true,
                shipping_option: {
                    shipping_rate_computation_method_system_name: "",
                    rate: 0,
                    name: "",
                    description: "",
                    transit_days: null,
                    is_pickup_in_store: false
                },
                custom_properties: {...CustomProperties}
            }
        ],
        notify_customer_about_shipping_from_multiple_locations: false,
        warnings: [],
        display_pickup_in_store: false,
        pickup_points_model: null,
        custom_properties: {...CustomProperties}
    },
    redirect_to_method: "",
    id: 0
}
//post
export const PostModel = {
    update_section_model: {
        name: "",
        view_name: "",
        model: {
            payment_view_component_name: "",
            display_order_totals: false,
            custom_properties: {...CustomProperties}
        }
    },
    wrong_billing_address: false,
    goto_section: ""
}