import { createAsyncThunk } from '@reduxjs/toolkit';
import * as APIs from './api';
import * as app from '../../../Services/AppService';


export const fetchData = createAsyncThunk('GetAvatar/fetchData', async () => {
    const response = await APIs.GetCustomerAvatar();
    return response.data
})

export const UploadAvatar = (Body, Params, OnSuccessFunc, OnErrorFunc) => {
    app.ChangeLinesSpinnerStatus(true)
    APIs.UploadAvatar(`"${Body.ImageBase64}"`, Params)
        .then(result => {
            if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
                OnSuccessFunc(result)
        })
        .catch(error => {
            app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
            if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
                OnErrorFunc(error)
        })
    app.ChangeLinesSpinnerStatus(false)
}