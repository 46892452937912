import { createSlice } from '@reduxjs/toolkit';
import { CoreInitialState } from '../../../Models/CoreInitialState';
import F_ALSOPURCHASED from '.';
const initialState =
{
    ...CoreInitialState,
    data: F_ALSOPURCHASED.MODELS.Response.Type === 'ArrayOfObject' ?
        [F_ALSOPURCHASED.MODELS.Response.Model] : F_ALSOPURCHASED.MODELS.Response.Model,
}

const reducers = {
}

function extraReducers(builder) {
    builder
        .addCase(F_ALSOPURCHASED.ACTIONS.fetchData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(F_ALSOPURCHASED.ACTIONS.fetchData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.data = action.payload
        })
        .addCase(F_ALSOPURCHASED.ACTIONS.fetchData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
}

export const AlsoPurchasedProducts = createSlice({
    name: 'AlsoPurchasedProducts',
    initialState,
    reducers: { ...reducers },
    extraReducers
})
export default AlsoPurchasedProducts.reducer;