import {lazy} from "react";
export const publicRoutes = [
   {path: "/", element: lazy(() => import("../Pages/Home"))},
   {path: "*", element: lazy(() => import("../Pages/PageNotFound"))},
   {path: "AboutUs", element: lazy(() => import("../Pages/AboutUs"))},
   {path: "PrivacyNotice", element: lazy(() => import("../Pages/PrivacyNotice"))},
   {path: "/ProductDetails/:name/:id", element: lazy(() => import("../Pages/ProductDetails/ProductDetails"))},
   {path: "/Register", element: lazy(() => import("../Pages/Auth/Register"))},
   {path: "/Login", element: lazy(() => import("../Pages/Auth/Login"))},
   {path: "/NewVendor", element: lazy(() => import("../Pages/NewVendor"))},
   {path: "wishList", element: lazy(() => import("../Pages/WishList"))},
   {path: "cart", element: lazy(() => import("../Pages/ShoppingCart"))},
   {path: "ShoppingCartDialog", element: lazy(() => import("../Pages/ShoppingCartDialog"))},
   {path: "categoryDetails", element: lazy(() => import("../Pages/CategoryDetails"))},
   {path: "AddOrUpdateAddress", element: lazy(() => import("../Pages/MyAccount/Addresses/AddOrUpdateAddress"))},
   {path: "Addresses", element: lazy(() => import("../Pages/MyAccount/Addresses/Addresses"))},
   {
      path: "/categoryDetails/manufacturer/:ManufacturerId",
      element: lazy(() => import("../Pages/CategoryDetails/ManufacturerProducts")),
   },
   {path: "/categoryDetails/vendor/:vendorId", element: lazy(() => import("../Pages/CategoryDetails/VendorProducts"))},
   {path: "/compareProducts", element: lazy(() => import("../Pages/CompareProducts"))},
   {path: "/categoryDetails/Product/:tagId", element: lazy(() => import("../Pages/CategoryDetails/TagProducts"))},
   {
      path: "/categoryDetails/manufacturer/:ManufacturerId",
      element: lazy(() => import("../Pages/CategoryDetails/ManufacturerProducts")),
   },
   {
      path: "/categoryDetails/vendor-info/:vendorId",
      element: lazy(() => import("../Pages/CategoryDetails/ContatctVendor")),
   },
   {
      path: "/categoryDetails/Categorie/:CategorieId",
      element: lazy(() => import("../Pages/CategoryDetails/CategoryProducts")),
   },
   {path: "/MyAccount", element: lazy(() => import("../Pages/MyAccount/AccountList/MyAccount"))},
   {path: "/Orders", element: lazy(() => import("../Pages/MyAccount/Orders/Orders"))},
   {path: "/OrderDetails/:Id", element: lazy(() => import("../Pages/MyAccount/Orders/OrderDetails"))},
   {path: "/ChangePassword", element: lazy(() => import("../Pages/MyAccount/ChangePassword"))},
   {path: "/BackSubscriptions", element: lazy(() => import("../Pages/MyAccount/BackSubscriptions/BackSubscriptions"))},
   {path: "/MyProductReviews", element: lazy(() => import("../Pages/MyAccount/MyProductReviews/MyProductReviews"))},
   {path: "/CustomerInfo", element: lazy(() => import("../Pages/MyAccount/CustomerInfo/CustomerInfo"))},
   {
      path: "/DownloadableProducts",
      element: lazy(() => import("../Pages/MyAccount/DownloadableProducts/DownloadableProducts")),
   },
   {path: "/Avatar", element: lazy(() => import("../Pages/MyAccount/Avatar/Avatar"))},
   {
      path: "/OrderDetails/ShipmentsDetails/:Id",
      element: lazy(() => import("../Pages/MyAccount/Shipments/ShipmentDetails")),
   },
   {
      path: "/Orderdetails/ShipmentTracking/:Id",
      element: lazy(() => import("../Pages/MyAccount/Shipments/TrackingOnMap")),
   },
   {path: "/checkout", element: lazy(() => import("../Pages/Checkout/Checkout"))},
   {path: "/ShippingMethod", element: lazy(() => import("../Pages/Checkout/ShippingMethod"))},
   {path: "/Completed", element: lazy(() => import("../Pages/Checkout/Completed"))},
   {path: "/ProductReviews/:Id", element: lazy(() => import("../Pages/ProductDetails/ProductReviews"))},
   {path: "/NewProducts", element: lazy(() => import("../Pages/NewProducts"))},
   {path: "/Manufacturer", element: lazy(() => import("../Pages/CategoryDetails/Manufacturers"))},
   {path: "/Vendors", element: lazy(() => import("../Pages/CategoryDetails/Vendors"))},
   {path: "/recentlyViewedProducts",element:lazy(()=>import("../Pages/CategoryDetails/RecentlyViewedProducts"))},
   {path: "/VendorsCategories", element: lazy(() => import("../Pages/VendorsCategories"))},
   {path: "/PopularTags", element: lazy(() => import("../Pages/CategoryDetails/PopularTags"))},
   {path: "/vendor/apply", element: lazy(() => import("../Pages/ApplyForVendorAccount"))},
   {path: "/ConditionsOfUse", element: lazy(() => import("../Pages/ConditionsOfUse"))},
   {path: "/Search", element: lazy(() => import("../Pages/CategoryDetails/Search/SearchResults"))},
];
export const privateRoutes = [];
