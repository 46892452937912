import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "../Button";
import Warning from '../Warning'
import * as app from '../../Services/AppService'
import { FaShoppingCart } from 'react-icons/fa';
function Card(props) {
   
   var lang = app.getCookie("language", "").split(",")[0];
   return (
      <div className="d-flex border border-1 border-light shadow shadow-1 rounded rounded-2 py-md-0 py-2 my-5 h-auto">
         <div className="d-block d-md-flex col-12">
            <div className="col-md row py-4">
               <div className="col-xl-5 col-lg-12 text-center align-self-center">
                  <Link to={`/ProductDetails/${props.Name}/${props.ProductId}`} state={{ id: props.ProductId }}>
                     <img className="mw-100 w-auto" src={props.Picture} alt={props.Name} />
                  </Link>
               </div>
               <ul className="col-xl-7 col-lg-12 m-auto list-unstyled text-xl-start text-center">
                  <li className="fw-bold fs-4">
                     <Link
                        to={`/ProductDetails/${props.Name}/${props.ProductId}`}
                        className="text-dark text-decoration-none"
                        state={{ id: props.ProductId }}
                     >
                        {props.Name}
                     </Link>
                  </li>
                  <li className="my-2 fs-5">{props.Price}</li>
                  {props.Warnings.length !== 0 && props.Warnings.map((war) => <Warning Show={true} Message={war} />)}
               </ul>
            </div>
            <div
               className={
                  "border-2 border-primary w-auto p-0 d-none d-md-block mx-2 " +
                  (lang === " ar" ? " border-start" : " border-end")
               }
            ></div>
            {(props.Role === "Checkout" || props.Role === "ShoppingCard") && (
               <>
                  <div className="col-md row">
                     <p className="text-center m-auto fw-bold">
                        {app.translate("shoppingcart.vendorname")}{" "}
                        {lang === "ar" ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}{" "}
                        <span className="fw-normal">{props.Vendor}</span>
                     </p>
                  </div>
                  <div
                     className={
                        "border-2 border-primary w-auto p-0 d-none d-md-block mx-2 " +
                        (lang === " ar" ? " border-start" : " border-end")
                     }
                  ></div>
               </>
            )}

            <div className={"col-md d-flex gap-2 align-items-center justify-content-center my-2"}>
               {props.Role === "Checkout" ?
                  <div className="m-auto">{app.translate('shoppingcart.quantity') + " : " + props.Quantity}</div>
                  :
                  (props.Role === "wishlist" || props.Role === "ShoppingCard") &&
                  <>
                     <div className="fw-bold"> {app.translate('shoppingcart.quantity')} {(lang === "ar" ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />)}</div>
                     <div>
                        <div className="d-flex bg-primary rounded">
                           <Button Label={"-"} Clicked={props.Minus} Class="py-1 fw-bold text-dark btn" />
                           <div className="col m-auto fw-bold">{props.Quantity}</div>
                           <Button Label="+" Clicked={props.Plus} Class="py-1 fw-bold text-dark btn" />
                        </div>
                     </div>
                  </>
               }
            </div>
            <div className={"border-2 border-primary w-auto p-0 d-none d-md-block mx-2 " + (lang === " ar" ? " border-start" : " border-end")}></div>
            <div className="col-md row">
               <p className="text-center m-auto py-2 fw-bold">{app.translate('shoppingcart.totals.ordertotal') + " : "}<span className="fw-normal">{props.DetailsPrice}</span></p>
            </div>
            <div className={"border-2 border-primary w-auto p-0 d-none d-md-block mx-2 " + (lang === " ar" ? " border-start" : " border-end")}></div>
            {(props.Role === "wishlist" || props.Role === "ShoppingCard") &&
               <div className="col-md row align-items-center justify-content-center">
                  <Button Label=''
                     Clicked={props.Delete}
                     Class="bg-danger btn btn-sm col-3"
                  >
                     <RiDeleteBin6Line size={23} className='text-white my-2' />
                  </Button>
                  {props.Role === "wishlist" &&
                     <Button
                        Class='bg-primary btn text-dark btn-outline-primary w-auto py-2 mx-1'
                        Clicked={props.AddToCard}>
                        <FaShoppingCart className="fs-2 float-start" size={25} />
                     </Button>
                  }
               </div>
            }
         </div>
         <div className={"border-2 border-primary w-auto p-0 d-block d-md-none" + (lang === " ar" ? " border-start" : " border-end")}></div>
      </div >
   )
}
export default Card;

Card.propTypes = {
   Id: PropTypes.number,
   Name: PropTypes.string,
   Picture: PropTypes.string,
   Color: PropTypes.string,
   DetailsPrice: PropTypes.string,
   Vendor: PropTypes.string,
   Quantity: PropTypes.number,
   Price: PropTypes.string,
   Currency: PropTypes.string,
   Role: PropTypes.string,
   Delete: PropTypes.func,
   Plus: PropTypes.func,
   Minus: PropTypes.func,
   AddToCard: PropTypes.func,
};
Card.defaultProps = {
   Id: 0,
   Name: "APPLE IPHONE 13 PRO MAX 5G",
   Picture: "logo512.png",
   Color: "Green",
   DetailsPrice: " 4,699.00 د.إ.‏ incl tax",
   Vendor: "Car Showroom",
   Quantity: 1,
   Price: "",
   Currency: "",
   //Role: "Checkout || wishlist || ShoppingCard "
};
