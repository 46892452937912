import {createAsyncThunk} from "@reduxjs/toolkit";
import GetCategoryById from "./api";
let id;
export function setRequest(Id) {
   id = Id;
}
export const fetchData = createAsyncThunk("CategoryProducts/fetchData", async () => {
   const response = await GetCategoryById(id);
   return response.data;
});
