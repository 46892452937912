import CustomProperties from "../../../../Models/CustomProperties";
export const Type = 'Object';
export const Model =
{
    update_section_model: {
        name: "",
        view_name: "",
        model: {
            terms_of_service_on_order_confirm_page: false,
            terms_of_service_popup: true,
            min_order_total_warning: null,
            warnings: [],
            custom_properties: { ...CustomProperties }
        }
    },
    wrong_billing_address: false,
    goto_section: ""
}