import {combineReducers, configureStore} from "@reduxjs/toolkit";
import CategoriesReducer from "../../Features/Categories/Reducer";
import VendorsReducer from "../../Features/Vendor/reducer";
import CommonReducer from "../../Features/Common/Reducer";
import AuthReducer from "../../Features/Auth/reducer";
import CustomerReducer from "../../Features/Customer/reducer";
import ProductsReducer from "../../Features/Products/Reducer";
import ManufacturersReducer from "../../Features/Manufacturers/reducer";
import ShoppingCartReducer from "../../Features/ShoppingCart/reducer";
import OrdersReducer from "../../Features/Orders/reducer";
import WishListReducer from "../../Features/WishList/reducer";
import SpinnerReducer from "../Reducers/Spinners/reducer";
import AlertReducer from "../Reducers/Alert/reducer";
import CheckoutReducer from "../../Features/Checkout/reducer";
import SearchReducer from "../../Features/Search/reducer";

const reducers = combineReducers({
   Categories: CategoriesReducer,
   Common: CommonReducer,
   Products: ProductsReducer,
   Vendors: VendorsReducer,
   Auth: AuthReducer,
   Customer: CustomerReducer,
   Manufacturers: ManufacturersReducer,
   ShoppingCart: ShoppingCartReducer,
   Orders: OrdersReducer,
   Checkout: CheckoutReducer,
   WishList: WishListReducer,
   Spinners: SpinnerReducer,
   Alerts: AlertReducer,
   Search: SearchReducer,
});

export default configureStore({
   reducer: reducers,
});
