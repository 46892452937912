import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';

export const WishListModel = {
  customer_guid: "00000000-0000-0000-0000-000000000000",
  customer_fullname: null,
  email_wishlist_enabled: true,
  show_sku: false,
  show_product_images: false,
  is_editable: false,
  display_add_to_cart: true,
  display_tax_shipping_info: false,
  items: [],
  warnings: [],
  custom_properties: { ...CustomProperties }
}

//ToCardFromWishList

export const ToCardModel = {
  customer_guid: "00000000-0000-0000-0000-000000000000",
  customer_fullname: null,
  email_wishlist_enabled: true,
  show_sku: false,
  show_product_images: false,
  is_editable: false,
  display_add_to_cart: true,
  display_tax_shipping_info: false,
  items: [],
  warnings: [],
  custom_properties: { ...CustomProperties }
}