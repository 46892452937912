import * as http from '../../../Services/http'

const GetUrl = `/Customer/Avatar`;
const PostUrl = "/Customer/UploadAvatar";

export function GetCustomerAvatar() {
  return http.Get(GetUrl)
}

export function UploadAvatar(Body, Params) {
  return http.Post(PostUrl, Body, Params);
}

