import CustomProperties from "../../../../Models/CustomProperties";
import pictureModel from "../../../../Models/Picture";
export const Type = "Object";
export const Model = {
   product_template_view_path: "",
   product_details_model: {
      default_picture_zoom_enabled: true,
      default_picture_model: {
         //  image_url: "",
         //  thumb_image_url: "",
         //  full_size_image_url: "",
         //  title: "",
         //  alternate_text: "",
         //  custom_properties: {
         //     additionalProp1: "",
         //     additionalProp2: "",
         //     additionalProp3: "",
         ...pictureModel,
      },
      picture_models: [
         {
            image_url: "",
            thumb_image_url: "",
            full_size_image_url: "",
            title: "",
            alternate_text: "",
            custom_properties: {...CustomProperties},
         },
      ],
      name: "",
      short_description: "",
      full_description: "",
      meta_keywords: "",
      meta_description: "",
      meta_title: "",
      se_name: "",
      visible_individually: true,
      product_type: "SimpleProduct",
      show_sku: true,
      sku: "",
      show_manufacturer_part_number: true,
      manufacturer_part_number: "",
      show_gtin: true,
      gtin: "",
      show_vendor: true,
      vendor_model: {
         name: "",
         se_name: "",
         id: 0,
         custom_properties: {...CustomProperties},
      },
      has_sample_download: true,
      gift_card: {
         is_gift_card: true,
         recipient_name: "",
         recipient_email: "",
         sender_name: "",
         sender_email: "",
         message: "",
         gift_card_type: "Virtual",
         custom_properties: {...CustomProperties},
      },
      is_ship_enabled: true,
      is_free_shipping: true,
      free_shipping_notification_enabled: true,
      delivery_date: "",
      is_rental: true,
      rental_start_date: "",
      rental_end_date: "",
      available_end_date: "",
      manage_inventory_method: "DontManageStock",
      stock_availability: "",
      display_back_in_stock_subscription: true,
      email_a_friend_enabled: true,
      compare_products_enabled: true,
      page_share_code: "",
      product_price: {
         currency_code: "",
         old_price: "",
         price: "",
         price_with_discount: "",
         price_value: 0,
         customer_enters_price: true,
         call_for_price: true,
         call_for_pricing_phone_number: "",
         product_id: 0,
         hide_prices: true,
         is_rental: true,
         rental_price: "",
         display_tax_shipping_info: true,
         base_price_p_ang_v: "",
         custom_properties: {...CustomProperties},
      },
      add_to_cart: {
         product_id: 0,
         entered_quantity: 0,
         order_minimum_quantity: 0,
         order_maximum_quantity: 0,
         minimum_quantity_notification: "",
         allowed_quantities: [
            {
               disabled: true,
               group: {
                  disabled: true,
                  name: "",
               },
               selected: true,
               text: "",
               value: "",
            },
         ],
         customer_enters_price: true,
         customer_entered_price: 0,
         customer_entered_price_range: "",
         disable_buy_button: true,
         disable_wishlist_button: true,
         is_rental: true,
         available_for_pre_order: true,
         pre_order_availability_start_date_time_utc: "",
         pre_order_availability_start_date_time_user_time: "",
         updated_shopping_cart_item_id: 0,
         update_shopping_cart_item_type: "ShoppingCart",
         custom_properties: {...CustomProperties},
      },
      breadcrumb: {
         enabled: true,
         product_id: 0,
         product_name: "",
         product_se_name: "",
         category_breadcrumb: [
            {
               name: "",
               se_name: "",
               number_of_products: 0,
               include_in_top_menu: true,
               sub_categories: [""],
               have_sub_categories: true,
               route: "",
               picture: {
                  ImageUrl: "",
                  ThumbImageUrl: "",
                  FullSizeImageUrl: "",
                  Title: "",
                  AlternateText: "",
                  CustomProperties: {...CustomProperties},
               },
               id: 0,
               custom_properties: {...CustomProperties},
            },
         ],
         custom_properties: {...CustomProperties},
      },
      product_tags: [
         {
            name: "",
            se_name: "",
            product_count: 0,
            id: 0,
            custom_properties: {...CustomProperties},
         },
      ],
      product_attributes: [
         {
            product_id: 0,
            product_attribute_id: 0,
            name: "",
            description: "",
            text_prompt: "",
            is_required: true,
            default_value: "",
            selected_day: 0,
            selected_month: 0,
            selected_year: 0,
            has_condition: true,
            allowed_file_extensions: [""],
            attribute_control_type: 0,
            values: [
               {
                  name: "",
                  color_squares_rgb: "",
                  image_squares_picture_model: {
                     image_url: "",
                     thumb_image_url: "",
                     full_size_image_url: "",
                     title: "",
                     alternate_text: "",
                     custom_properties: {...CustomProperties},
                  },
                  price_adjustment: "",
                  price_adjustment_use_percentage: true,
                  price_adjustment_value: 0,
                  is_pre_selected: true,
                  picture_id: 0,
                  customer_enters_qty: true,
                  quantity: 0,
                  id: 0,
                  custom_properties: {...CustomProperties},
               },
            ],
            id: 0,
            custom_properties: {...CustomProperties},
         },
      ],
      product_specification_model: {
         groups: [
            {
               name: "",
               attributes: [
                  {
                     name: "",
                     values: [
                        {
                           attribute_type_id: 0,
                           value_raw: "",
                           color_squares_rgb: "",
                           custom_properties: {...CustomProperties},
                        },
                     ],
                     id: 0,
                     custom_properties: {...CustomProperties},
                  },
               ],
               id: 0,
               custom_properties: {...CustomProperties},
            },
         ],
         custom_properties: {...CustomProperties},
      },
      product_manufacturers: [
         {
            name: "",
            se_name: "",
            is_active: true,
            id: 0,
            custom_properties: {...CustomProperties},
         },
      ],
      product_review_overview: {
         product_id: 0,
         product_rating: 0,
         rating_sum: 0,
         total_reviews: 0,
         allow_customer_reviews: true,
         can_add_new_review: true,
         stars_details: [
            {
               star_type: 0,
               percentage_rating: 0,
               raters_count: 0,
               custom_properties: {...CustomProperties},
            },
         ],
         custom_properties: {...CustomProperties},
      },
      product_estimate_shipping: {
         product_id: 0,
         request_delay: 0,
         enabled: true,
         country_id: 0,
         state_province_id: 0,
         zip_postal_code: "",
         use_city: true,
         city: "",
         available_countries: [
            {
               disabled: true,
               group: {
                  disabled: true,
                  name: "",
               },
               selected: true,
               text: "",
               value: "",
            },
         ],
         available_states: [
            {
               disabled: true,
               group: {
                  disabled: true,
                  name: "",
               },
               selected: true,
               text: "",
               value: "",
            },
         ],
         custom_properties: {...CustomProperties},
      },
      tier_prices: [
         {
            price: "",
            quantity: 0,
            custom_properties: {...CustomProperties},
         },
      ],
      associated_products: [""],
      display_discontinued_message: true,
      current_store_name: "",
      in_stock: true,
      allow_adding_only_existing_attribute_combinations: true,
      id: 0,
      custom_properties: {...CustomProperties},
   },
};
