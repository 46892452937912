import {createSlice} from "@reduxjs/toolkit";
import {CoreInitialState} from "../../../Models/CoreInitialState";

import * as ResponseModel from "./models/response";
import {fetchData} from "./middleware";

import SHIPPING_METHOD from "./index";

const initialState = {
   ...CoreInitialState,
   // data: ResponseModel.Type === 'ArrayOfObject' ?
   // [ResponseModel.Model] : ResponseModel.Model,
   data:
      SHIPPING_METHOD.MODELS.Response.Type.Type === "ArrayOfObject"
         ? [SHIPPING_METHOD.MODELS.Response.GetModel]
         : SHIPPING_METHOD.MODELS.Response.GetModel,
};

const reducers = {
   // Local Data Reducers
};

function extraReducers(builder) {
   builder
   .addCase(SHIPPING_METHOD.ACTIONS.fetchData.pending, (state, action) => {
      state.status = "loading";
   })
   .addCase(SHIPPING_METHOD.ACTIONS.fetchData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
   })
   .addCase(SHIPPING_METHOD.ACTIONS.fetchData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
   });
}
export const ShippingMethodSlice = createSlice({
   name: "ShippingMethod",
   initialState,
   reducers: {...reducers},
   extraReducers,
});
export default ShippingMethodSlice.reducer;
