import {createSlice} from "@reduxjs/toolkit";
import * as ResponseModel from "./models/response";
import {fetchData} from "./middleware";
import {CoreInitialState} from "../../../Models/CoreInitialState";

const initialState = {
   ...CoreInitialState,
   data: ResponseModel.Type === "ArrayOfObject" ? [ResponseModel.Model] : ResponseModel.Model,
};

const reducers = {
   // Local Data Reducers
};

function extraReducers(builder) {
   builder
   .addCase(fetchData.pending, (state, action) => {
      state.status = "loading";
   })
   .addCase(fetchData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
   })
   .addCase(fetchData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
   });
}

export const GetCategoryByIdSlice = createSlice({
   name: "GetCategoryById",
   initialState,
   reducers: {...reducers},
   extraReducers,
});
//test

export default GetCategoryByIdSlice.reducer;
