import CustomProperties from "../../../../Models/CustomProperties";
import pictureModel from "../../../../Models/Picture"


export const Type = 'ArrayOfObject';

export const Model = {
    manufacturer: {
        name: "",
        description: "",
        manufacturer_template_id: 0,
        meta_keywords: "",
        meta_description: "",
        meta_title: "",
        picture_id: 0,
        page_size: 0,
        allow_customers_to_select_page_size: true,
        page_size_options: "",
        subject_to_acl: true,
        limited_to_stores: true,
        published: true,
        deleted: true,
        display_order: 0,
        created_on_utc: "",
        updated_on_utc: "",
        price_range_filtering: true,
        price_from: 0,
        price_to: 0,
        manually_price_range: true,
        id: 0,
        custom_properties: { ...CustomProperties }
    },
    picture: {
        ImageUrl: "",
        ThumbImageUrl: "",
        FullSizeImageUrl: "",
        Title: "",
        AlternateText: "",
        CustomProperties: { ...CustomProperties }
    },
    id: 0,
    custom_properties: { ...CustomProperties }
}





