import Select from "../Form/Select";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";
import PageHandling from "../../Services/UI/PageHandling";
import COUNTRIES from "../../Features/Common/GetCountries";

function Countries(props) {
   return (
      <PageHandling
         selectAll={COUNTRIES.SELECTORS.selectAll}
         selectStatus={COUNTRIES.SELECTORS.selectStatus}
         selectError={COUNTRIES.SELECTORS.selectError}
         ParentFolder="Common"
         ChildFolder="GetCountries"
         oldData={true}
         Child={(data) => Design(data)}
      />
   );

   function Design(Countries) {
      function handler(value) {
         Countries.length > 0 &&
            Countries.map((item) => {
               if (item.Value == value) {
                  if (props.OnChange && typeof props.OnChange == "function") props.OnChange(item);

                  return;
               }
            });
      }
      return (
         <div className={`d-flex align-items-center mt-2 ${props.MainContainerClass}`}>
            <label className={`col-auto m-2 mt-1  ${props.LabelClass}`}>{app.translate(props.Label)}</label>
            <div className={` rounded ${props.ContainerClass}`}>
               <Select
                  Options={Countries}
                  Key="Value"
                  Value="Text"
                  OnChange={handler}
                  Model={props.Model}
                  FirstSelect={app.translate("address.selectcountry")}
               />
            </div>
         </div>
      );
   }
}

export default Countries;
Countries.propTypes = {
   Label: PropTypes.string,
   Model: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
   OnChange: PropTypes.func,
   GetCountriesData: PropTypes.func,
};
Countries.defaultProps = {
   Label: "",
};
