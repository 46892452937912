import { CustomProperties } from "../../../../Models";

export const CustomerModel = {
   email: "",
   email_to_revalidate: "",
   check_username_availability_enabled: true,
   allow_users_to_change_usernames: true,
   usernames_enabled: true,
   username: "",
   gender_enabled: true,
   gender: "",
   first_name_enabled: true,
   first_name: "",
   first_name_required: true,
   last_name_enabled: true,
   last_name: "",
   last_name_required: true,
   date_of_birth_enabled: true,
   date_of_birth_day: 0,
   date_of_birth_month: 0,
   date_of_birth_year: 0,
   date_of_birth_required: true,
   company_enabled: true,
   company_required: true,
   company: "",
   street_address_enabled: true,
   street_address_required: true,
   street_address: "",
   street_address2_enabled: true,
   street_address2_required: true,
   street_address2: "",
   zip_postal_code_enabled: true,
   zip_postal_code_required: true,
   zip_postal_code: "",
   city_enabled: true,
   city_required: true,
   city: "",
   county_enabled: true,
   county_required: true,
   county: "",
   country_enabled: true,
   country_required: true,
   country_id: 0,
   available_countries: [
      {
         disabled: true,
         group: {
            disabled: true,
            name: "",
         },
         selected: true,
         text: "",
         value: "",
      },
   ],
   state_province_enabled: true,
   state_province_required: true,
   state_province_id: 0,
   available_states: [
      {
         disabled: true,
         group: {
            disabled: true,
            name: "",
         },
         selected: true,
         text: "",
         value: "",
      },
   ],
   phone_enabled: true,
   phone_required: true,
   phone: "",
   fax_enabled: true,
   fax_required: true,
   fax: "",
   newsletter_enabled: true,
   newsletter: true,
   signature_enabled: true,
   signature: "",
   time_zone_id: "",
   allow_customers_to_set_time_zone: true,
   available_time_zones: [
      {
         disabled: true,
         group: {
            disabled: true,
            name: "",
         },
         selected: true,
         text: "",
         value: "",
      },
   ],
   vat_number: "",
   vat_number_status_note: "",
   display_vat_number: true,
   associated_external_auth_records: [
      {
         email: "",
         external_identifier: "",
         auth_method_name: "",
         id: 0,
         custom_properties: CustomProperties,
      },
   ],
   number_of_external_authentication_providers: 0,
   allow_customers_to_remove_associations: true,
   customer_attributes: [
      {
         name: "",
         is_required: true,
         default_value: "",
         attribute_control_type: "DropdownList",
         values: [
            {
               name: "",
               is_pre_selected: true,
               id: 0,
               custom_properties: CustomProperties,
            },
         ],
         id: 0,
         custom_properties: CustomProperties,
      },
   ],
   gdpr_consents: [
      {
         message: "",
         is_required: true,
         required_message: "",
         accepted: true,
         id: 0,
         custom_properties: CustomProperties,
      },
   ],
   custom_properties: CustomProperties,
};
