import { combineReducers } from '@reduxjs/toolkit';
import AllTagsReducers from './AllTags/reducer';
import featureProductsReducer from './FeaturedProducts/reducer';
import ShipmentMapTrackingReducer from "./ShipmentMapTracking/reducer"
import RecentlyReducer from './RecentlyViewedProducts/reducer';
import productDetailsReducer from './ProductDetails/reducer';
import RelatedProductsReducer from './RelatedProducts/reducer'
import AlsoPurchasedProductsReducer from './AlsoPurchasedProducts/reducer'
import CompareProductsReducer from './CompareProducts/reducer';
import GetProductsByTagReducer from './GetProductsByTag/reducer';
import GetNewProductsReducer from './NewProducts/reducer';

const SlidersReducer = combineReducers({
    GetAllTagsPruducts: AllTagsReducers,
    featuredProducts: featureProductsReducer,
    ShipmentMapTracking: ShipmentMapTrackingReducer,
    RecentlyProducts: RecentlyReducer,
    ProductDetails: productDetailsReducer,
    CompareProducts: CompareProductsReducer,
    RelatedProducts: RelatedProductsReducer,
    AlsoPurchasedProducts: AlsoPurchasedProductsReducer,
    GetProductsByTag: GetProductsByTagReducer,
    NewProducts: GetNewProductsReducer,
    
})

export default SlidersReducer;