import { combineReducers } from '@reduxjs/toolkit';
import ShippingMethodReducers from './ShippingMethod/reducer';
// import SaveAddressShippingReducers from './SaveAddressShipping/reducer';
import SavePaymentInfoReducers from './SavePaymentInfo/reducer';
import ConfirmOrderReducers from './ConfirmOrder/reducer';
const CheckoutReducer = combineReducers({
    ShippingMethod: ShippingMethodReducers,
    // SaveAddressShipping: SaveAddressShippingReducers,
    SavePaymentInfo: SavePaymentInfoReducers,
    ConfirmOrder: ConfirmOrderReducers,
})
export default CheckoutReducer;