import {createSlice, current} from "@reduxjs/toolkit";
import * as ResponseModel from "./models/response";
import {CoreInitialState} from "../../../Models/CoreInitialState";
// import {fetchData} from "./middleware";

const initialState = {
   ...CoreInitialState,
   data: ResponseModel.Type === "ArrayOfObject" ? [ResponseModel.Model] : ResponseModel.Model,
   Request: {},
};
const reducers = {
   setRequest(state, action) {
      state.Request = action.payload;
   },
};

// function extraReducers(builder) {
//    builder
//    .addCase(fetchData.pending, (state, action) => {
//       state.status = "loading";
//    })
//    .addCase(fetchData.fulfilled, (state, action) => {
//       state.status = "succeeded";
//       state.data = action.payload;
//    })
//    .addCase(fetchData.rejected, (state, action) => {
//       state.status = "failed";
//       state.error = action.error.message;
//    });
// }

export const AddProductToCartFromDetails = createSlice({
   name: "AddProductToCartFromDetails",
   initialState,
   reducers: {...reducers},
});
export const {setRequest} = AddProductToCartFromDetails.actions;
export default AddProductToCartFromDetails.reducer;
