import React from "react";
import { useSelector } from "react-redux";
import Spinner from '../../Utils/Spinner'

import ApiStatusManager from '../ApiStatusManager';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function PageHandling(props) {

    const data = useSelector(state => props.selectAll(state))
    const status = useSelector(state => props.selectStatus(state))
    const error = useSelector(state => props.selectError(state))
    const dispatch = useDispatch();
    useEffect(() => {
        if (!props.oldData || status != 'succeeded')
            dispatch(ApiStatusManager(props.ParentFolder, props.ChildFolder));
    }, [props.Reload])
    return (
        (status == "loading" || status == 'idle') ? <Spinner /> :
            status == "succeeded" ? props.Child(data) : <div>{status}</div>
    )


}

export default PageHandling;