import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';
export const Model={
    one_page_checkout_enabled: true,
    show_sku: false,
    show_product_images: false,
    is_editable: false,
    items: [],
    checkout_attributes: [],
    warnings: [],
    min_order_subtotal_warning: null,
    display_tax_shipping_info: false,
    terms_of_service_on_shopping_cart_page: false,
    terms_of_service_on_order_confirm_page: false,
    terms_of_service_popup: false,
    discount_box: {
      applied_discounts_with_codes: [],
      display: false,
      messages: [],
      is_applied: false,
      custom_properties: {...CustomProperties}
    },
    gift_card_box: {
      display: false,
      message: null,
      is_applied: false,
      custom_properties: {...CustomProperties}
    },
    order_review_data: {
      display: false,
      billing_address: {
        first_name: null,
        email: null,
        company_enabled: false,
        company_required: false,
        company: null,
        country_enabled: false,
        country_id: null,
        country_name: null,
        state_province_enabled: false,
        state_province_id: null,
        state_province_name: null,
        county_enabled: false,
        county_required: false,
        county: null,
        city_enabled: false,
        city_required: false,
        city: null,
        street_address_enabled: false,
        street_address_required: false,
        address1: null,
        street_address2_enabled: false,
        street_address2_required: false,
        address2: null,
        zip_postal_code_enabled: false,
        zip_postal_code_required: false,
        zip_postal_code: null,
        phone_enabled: false,
        phone_required: false,
        phone_number: null,
        fax_enabled: false,
        fax_required: false,
        fax_number: null,
        latitude: null,
        longitude: null,
        available_countries: [],
        available_states: [],
        formatted_custom_address_attributes: null,
        custom_address_attributes: [],
        id: 0,
        custom_properties: {...CustomProperties}
      },
      is_shippable: false,
      shipping_address: {
        first_name: null,
        email: null,
        company_enabled: false,
        company_required: false,
        company: null,
        country_enabled: false,
        country_id: null,
        country_name: null,
        state_province_enabled: false,
        state_province_id: null,
        state_province_name: null,
        county_enabled: false,
        county_required: false,
        county: null,
        city_enabled: false,
        city_required: false,
        city: null,
        street_address_enabled: false,
        street_address_required: false,
        address1: null,
        street_address2_enabled: false,
        street_address2_required: false,
        address2: null,
        zip_postal_code_enabled: false,
        zip_postal_code_required: false,
        zip_postal_code: null,
        phone_enabled: false,
        phone_required: false,
        phone_number: null,
        fax_enabled: false,
        fax_required: false,
        fax_number: null,
        latitude: null,
        longitude: null,
        available_countries: [],
        available_states: [],
        formatted_custom_address_attributes: null,
        custom_address_attributes: [],
        id: 0,
        custom_properties: {...CustomProperties}
      },
      selected_pickup_in_store: false,
      pickup_address: {
        first_name: null,
        email: null,
        company_enabled: false,
        company_required: false,
        company: null,
        country_enabled: false,
        country_id: null,
        country_name: null,
        state_province_enabled: false,
        state_province_id: null,
        state_province_name: null,
        county_enabled: false,
        county_required: false,
        county: null,
        city_enabled: false,
        city_required: false,
        city: null,
        street_address_enabled: false,
        street_address_required: false,
        address1: null,
        street_address2_enabled: false,
        street_address2_required: false,
        address2: null,
        zip_postal_code_enabled: false,
        zip_postal_code_required: false,
        zip_postal_code: null,
        phone_enabled: false,
        phone_required: false,
        phone_number: null,
        fax_enabled: false,
        fax_required: false,
        fax_number: null,
        latitude: null,
        longitude: null,
        available_countries: [],
        available_states: [],
        formatted_custom_address_attributes: null,
        custom_address_attributes: [],
        id: 0,
        custom_properties: {...CustomProperties}
      },
      shipping_method: null,
      payment_method: null,
      custom_values: {},
      custom_properties: {...CustomProperties}
    },
    button_payment_method_view_component_names: [],
    hide_checkout_button: false,
    show_vendor_name: false,
    custom_properties: {...CustomProperties}
  }