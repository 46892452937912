// import * as http from '../../../Services/http'
// let PostUrl = `/ShoppingCart/UpdateCartORwithListQuntity`;
// let GetUrl = `/Wishlist/Wishlist`;

// function Fetch(Type, Params) {
//   switch (Type) {
//     case 'get': return http.Get(GetUrl, {}, Params);
//     case 'post': return http.Post(PostUrl, {}, Params);
//   }
// }

// export default Fetch;




import * as http from '../../../Services/http'

let PostUrl = `/ShoppingCart/UpdateCartORwithListQuntity`;
let GetUrl = `/Wishlist/Wishlist`;
let ToCardUrl = `/Wishlist/AddItemsToCartFromWishlist`;


//WishList

export function AddToWishList(Params) {
  return http.Post(PostUrl, {},Params);
}

export function fetchData() {
  return http.Get(GetUrl);
}

//ToCardFromWishList

export function AddToCardFromWishList(Body) {
  return http.Put(ToCardUrl, Body, {});
}
