export default function ApiStatusManager(parentDataName, childDataName) {

   let fetchData;

   return async (dispatch) => {
      await import("../Features/" + parentDataName + "/" + childDataName + "/action").then((module) => {
         fetchData = module && module.fetchData ? module.fetchData : undefined;
      });

      fetchData != undefined && dispatch(fetchData());
   };
}
