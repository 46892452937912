import pictureModel from "../../../../Models/Picture"


export const Type = 'ArrayOfObject';

export const Model = {
    Number: 0,
    Text: "",
    AltText: "",
    Link:"",
    picture: pictureModel,
}



