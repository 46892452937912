import React from "react";
import PropTypes from "prop-types";
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import * as app from "../../Services/AppService";

function AccordionItem(props) {
   let Lang = app.getCookie("language", "ar");
   return (
      <div className={`accordion-item my-2   ${props.Class}`}>
         <h2 className="accordion-header" id="headingOne">
            <button
               style={{height: "35px"}}
               className={` rounded rounded-2 ${props.BtnClass}`}
               type="button"
               data-bs-toggle="collapse"
               data-bs-target={`#${props.Title}`}
               aria-expanded="true"
               aria-controls="collapseOne"
               onClick={props.Clicked}
               disabled={props.Disabled}
            >
               {props.Id && <span className={props.Disabled ? "text-white" : "text-dark"}>{props.Id} -</span>}
               <span className={`${props.TitleClass} ${props.Disabled ? "text-white" : "text-dark"}`}>
                  {" "}
                  {app.translate(props.Title)}
               </span>
               {props.BtnIcon &&
                  (props.Open ? <FiArrowDownCircle className="mx-1" /> : <FiArrowUpCircle className="mx-1" />)}
            </button>
         </h2>
         <div
            id={props.Title}
            className={`accordion-collapse collapse  ${props.Show ? "show" : ""}`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
         >
            <div className="accordion-body bg-white">{props.children}</div>
         </div>
      </div>
   );
}
AccordionItem.propTypes = {
   Class: PropTypes.string,
   Disabled: PropTypes.bool,
   Title: PropTypes.string,
   Clicked: PropTypes.func,
   Show: PropTypes.bool,
   TitleClass: PropTypes.string,
   BtnClass: PropTypes.string,
   BtnIcon: PropTypes.bool,
   Open: PropTypes.bool,
};
AccordionItem.defaultProps = {
   Class: " ",
   Disabled: false,
   Show: false,
   BtnClass: "accordion-button bg-primary",
};

export default AccordionItem;
