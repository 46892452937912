import { createAsyncThunk } from '@reduxjs/toolkit';
import GetLanguagesTranslate from './api';
let Body;
let Params;
export function setRequest(body,params){
  Body=body;
  Params=params;
}
export const fetchData = createAsyncThunk('GetLanguagesTranslate/fetchData', async () => {
    const response = await GetLanguagesTranslate(Body,Params);
    return response.data
})