import PageHandle from "../../../../Services/UI/PageHandling";
import {Card} from "../../../../Components";
import * as app from "../../../../Services/AppService";
import F_ALSOPURCHASED from "..";
function AlsoPurchased(props) {
   F_ALSOPURCHASED.ACTIONS.setRequest(props.productID);
   return (
      <PageHandle
         selectAll={F_ALSOPURCHASED.SELECTORS.selectAll}
         selectError={F_ALSOPURCHASED.SELECTORS.selectError}
         selectStatus={F_ALSOPURCHASED.SELECTORS.selectStatus}
         ParentFolder="Products"
         ChildFolder="AlsoPurchasedProducts"
         Child={(data) => Design(data)}
      />
   );
}
function Design(data) {
   let lang = app.getCookie("language", "ar");
   if (data) {
      return (
         <div className="mt-5">
            <h3
               className={
                  "fw-bold " + (lang.substr(0, 2) === "ar" ? "text-md-end text-center" : "text-md-start text-center")
               }
            >
               {app.translate("products.alsopurchased")}
            </h3>
            <hr className="border border-primary" />
            <div className="d-md-flex d-sm-block flex-wrap">
               {data.map((apiData, index) => {
                  return (
                     <div className="col-lg-3 col-md-4 col-sm-12 px-2 my-5" key={apiData.id}>
                        <Card
                           Tooltip={apiData.name}
                           Product={true}
                           productId={apiData.id}
                           LinkTo={apiData.se_name !== "" ? apiData.se_name : apiData.name}
                           Id={apiData.id}
                           Rating={apiData.review_overview_model.product_rating}
                           Title={apiData.name}
                           Picture={
                              apiData.default_picture_model.image_url != null
                                 ? apiData.default_picture_model.image_url
                                 : ""
                           }
                           Price={apiData.product_price.price}
                           se_name={apiData.se_name}
                        />
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }
}
export default AlsoPurchased;
