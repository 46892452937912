import { createSlice } from '@reduxjs/toolkit';
import * as ResponseModel from './models/response';
import { fetchData } from './middleware';
import { CoreInitialState } from '../../../Models/CoreInitialState';

const initialState =
{
    ...CoreInitialState,
    data: [],
}

const reducers = {
    // Local Data Reducers
   
    DeleteAddress(state, action) {
        state.data.addresses = state.data.addresses.filter(Add => action.payload != Add.id)
    },

    AddAddress(state, action) {
        var tempAddresses = [...state.data.addresses]
        tempAddresses.push(action.payload)
        state.data.addresses = tempAddresses
    },

    UpdateAddress(state, action) {
        var tempAddresses = [...state.data]
        tempAddresses.addresses = tempAddresses.addresses.filter(address => {
            if (address.id === action.payload.id)
                return action.payload
            return address
        })
        state.data.addresses = tempAddresses
    }
}

function extraReducers(builder) {
    builder
        .addCase(fetchData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.data = action.payload
        })
        .addCase(fetchData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
}


export const GetCUstomerAddressesSlice = createSlice({
    name: 'Addresses',
    initialState,
    reducers: { ...reducers },
    extraReducers
})

export const { DeleteAddress, AddAddress, UpdateAddress } = GetCUstomerAddressesSlice.actions;

export default GetCUstomerAddressesSlice.reducer;
