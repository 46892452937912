import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';

export const GetModel = {
    product_reviews: [
        {
            product_id: 0,
            product_name: "",
            product_se_name: "",
            title: "",
            review_text: "",
            reply_text: null,
            rating: 0,
            written_on_str: "",
            approval_status: null,
            additional_product_review_list: [],
            custom_properties: {...CustomProperties}
        }
    ],
    pager_model: {
        current_page: 1,
        individual_pages_displayed_count: 5,
        page_index: 0,
        page_size: 10,
        show_first: true,
        show_individual_pages: true,
        show_last: true,
        show_next: true,
        show_pager_items: true,
        show_previous: true,
        show_total_summary: false,
        total_pages: 1,
        total_records: 2,
        route_action_name: "",
        use_route_links: true,
        route_values: {
            page_number: 0
        }
    },
    custom_properties: {...CustomProperties}
}

export const PostModel = {
    customer_id: 0,
    product_id: 0,
    store_id: 0,
    is_approved: true,
    title: "",
    review_text: "",
    reply_text: "",
    customer_notified_of_reply: true,
    rating: 0,
    helpful_yes_total: 0,
    helpful_no_total: 0,
    created_on_utc: "",
    id: 0
}