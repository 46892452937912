import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavBar from "../Layout/NavBar";
import Container from "react-bootstrap/Container";
import {Link, Outlet} from "react-router-dom";
export default function AdminLayout(props) {
   return (
      <div>
         <div>
            <Navbar style={{background: "#272727d1"}} expand="lg" className="sticky-top shadow-sm">
               <Container className="justify-content-center">
                  <Nav.Item>
                     <a
                        href="https://sawaapi.tuqaatech.info/Admin/"
                        style={{color: "#eee"}}
                        eventKey="link-1 "
                        disabled
                        className="text-decoration-none"
                     >
                        Administrator
                     </a>
                  </Nav.Item>
               </Container>
            </Navbar>
         </div>
         <Container>
            <NavBar />
            <Outlet />
         </Container>
      </div>
   );
}
