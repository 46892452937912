import * as http from '../../../Services/http'
import  RequestModel from './models'

const url = `/Catalog/GetVendorProducts/`;

function GetVendorsById(id) {
  return http.Post(url+id,{},{})
}

export default GetVendorsById;

