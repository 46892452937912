import React from 'react';
import PropTypes from "prop-types";
import * as app from '../../Services/AppService'
function Input(props) {

    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value, props.Key);
    }

    return (
        <div className={`d-flex mt-2 ${props.MainContainerClass}`}>
            <label className={`col-auto m-2 mt-1  ${props.LabelClass}`} >{app.translate(props.Label)}</label>
            <div className={`input-group border rounded ${props.ContainerClass}`}>
                <input
                    type={props.Type}
                    key={props.Key}
                    className={`form-control form-control-sm border rounded border  ${props.Class}`}
                    disabled={props.Disabled}
                    onChange={handler}
                    value={props.Model}
                    onBlur={props.OnBlur}
                    placeholder={app.translate(props.Placeholder) + props.Symbol}
                />
                {props.Icon &&
                    <button className="btn btn-light rounded p-0 px-2" type="button" disabled>
                        <i className={`text-primary iconssc-${props.Icon}`} />
                    </button>
                }
            </div>
        </div>
    );
}

Input.propTypes = {
    Label: PropTypes.string,
    LabelFunc: PropTypes.bool,
    Class: PropTypes.string,
    LabelClass: PropTypes.string,
    Disabled: PropTypes.bool,
    Type: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    Placeholder: PropTypes.string,
    OnChange: PropTypes.func,
    Key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    OnBlur: PropTypes.func,
    Symbol: PropTypes.string,
    Type: PropTypes.string,
    MainContainerClass: PropTypes.string
}
Input.defaultProps = {
    Class: " border-0 ",
    LabelClass: "text-primary",

}
Input.defaultProps = {
    Class: " border-0",
    LabelClass: "text-dark",
    Type: "text",
    Placeholder: "",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
    Symbol: "",
    Type: "text",
    MainContainerClass: ""
}
export default Input;