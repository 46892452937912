import { createSlice } from '@reduxjs/toolkit';
import F_Product_Details from '.';
import { CoreInitialState } from '../../../Models/CoreInitialState';

const initialState =
{
    ...CoreInitialState,
    data: F_Product_Details.MODELS.Response.Type === 'ArrayOfObject' ?
        [F_Product_Details.MODELS.Response.Model] : F_Product_Details.MODELS.Response.Model,
}

const reducers = {
    setData(state, action) {
        state.data.product_details_model = action.payload
    },
    setQuantity(state, action) {
        state.data.product_details_model.add_to_cart.entered_quantity = action.payload
    },
}

function extraReducers(builder) {
    builder
        .addCase(F_Product_Details.ACTIONS.fetchData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(F_Product_Details.ACTIONS.fetchData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.data = action.payload
        })
        .addCase(F_Product_Details.ACTIONS.fetchData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
}

export const ProductDetails = createSlice({
    name: 'ProductDetails',
    initialState,
    reducers: { ...reducers },
    extraReducers
})
//test
export const { setData, setQuantity } = ProductDetails.actions
export default ProductDetails.reducer;