import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
export default function Gallery(props) {
   const [expandedImg, setExpandedImg] = useState({});
   useEffect(() => {
      setExpandedImg(props.ExpandedImg);
   }, [props.ExpandedImg]);

   return (
      <>
         <div className="col-10 d-flex justify-content-center" style={{height: "250px"}}>
            <img src={expandedImg.image_url} alt={expandedImg.alt} className="mw-100 w-auto mh-100 h-auto" />
         </div>
         <div className="row mt-3">
            {props.Images &&
               props.Images.map((img, index) => (
                  <div key={index} className="d-flex my-3 col-4 col-lg-3 col-md-4 ">
                     <img
                        src={img.image_url}
                        alt={img.alt}
                        onClick={() => setExpandedImg(img)}
                        className="mw-100 h-100 w-auto rounded-2"
                        style={{maxHeight: "150px"}}
                     />
                  </div>
               ))}
         </div>
      </>
   );
}

Gallery.propTypes = {
   ExpandedImg: PropTypes.object.isRequired,
   Images: PropTypes.array,
};

Gallery.defaultProps = {
   Images: [],
};
