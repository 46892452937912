import React from 'react';
// import ReactPhoneInput from 'react-phone-input-2'
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ar from 'react-phone-input-2/lang/ar.json';
//https://www.npmjs.com/package/react-phone-input-2  documentation

function ReactPhoneInput(props) {
    let lang = app.getCookie("language", "").split(',');
    var IsAr = lang[0] == 'ar';
    function handler(value) {
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange(value, props.Key);
    }

    return (
        <div className="mt-2">
            <PhoneInput
                placeholder={IsAr ? app.translate(props.Placeholder) : ""}
                value={props.Model}
                onChange={handler}
                country={!IsAr ? 'sy' : ""}
                inputClass=" w-100  h-100 rounded-2 bg-light"
                containerStyle={{ height: '42px' }}
                localization={IsAr ? ar : ""}
                buttonStyle={{ display: `${IsAr ? "none" : ""}` }}
            />
        </div>

    );
}

ReactPhoneInput.propTypes = {
    OnChange: PropTypes.func,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    Placeholder: PropTypes.string,

}
ReactPhoneInput.defaultProps = {

}
export default ReactPhoneInput;