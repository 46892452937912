import * as APIS from "./api";
import * as ACTIONS from "./action";
import * as COMPONENTS from "./component";
import * as SELECTORS from "./selector";
import MODELS from "./models";
const F_RECENT_VIEWED = {
   APIS,
   ACTIONS,
   SELECTORS,
   COMPONENTS,
   MODELS,
};

export default F_RECENT_VIEWED;
