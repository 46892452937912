import CustomProperties from '../../../Models/CustomProperties'

const AvailableCountries = [
    {
        disabled: true,
        group: {
            disabled: true,
            name: ""
        },
        selected: true,
        text: "",
        value: ""
    }
];
const AvailableStates = [
    {
        disabled: true,
        group: {
            disabled: true,
            name: ""
        },
        selected: true,
        text: "",
        value: ""
    }
];
const CustomAddressAttributes = [
    {
        name: "",
        is_required: false,
        default_value: "",
        attribute_control_type: "DropdownList",
        values: [
            {
                name: "",
                is_pre_selected: false,
                id: 0,
                custom_properties: CustomProperties
            }
        ],
        id: 0,
        custom_properties: CustomProperties
    }
    
];
const Address = {
    first_name: "",
    email: "",
    company_enabled: true,
    company_required: true,
    company: "",
    country_enabled: true,
    country_id: 0,
    country_name: "",
    state_province_enabled: true,
    state_province_id: 0,
    state_province_name: "",
    county_enabled: true,
    county_required: true,
    county: "",
    city_enabled: true,
    city_required: true,
    city: "",
    street_address_enabled: true,
    street_address_required: true,
    address1: "",
    street_address2_enabled: true,
    street_address2_required: true,
    address2: "",
    zip_postal_code_enabled: true,
    zip_postal_code_required: true,
    zip_postal_code: "",
    phone_enabled: true,
    phone_required: true,
    phone_number: "",
    fax_enabled: true,
    fax_required: true,
    fax_number: "",
    latitude: "",
    longitude: "",
    available_countries: AvailableCountries,
    available_states: AvailableStates,
    formatted_custom_address_attributes: "",
    custom_address_attributes: CustomAddressAttributes,
    id: 0,
    custom_properties: CustomProperties
}

export default Address;