import * as http from '../../../Services/http'
let PostUrl = `/ShoppingCart/UpdateCartORwithListQuntity`;
let GetUrl = `/ShoppingCart/Cart`;

export function AddToShoppingCart(Params) {
  return http.Post(PostUrl, {}, Params);
}

export function fetchData() {
  return http.Get(GetUrl);
}

