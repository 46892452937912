import * as ACTIONS from "./action";
import * as APIS from "./api";
import * as COMPONENTS from "./component";
import MODELS from "./models";
import * as SELECTORS from "./selector";

const SHIPPING_METHOD = {
   APIS,
   COMPONENTS,
   ACTIONS,
   MODELS,
   SELECTORS,
};
export default SHIPPING_METHOD;
