import {createAsyncThunk} from "@reduxjs/toolkit";
import * as app from "../../../Services/AppService";
import * as APIs from "./api";

//get

export const fetchData = createAsyncThunk("ShippingMethod/fetchData", async () => {
   const response = await APIs.GetShippingMethod();
   return response.data;
});

//post

export const SaveShippingMethod = (model, OnSuccessFunc, OnErrorFunc) => {
   app.ChangeLinesSpinnerStatus(true);
   APIs.SaveShippingMethod(model)
   .then((result) => {
      // app.ShowTopMessageAlert(`${result.data}`, "");
      if (OnSuccessFunc && typeof (OnSuccessFunc === "function")) OnSuccessFunc(result);
   })
   .catch((error) => {
      app.ShowTopMessageAlert(`Error: ${error}`, "", "danger");
      if (OnErrorFunc && typeof (OnErrorFunc === "function")) OnErrorFunc(error);
   });
   app.ChangeLinesSpinnerStatus(false);
};
