import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";

function ImageCard(props) {
   const blur1 = (e) => (e.target.style.filter = "opacity(100%)");
   const blur0 = (e) => (e.target.style.filter = "opacity(90%)");
   return (
      <>
         <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{app.translate(props.Tooltip)}</Tooltip>}
         >
            {({ref, ...triggerHandler}) => (
               <>
                  {props.LabelTop && (
                     <h5 className="text-center pe-auto mt-4 text-dark">{app.translate(props.LabelTop)}</h5>
                  )}
                  <div
                     className={
                        props.WithBackgroundCard
                           ? "row card my-4 border border-1 border-light shadow shadow-1 rounded rounded-4 rounded-top m-1 h-75"
                           : props.Class
                     }
                     {...triggerHandler}
                  >
                     <img
                        onMouseOver={blur0}
                        onMouseLeave={blur1}
                        ref={ref}
                        src={`${props.Image}`}
                        className={`rounded mx-auto w-75 mb-0   ${props.ClassImage}`}
                        style={{filter: "opacity(90%)"}}
                     />
                  </div>
                  {props.LabelBottom && (
                     <h5 className="text-center mt-5 m-1 pe-auto">{app.translate(props.LabelBottom)}</h5>
                  )}
               </>
            )}
         </OverlayTrigger>
      </>
   );
}
ImageCard.propTypes = {
   Tooltip: PropTypes.string,
   LabelTop: PropTypes.string,
   LabelBottom: PropTypes.string,
   Image: PropTypes.string.isRequired,
   ClassImage: PropTypes.string,
   Class: PropTypes.string,
};

ImageCard.defaultProps = {
   LabelTop: "",
   LabelBottom: "",
   Image: "/Group 105.png",
   Tooltip: "",
   WithBackgroundCard: true,
   Class: "row my-4 h-75",
};
export default ImageCard;
