import { createAsyncThunk } from '@reduxjs/toolkit';
import store from '../../../Redux/Store/store';
import * as app from '../../../Services/AppService';
import * as APIs from './api'
import { AddAddress, DeleteAddress, UpdateAddress } from './reducer'


export const fetchData = createAsyncThunk('Addresses/fetchData', async () => {
  const response = await APIs.GetCustomerAddresses();
  return response.data
})

export const DeleteById = (Id, OnSuccessFunc, OnErrorFunc) => {
  app.ChangeLinesSpinnerStatus(true)
  APIs.DeleteAddress(Id)
    .then(result => {
      app.ShowTopMessageAlert(`${result.data}`, '')
      store.dispatch(DeleteAddress(Id))
      if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
        OnSuccessFunc(result)
    })
    .catch(error => {
      app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
      if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
        OnErrorFunc(error)
    })
  app.ChangeLinesSpinnerStatus(false)
}

export const Update = (addressModel, ID, OnSuccessFunc, OnErrorFunc) => {
  app.ChangeLinesSpinnerStatus(true)
  APIs.AddressUpdate(addressModel, {}, ID)
    .then(result => {
      app.ShowTopMessageAlert(`${result.data}`, '')
      store.dispatch(UpdateAddress(result.data.model.address))
      if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
        OnSuccessFunc(result)
    })
    .catch(error => {
      app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
      if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
        OnErrorFunc(error)
    })
  app.ChangeLinesSpinnerStatus(false)
}

export const Add = (addressModel, OnSuccessFunc, OnErrorFunc) => {
  app.ChangeLinesSpinnerStatus(true)
  APIs.AddressAdd(addressModel, {})
    .then(result => {
      app.ShowTopMessageAlert(`${result.data}`, '')
      store.dispatch(AddAddress(result.data.model.address))
      if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
        OnSuccessFunc(result)
    })
    .catch(error => {
      app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
      if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
        OnErrorFunc(error)
    })
  app.ChangeLinesSpinnerStatus(false)
}