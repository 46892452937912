import { createSlice } from '@reduxjs/toolkit';
import * as ResponseModel from './models/response';
import { fetchData } from './middleware';
import { CoreInitialState } from '../../../Models/CoreInitialState';

const initialState =
{
     ...CoreInitialState,
    data: ResponseModel.Type === 'ArrayOfObject' ?
        [ResponseModel.GetModel] : ResponseModel.GetModel,
}

const reducers = {
    setData(state,action){
        state.data =action.payload;
    }
}

function extraReducers(builder) {
    builder
        .addCase(fetchData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.data = action.payload
        })
        .addCase(fetchData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
}

export const CustomerInfoSlice = createSlice({
    name: 'CustomerInfo',
    initialState,
    reducers: { ...reducers },
    extraReducers
})
export const {setData}=CustomerInfoSlice.actions
export default CustomerInfoSlice.reducer;