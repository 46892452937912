import { createAsyncThunk } from '@reduxjs/toolkit';
import * as APIs from './api';
import * as app from '../../../Services/AppService';

export const fetchData = createAsyncThunk('ProductReview/fetchData', async () => {
    const response = await APIs.GetCustomerProductReview();
    return response.data
})

export const AddProductReview = (model, OnSuccessFunc, OnErrorFunc) => {
    app.ChangeLinesSpinnerStatus(true)
    APIs.AddProductReview(model)
        .then(result => {
            app.ShowTopMessageAlert(`${result.data}`, "");
            if (OnSuccessFunc && typeof (OnSuccessFunc === "function")) OnSuccessFunc(result);
        })
        .catch(error => {
            app.ShowTopMessageAlert(`Error :${error}`, '', 'danger')
            if (OnErrorFunc && typeof (OnErrorFunc === "function")) OnErrorFunc(error)
        })
    app.ChangeLinesSpinnerStatus(false)
}