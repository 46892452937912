
const Delivery_Man = {
    CustomerGuid: "",
    Username: "",
    Email: "",
    EmailToRevalidate: null,
    AdminComment: null,
    IsTaxExempt: false,
    AffiliateId: 0,
    VendorId: 0,
    HasShoppingCartItems: false,
    RequireReLogin: false,
    FailedLoginAttempts: 0,
    CannotLoginUntilDateUtc: null,
    Active: true,
    Deleted: false,
    IsSystemAccount: false,
    SystemName: null,
    LastIpAddress: null,
    CreatedOnUtc: "",
    LastLoginDateUtc: "",
    LastActivityDateUtc: "",
    RegisteredInStoreId: 1,
    BillingAddressId: null,
    ShippingAddressId: null,
    ValidationMessage: null,
    Latitude: "",
    Longitude: "",
    Id: 0
}


const Shipping_Address = {
    FirstName: "",
    Email: "",
    Company: null,
    CountryId: 0,
    StateProvinceId: null,
    County: null,
    City: null,
    Address1: null,
    Address2: null,
    ZipPostalCode: null,
    PhoneNumber: "",
    FaxNumber: null,
    CustomAttributes: "",
    CreatedOnUtc: "",
    Latitude: "",
    Longitude: "",
    Id: 0
}

const shipment = {
    OrderId: 0,
    TotalCost: 0,
    DeliveryManId: 0,
    TrackingNumber: "",
    TotalWeight: 0.0,
    ShippedDateUtc: "",
    DeliveryDateUtc: null,
    dminComment: "",
    CreatedOnUtc: "",
    Id: 0
}

export const Type = 'ArrayOfObject';

export const Model = {
    DeliveryMan:Delivery_Man,
    ShippingAddress:Shipping_Address,
    Shipment:shipment
}



