import axios from "axios";
import * as app from './AppService'
import * as Constants from "../Utils/constants";
import Models from "../Features/Auth/Login/models";

var UpdateToken = false;
var canReCall = false;

export async function Get(url, Body, Params) {
  let token = app.getCookie('jwt-Auzhorization', '');


  return axios.get(Constants.BASE_FRONTEND_API_URL + url, {
    params: Params,
    withCredentials: false,
    headers: { "Authorization": `Bearer ${token}` }
  })
    .then(response => HandleResponse(response, 'Get'))

    .catch(error => {
      return HandleError(error).then(() => {

        if (canReCall) {
          return Get(url, Body, Params);
        }
        else
          return;
      }
      );
    });
}

export async function Post(url, Body, Params, isFormData = false) {
  let token = app.getCookie('jwt-Auzhorization', '');
  let body = Body;
  let headers = { "Authorization": `Bearer ${token}`, "content-type": "application/json" }
  if (isFormData) {
    let formData = new FormData();
    formData.append('files', Body);
    body = formData;
    headers = { "Authorization": `Bearer ${token}`, "content-type": "multipart/form-data" }
  }
  return axios.post(Constants.BASE_FRONTEND_API_URL + url, body, { params: Params, headers })
    .then(response => HandleResponse(response, url.includes("GetToken") ? 'token' : 'Post'))

    .catch(error => {
      return HandleError(error).then(() => {
        if (canReCall) {
          return Post(url, Body, Params);
        }
        else
          return;
      }
      );
    });
}


export async function Put(url, Body, Params, isFormData = false) {
  let token = app.getCookie('jwt-Auzhorization', '')
  let body = Body;
  let headers = { "Authorization": `Bearer ${token}` }
  if (isFormData) {
    let formData = new FormData();
    formData.append('files', Body);
    body = formData;
    headers = { "Authorization": `Bearer ${token}`, "content-type": "multipart/form-data" }
  }
  return axios.put(Constants.BASE_FRONTEND_API_URL + url, body, { params: Params, headers })
    .then(response => HandleResponse(response, 'Put'))
    .catch(error => {
      return HandleError(error).then(() => {
        if (canReCall) {
          return Put(url, Body, Params);
        }
        else
          return;
      }
      );
    });
}

export async function Delete(url, Params) {
  let token = app.getCookie('jwt-Auzhorization', '')
  return axios.delete(Constants.BASE_FRONTEND_API_URL + url, { params: Params, headers: { "Authorization": `Bearer ${token}` } })
    .then(response => HandleResponse(response, 'Delete'))
    .catch(error => {
      return HandleError(error).then(() => {
        if (canReCall) {
          return Delete(url, Params);
        }
        else
          return;
      }
      );
    });
}

async function HandleError(error) {
  if (error.response.status === 401 && !UpdateToken) {
    return Post("/Authenticate/GetToken", Models.Request.Model.Body, {})
  }
  return Promise.reject(error.message ? error.message : null);
}

function HandleResponse(response, methodType) {
  let data = {};
  data = response.data;
  if (response.status === 200) {
    canReCall = true;
    if (methodType == 'token') {
      app.setCookie('jwt-Auzhorization', response.data.token);
      data.token = '';
      localStorage.setItem('user', JSON.stringify(data))
    }
    return { data };
  }
  canReCall = false;
  throw new Error(response.statusText);
}
