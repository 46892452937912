import {combineReducers} from "@reduxjs/toolkit";
import CartReducer from "./Cart/reducer";
import AddProductToCartFromDetailsReducer from "./AddProduct/reducer";
// import AddProductToCartFromCatalogReducer from "./AddProductToCartFromCatalog/reducer";
const ShoppingCartReducer = combineReducers({
   Cart: CartReducer,
   AddProductToCartFromDetails: AddProductToCartFromDetailsReducer,
   // AddProductToCartFromCatalog: AddProductToCartFromCatalogReducer,
});
export default ShoppingCartReducer;
