import ReactSlider from "react-slider";
import PropTypes from "prop-types";
import styled from 'styled-components';

export default function RangeScroll(props) {

    const Track = (props, state) => <StyledTrack {...props} index={state.index} />
    const StyledTrack = styled.div`
    top: 5.5px;
    bottom: 0;
    height: 12px;
    background: ${props => (props.index === 2 ? '#fff' : props.index === 1 ? '#e9e9e9' : '#fff')};
    border-radius: 5px;
    border:2px solid #eee
`;

    function handler(event) {
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(event);
    }
    return (
        <div className="row w-100">
            <ReactSlider
                thumbClassName="btn btn-light btn-sm border-secondary"
                min={props.Min}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                max={props.Max}
                defaultValue={[0, 10000]}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                pearling
                minDistance={props.MinDistance}
                onChange={(value) => { handler(value) }}
                step={props.Step}
                value={[...props.Values]}
                renderTrack={Track}
                thumbActiveClassName="bg-info text-light"
            />
        </div>
    )
}

RangeScroll.propTypes = {
    OnChange: PropTypes.func,
    MinDistance: PropTypes.number,
    Min: PropTypes.number,
    Max: PropTypes.number,
    Step: PropTypes.number,
    Values: PropTypes.array
};

RangeScroll.defaultProps = {
    MinDistance: 10,
    Min: 0,
    Max: 100000000,
    Step: 1,
    Values: [0, 100000000]
};