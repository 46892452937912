import React from "react";
import PropTypes from "prop-types";
import * as app from '../../Services/AppService'
function Year(props) {

    const GenerateYearOptions = () => {
        const arr = [];
        const startYear = 1912;
        const endYear = new Date().getFullYear()
        for (let i = startYear; i <= endYear; i++) {
          arr.push(<option value={i}>{i}</option>);
        }
      
        return arr;
      };
    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(value, props.Options, props.ID);
    }
    return (
        <div className={props.containerClass}>
            <div className={`border rounded bg-light input-group `} >
                <select
                    className={`form-select form-select-md rounded border border-0  ${props.Class}`}
                    disabled={props.Disabled}
                    onChange={handler}
                    value={props.Model}
                    name="Years"
                >
                    <option value='0'>{app.translate("common.year")}</option>
                    {GenerateYearOptions()}
                </select>
            </div>
        </div>

    );
}

Year.propTypes = {
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Key: PropTypes.string,
    ID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Value: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]).isRequired,
    OnChange: PropTypes.func,
    containerClass: PropTypes.string,
    Clicked: PropTypes.func.isRequired,
};

Year.defaultProps = {
    Class: " ",
    Key: "Key",
    ID: "ID",
    Value: "Value",
    Disabled: false,
    containerClass: " mt-2",
};

export default Year;
