import * as app from "../../../Services/AppService";

import * as APIs from "./api";

export const AddProductFromDetails = (parentModel, OnSuccessFunc, OnErrorFunc) => {

   app.ChangeLinesSpinnerStatus(true);
   APIs.AddProductToCartFromDetails(parentModel)
      .then((result) => {
         if (!result.data.success) {
            app.ShowTopMessageAlert(`${result.data.errors.map(item => item).join("  &&  ")}`, "", "danger");
         } else {
            app.ShowTopMessageAlert(`${result.data}`, "");
            if (OnSuccessFunc && typeof (OnSuccessFunc === "function")) OnSuccessFunc(result);
         }
      })
      .catch((error) => {
         app.ShowTopMessageAlert(`Error: ${error}`, "", "danger");
         if (OnErrorFunc && typeof (OnErrorFunc === "function")) OnErrorFunc(error);
      });
   app.ChangeLinesSpinnerStatus(false);
};


export const AddProductToCartFromCatalog = (parentModel, OnSuccessFunc, OnErrorFunc) => {

   app.ChangeLinesSpinnerStatus(true);
   APIs.AddProductToCartFromCatalog(parentModel)
      .then((result) => {
         if (result.data.success) {
            if (OnSuccessFunc && typeof (OnSuccessFunc === "function")) OnSuccessFunc(result);
         } else {
            if (OnErrorFunc && typeof (OnErrorFunc === "function")) OnErrorFunc(result);
         }
      })
      .catch((error) => {
         if (OnErrorFunc && typeof (OnErrorFunc === "function")) OnErrorFunc(error);
      });
   app.ChangeLinesSpinnerStatus(false);
};
