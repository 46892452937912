import * as http from "../../../Services/http";
const DetailsURL = `/ShoppingCart/AddProductToCartFromDetails/`;
const CatalogURL = `/ShoppingCart/AddProductToCartFromCatalog/`;

// to make the product Details model as the back request it
function setModel(model) {
   let requestModel = {...model};
   Object.keys(requestModel).map((key, index) => {
      if (Array.isArray(requestModel[key])) {
         if (requestModel[key].length > 0) {
            requestModel[key] = requestModel[key].toString();
         } else {
            requestModel[key] = requestModel[key].toString();
         }
      }
   });
   return requestModel;
}

// function to add product to cart
function AddProductToCartFromDetails(parentModel) {
   parentModel.model = setModel(parentModel.model);
   return http.Post(DetailsURL + parentModel.productId, parentModel.model, parentModel.params);
}

// function to add product to cart from catalog page
function AddProductToCartFromCatalog(parentModel) {
   return http.Post(CatalogURL + parentModel.productId, parentModel.model, parentModel.params);
}
export {AddProductToCartFromDetails, AddProductToCartFromCatalog};
