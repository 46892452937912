import CustomProperties from "../../../../Models/CustomProperties";
import pictureModel from "../../../../Models/Picture"


export const Type = 'ArrayOfObject';

// for all
const group = {
    disabled: true,
    name: ""
}

// for catalog_products_model
const page_size_options = {
    disabled: true,
    group,
    selected: true,
    text: "",
    value: ""
}

// for products
const product_price = {
    old_price: "",
    price: "",
    price_value: 0,
    base_price_p_ang_v: "",
    disable_buy_button: true,
    disable_wishlist_button: true,
    disable_add_to_compare_list_button: true,
    available_for_pre_order: true,
    pre_order_availability_start_date_time_utc: "",
    is_rental: true,
    force_redirection_after_adding_to_cart: true,
    display_tax_shipping_info: true,
    custom_properties: { ...CustomProperties }
}
// for productAttributes
const productValue = {
    attribute_type_id: 0,
    value_raw: "",
    color_squares_rgb: "",
    custom_properties: { ...CustomProperties }
}
// for groups
// here i changed the value to productValue because attributes is already defined
const productAttributes = {
    name: "",
    productValue,
    id: 0,
    custom_properties: { ...CustomProperties }
}

// for product_specification_model
// here i changed the attributes to productAttributes because attributes is already defined
const groups = {
    name: "",
    productAttributes,
    id: 0,
    custom_properties: { ...CustomProperties }
}

// for products
const product_specification_model = {
    groups,
    custom_properties: { ...CustomProperties }
}

// for review_overview_model
const stars_details = {
    star_type: 0,
    percentage_rating: 0,
    raters_count: 0,
    custom_properties: { ...CustomProperties }
}

// for products
const review_overview_model = {
    product_id: 0,
    product_rating: 0,
    rating_sum: 0,
    total_reviews: 0,
    allow_customer_reviews: true,
    can_add_new_review: true,
    stars_details,
    custom_properties: { ...CustomProperties }
}



// for catalog_products_model
const products = {
    name: "",
    short_description: "",
    full_description: "",
    se_name: "",
    sku: "",
    product_type: ['SimpleProduct'],
    mark_as_new: true,
    product_price,
    default_picture_model: pictureModel,
    product_specification_model,
    review_overview_model
}















// for catalog_products_model
const available_view_modes = {
    disabled: true,
    group,
    selected: true,
    text: "",
    value: ""
}

// for catalog_products_model
const available_sort_options = {
    disabled: true,
    group,
    selected: true,
    text: "",
    value: ""
}

// for manufacturer_filter
const manufacturers = {
    disabled: true,
    group,
    selected: true,
    text: "",
    value: ""
}

// for catalog_products_model
const manufacturer_filter = {
    enabled: true,
    manufacturers,
    custom_properties: { ...CustomProperties }
}

// for attributes
const values = {
    name: "",
    color_squares_rgb: "",
    selected: true,
    id: 0,
    custom_properties: { ...CustomProperties }
}

// for specification_filter
const attributes = {
    name: "",
    values,
    id: 0,
    custom_properties: { ...CustomProperties }
}

// for catalog_products_model
const specification_filter = {
    enabled: true,
    attributes,
    custom_properties: { ...CustomProperties }
}
const temp = {
    from: 0,
    to: 0,
    custom_properties: { ...CustomProperties }
}
// for price_range_filter
const available_price_range = {
    ...temp
}

// for price_range_filter
const selected_price_range = {
    from: 0,
    to: 0,
    custom_properties: { ...CustomProperties }
}


//for catalog_products_model
const price_range_filter = {
    enabled: true,
    selected_price_range,
    available_price_range,
    custom_properties: { ...CustomProperties }
}

const catalog_products_model = {
    use_ajax_loading: true,
    warning_message: "",
    no_result_message: "",
    price_range_filter,
    specification_filter,
    manufacturer_filter,
    allow_product_sorting: true,
    available_sort_options,
    allow_product_view_mode_changing: true,
    available_view_modes,
    allow_customers_to_select_page_size: true,
    page_size_options,
    order_by: 0,
    view_mode: "",
    products,
    page_index: 0,
    page_number: 0,
    page_size: 0,
    total_items: 0,
    total_pages: 0,
    first_item: 0,
    last_item: 0,
    has_previous_page: true,
    has_next_page: true,
    custom_properties: { ...CustomProperties }
}

export const Model = {
    name: "",
    description: "",
    meta_keywords: "",
    meta_description: "",
    meta_title: "",
    se_name: "",
    allow_customers_to_contact_vendors: true,
    picture_model: pictureModel,
    catalog_products_model,
    id: 0,
    custom_properties: { ...CustomProperties }
}