import { Button } from "../../../../Components";
import F_ADD_PRODUCT from "..";
import { BsFillHeartFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as app from "../../../../Services/AppService";
import ApiStatusManager from "../../../../Services/ApiStatusManager";

export default function AddProductButton(props) {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   let RequestModel = useSelector((state) => F_ADD_PRODUCT.SELECTORS.selectRequest(state));

   async function PostApi(ProductID, type, ProductName = "") {

      let TempModel = { model: RequestModel, params: { quantity: 1, shoppingCartType: type }, productId: ProductID }

      F_ADD_PRODUCT.ACTIONS.AddProductToCartFromCatalog(
         TempModel,
         (resSuccess) => {
            app.ShowTopMessageAlert(`${resSuccess.data.message}`)
            if (type === "Wishlist") dispatch(ApiStatusManager('WishList', 'WishList'))
            else if (type === "ShoppingCart") dispatch(ApiStatusManager('ShoppingCart', 'Cart'))
         },
         (resError) => navigate(`/ProductDetails/${ProductName}/${ProductID}`, { state: { id: ProductID } })
      );
   }

   return (
      <>
         <Button
            Label={props.type !== "Wishlist" ? "shoppingcart.addtocart" : ""}
            Class={
               props.type === "Wishlist"
                  ? "border-0 py-2 opacity-75 m-2 w-auto top-0 position-absolute bg-light rounded"
                  : props.Class ||
                  "col-10 btn fs-6 btn-primary d-flex justify-content-center align-items-center p-0 mt-2 rounded"
            }
            Clicked={() => {
               PostApi(props.ProductID, props.type, props.ProductName);
            }}
         >
            {props.type === "Wishlist" ? (
               <BsFillHeartFill className="fs-4 m-1 " />
            ) : (
               <FaShoppingCart className="fs-2 float-start" />
            )}
         </Button>
      </>
   );
}
