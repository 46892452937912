
import ConfigureStore from '../Redux/Store/store'
import store from '../Redux/Store/store';
import { setTopMessageState, alertTopMessageModel } from '../Redux/Reducers/Alert/TopMessage/index'
import { setLinesSpinnerStatus } from '../Redux/Reducers/Spinners/Lines/reducer';

var TranslationKeys;

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name, defValue) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        //delete spaces from word 
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        //(النص يحوي الاسم و القيمة ..نأخذ منه القيمة فقط و التي تكون موجودة بعد الاسم أي من نهاية الاسم لنهاية النص)اذا وجد الاسم الذي نبحث عنه يعيد قيمته
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return defValue;
}

export function isLoggedIn() {
    let token = getCookie("jwt-Auzhorization", '');

}

export function translate(key) {
    let languages = ConfigureStore.getState().Common.GetLanguagesTranslate.data;
    languages.map(lang => {
        (lang.resources && JSON.stringify(lang.resources) != '{}') && (TranslationKeys = lang.resources)
    })
    if (key == undefined || typeof key != "string") return "";
    if (TranslationKeys == undefined) return key;
    if (TranslationKeys[key] && TranslationKeys[key] != undefined)
        return TranslationKeys[key].Value
    return key;

}

export function ShowTopMessageAlert(messageKey = '', link = '', messageType = 'success') {
    let alertModel = { ...alertTopMessageModel }
    alertModel.show = true;
    alertModel.messageKey = messageKey
    alertModel.link = link
    switch (messageType) {
        case 'success':
            alertModel.class = 'bg-success'
            break;
        case 'danger':
            alertModel.class = 'bg-danger'
            break;
        case 'warning':
            alertModel.class = 'bg-warning'
            break;
        case 'info':
            alertModel.class = 'bg-info'
            break;
        default:
            break;
    }
    store.dispatch(setTopMessageState(alertModel))
}


export function ChangeLinesSpinnerStatus(show = false) {
    store.dispatch(setLinesSpinnerStatus(show))
}