import { CustomProperties } from "../../../../Models";

export const Type = "Object";

export const PostModel = {
   Params: {
      shippingOption: "",
   },
   Body: {...CustomProperties},
};
