import * as http from '../../../Services/http'

const url = `/Order/CustomerOrders`;

function GetCustomerOrder() {
  return http.Get(url,{},{})
}

export default GetCustomerOrder;

