import CustomProperties from "../../../../Models/CustomProperties";
import {CustomerModel} from "./CustomerModel";

export const Type = "Object";

export const GetModel = {
   email: "",
   email_to_revalidate: null,
   check_username_availability_enabled: false,
   allow_users_to_change_usernames: false,
   usernames_enabled: true,
   username: "",
   gender_enabled: true,
   gender: "",
   first_name_enabled: true,
   first_name: null,
   first_name_required: true,
   last_name_enabled: true,
   last_name: null,
   last_name_required: true,
   date_of_birth_enabled: true,
   date_of_birth_day: null,
   date_of_birth_month: null,
   date_of_birth_year: null,
   date_of_birth_required: false,
   company_enabled: false,
   company_required: false,
   company: null,
   street_address_enabled: false,
   street_address_required: false,
   street_address: null,
   street_address2_enabled: false,
   street_address2_required: false,
   street_address2: null,
   zip_postal_code_enabled: false,
   zip_postal_code_required: false,
   zip_postal_code: null,
   city_enabled: false,
   city_required: false,
   city: null,
   county_enabled: false,
   county_required: false,
   county: null,
   country_enabled: false,
   country_required: false,
   country_id: 0,
   available_countries: [],
   state_province_enabled: false,
   state_province_required: false,
   state_province_id: 0,
   available_states: [],
   phone_enabled: true,
   phone_required: false,
   phone: null,
   fax_enabled: false,
   fax_required: false,
   fax: null,
   newsletter_enabled: false,
   newsletter: false,
   signature_enabled: false,
   signature: null,
   time_zone_id: null,
   allow_customers_to_set_time_zone: true,
   available_time_zones: [
      {
         disabled: false,
         group: null,
         selected: true,
         text: "",
         value: "",
      },
   ],
   vat_number: null,
   vat_number_status_note: "",
   display_vat_number: false,
   associated_external_auth_records: [],
   number_of_external_authentication_providers: 0,
   allow_customers_to_remove_associations: true,
   customer_attributes: [],
   gdpr_consents: [],
   custom_properties: {...CustomProperties},
};

export const PostModel = {
   model: {...CustomerModel},
   errors: [""],
};
