import * as http from '../../../Services/http'

const url = `/Customer/Addresses`;
const deleteUrl = `/Customer/AddressDelete/`;
const addUrl = "/Customer/AddressAdd";
const updateUrl = "/Customer/AddressEdit/";//{addressId}


export function GetCustomerAddresses() {
  return http.Get(url)
}

export function DeleteAddress(id) {
  return http.Delete(deleteUrl + id)
}

export function AddressAdd(model, params) {
  return http.Post(addUrl, model, params);
}

export function AddressUpdate(model, params, id) {
  return http.Put(updateUrl + id, model, params);
}


