import {combineReducers} from "@reduxjs/toolkit";

import RootCategoriesReducer from "./GetCatalogRoot/reducer";
import FeaturedCategoriesReducer from "./Featured/reducer";
import TopMenuCategoriesReducer from "./TopMenuCategories/reducer";
import GetCategoryByIdReducer from "./GetCategoryById/reducer";
import AllCategoriesSlice from "./AllWithSub/reducer";

const CategoriesReducer = combineReducers({
   RootCategories: RootCategoriesReducer,
   FeaturedCategories: FeaturedCategoriesReducer,
   TopMenuCategories: TopMenuCategoriesReducer,
   GetCategoryById: GetCategoryByIdReducer,
   AllWithSub: AllCategoriesSlice,
});

export default CategoriesReducer;
