import CustomProperties from '../../../../Models/CustomProperties'


const Order = [
    {
        custom_order_number: "",
        order_total: "",
        is_return_request_allowed: false,
        order_status_enum: 0,
        order_status: "",
        payment_status: "",
        shipping_status: "",
        shipping_status_enum: 0,
        created_on: "",
        id: 0,
        custom_properties: CustomProperties
    }
]

export const Type = 'ArrayOfObject';

export const Model = [
    {
        orders: Order,
        recurring_orders: [],
        recurring_payment_errors: [],
        custom_properties: {...CustomProperties}
    }
]