import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Accordion, AccordionItem} from "../../../../Components";
import * as app from "../../../../Services/AppService";

function RecentlyViewedProducts(props) {
   const [control, setControl] = useState({
      Show: 0,
   });
   if (props.apiData.length > 0) {
      return (
         <>
            <p className="h5 ms-2 d-none d-lg-block">{app.translate("products.recentlyviewedproducts")}</p>
            <div className="border-bottom border-primary border-opacity-100 w-75 pt-1 d-none d-lg-block"></div>

            <div className="d-none d-lg-block">{RecentViewed(true)}</div>
            <Accordion Class="d-block d-lg-none">
               <AccordionItem
                  TitleClass="text-muted"
                  BtnClass="btn w-100 text-muted fs-4 bg-light d-flex justify-content-between align-items-center"
                  BtnIcon={true}
                  Show={control.Show === 1}
                  Title="products.recentlyviewedproducts"
                  Open={control.Show === 0}
                  Clicked={() => {
                     setControl((old) => ({...old, Show: old.Show === 0 ? 1 : 0}));
                  }}
               >
                  {RecentViewed(false)}
               </AccordionItem>
            </Accordion>
         </>
      );
   }

   function RecentViewed(Img) {
      return (
         <div className="row w-100 ms-1 mt-4">
            {props.apiData &&
               props.apiData.map((item, index) => {
                  return (
                     <div key={index} className="d-flex align-items-end my-2">
                        {Img && (
                           <div className="col-6">
                              <Link to={`/ProductDetails/${item.se_name}/${item.id}`} state={{id: item.id}}>
                                 <img
                                    src={item?.default_picture_model?.image_url}
                                    style={{width: "60px", height: "60px"}}
                                 ></img>
                              </Link>
                           </div>
                        )}
                        <div className="col-6 ">
                           <Link
                              to={`/ProductDetails/${item.se_name}/${item.id}`}
                              state={{id: item.id}}
                              className="text-decoration-none text-dark"
                           >
                              <p className="" style={{marginLeft: "-30px"}}>
                                 <strong className="mb-4">{item?.name}</strong>
                              </p>
                           </Link>
                        </div>
                     </div>
                  );
               })}
         </div>
      );
   }
}
export default RecentlyViewedProducts;
