import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';
export const Model = {
    total_tags: 0,
    tags: [
        {
            name: "",
            se_name: "",
            product_count: 0,
            id: 0,
            custom_properties: {...CustomProperties}
        }
    ],
    custom_properties: {...CustomProperties}
}