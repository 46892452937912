export const selectAll = (state) => {
   return state.ShoppingCart.AddProductToCartFromDetails.data;
};

export const selectStatus = (state) => {
   return state.ShoppingCart.AddProductToCartFromDetails.status;
};

export const selectError = (state) => {
   return state.ShoppingCart.AddProductToCartFromDetails.error;
};

export const selectRequest = (state) => {
   return state.ShoppingCart.AddProductToCartFromDetails.Request;
};
