import Picture from "../../../../Models/Picture"
import CustomProperties from "../../../../Models/CustomProperties"
const Selected_price_range = {
    From: null,
    To: null,
    CustomProperties: { ...CustomProperties }
}
// const Available_Price_Range = {
//     From: null,
//     To: null,
//     CustomProperties: { ...CustomProperties }
// }

const Price_Range_Filter = {
    Enabled: false,
    SelectedPriceRange: {...Selected_price_range},
    AvailablePriceRange: {...Selected_price_range},// Available_Price_Range,
    CustomProperties: { ...CustomProperties }
}


const Specification_Filter = {
    Enabled: false,
    Attributes: [],
    CustomProperties: { ...CustomProperties }
}
const Manufacturer_Filter = {
    Enabled: false,
    Manufacturers: [],
    CustomProperties: { ...CustomProperties }
}

const Catalog_Products_Model = {
    UseAjaxLoading: false,
    WarningMessage: null,
    NoResultMessage: null,
    PriceRangeFilter: {...Price_Range_Filter},
    SpecificationFilter: {...Specification_Filter},
    ManufacturerFilter: {...Manufacturer_Filter},
    AllowProductSorting: false,
    AvailableSortOptions: [],
    AllowProductViewModeChanging: false,
    AvailableViewModes: [],
    AllowCustomersToSelectPageSize: false,
    PageSizeOptions: [],
    OrderBy: null,
    ViewMode: null,
    Products: [],
    PageIndex: 0,
    PageNumber: 0,
    PageSize: 0,
    TotalItems: 0,
    TotalPages: 0,
    FirstItem: 0,
    LastItem: 0,
    HasPreviousPage: false,
    HasNextPage: false,
    CustomProperties: { ...CustomProperties }
}


export const Type = 'ArrayOfObject';

export const Model = {
    Name: "",
    Description: null,
    MetaKeywords: null,
    MetaDescription: null,
    MetaTitle: null,
    SeName: "",
    PictureModel: Picture,
    DisplayCategoryBreadcrumb: false,
    CategoryBreadcrumb: [],
    SubCategories: [],
    FeaturedProducts: [],
    CatalogProductsModel: {...Catalog_Products_Model},
    Id: 0,
    CustomProperties: { ...CustomProperties }
}