import { createAsyncThunk } from '@reduxjs/toolkit';
// import GetCustomerInfo from './api';
import * as APIs from './api';
import * as app from "../../../Services/AppService";

//Get 
export const fetchData = createAsyncThunk('GetCustomerInfo/fetchData', async () => {
    const response = await APIs.GetCustomerInfo();
    return response.data
})

//Post 
export const PostCustomerInfo=(model, OnSuccessFunc, OnErrorFunc) => {
    app.ChangeLinesSpinnerStatus(true);
    APIs.PostCustomerInfo(model)
    .then((result) => {
        app.ShowTopMessageAlert(`${result.data}`, "");
        if (OnSuccessFunc && typeof (OnSuccessFunc === "function")) OnSuccessFunc(result);
    })
    .catch((error) => {
        // app.ShowTopMessageAlert(`Error: ${error}`, "", "danger");
        if (OnErrorFunc && typeof (OnErrorFunc === "function")) OnErrorFunc(error);
    });
    app.ChangeLinesSpinnerStatus(false);
};