import React, {useEffect} from "react";
import "./App.css";
import * as app from "./Services/AppService";
import AppRoutes from "./Routes";
import Footer from "./Layout/Footer";
import {setRequest as setLangsRequest} from "./Features/Common/GetLanguagesTranslate/action";
import {setUserData} from "./Features/Auth/Login/reducer";
import ApiStatusManager from "./Services/ApiStatusManager";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "./Utils/Spinner";
import SpinnerLines from "./Utils/SpinnerLines";
import {MessageAlert} from "./Components";
import {selectStatus as selectLanguageStatus} from "./Features/Common/GetLanguagesTranslate/selector";
import {setTopMessageState} from "./Redux/Reducers/Alert/TopMessage";
import store from "./Redux/Store/store";

const ArStyle = React.lazy(() => import("./Utils/RtlTheme"));
const EnStyle = React.lazy(() => import("./Utils/LtrTheme"));

function App() {
   const dispatch = useDispatch();

   let langStatus = useSelector((state) => selectLanguageStatus(state));
   let linesSpinnerStatus = useSelector((state) => state.Spinners.Lines.show);
   let alertTopMessageSelector = useSelector((state) => state.Alerts.TopMessage.data);

   let lang = app.getCookie("language", "").split(",");
   let IsAr = lang[0] == "ar";
   let langId = lang[1];
   useEffect(() => {
      dispatch(setUserData(JSON.parse(localStorage.getItem("user"))));

      setLangsRequest({}, langId != undefined ? {languageId: parseInt(langId)} : {});
      dispatch(ApiStatusManager("Common", "GetLanguagesTranslate"));
   }, []);

   return langStatus == "loading" || langStatus == "idle" ? (
      <Spinner />
   ) : langStatus == "faild" ? (
      <>faild</>
   ) : (
      <div
         style={{
            direction: IsAr ? "rtl" : "ltr",
            background: "url(https://sawaapi.tuqaatech.info/Themes/OswaTech/Content/OswaTechImages/Group%20950.svg)",
         }}
      >
         <React.Suspense>
            {linesSpinnerStatus && <SpinnerLines Show={true} />}
            {alertTopMessageSelector.show && (
               <MessageAlert
                  Message={alertTopMessageSelector.messageKey}
                  Link={alertTopMessageSelector.link}
                  Class={alertTopMessageSelector.class}
                  Show={true}
                  Close={() => {
                     store.dispatch(setTopMessageState({...alertTopMessageSelector, show: false}));
                  }}
               />
            )}
            {IsAr && <ArStyle />}
            {!IsAr && <EnStyle />}
         </React.Suspense>

         <AppRoutes />
         <Footer />
      </div>
   );
}

export default App;
