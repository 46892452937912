import { createAsyncThunk } from '@reduxjs/toolkit';
import GetProductDetails from './api';

let ProductId;
export function setRequest(productId) {
    ProductId = productId;
}
export const fetchData = createAsyncThunk('ProductDetails/fetchData', async () => {
    const response = await GetProductDetails(ProductId);
    return response.data
})