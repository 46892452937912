import CustomProperties from './CustomProperties'

const pictureModel = {
    ImageUrl: "",
    ThumbImageUrl: "",
    FullSizeImageUrl: "",
    Title: "",
    AlternateText: "",
    CustomProperties
}

export default pictureModel;



