import { combineReducers } from '@reduxjs/toolkit';
import SlidersReducers from './GetSlidersModel/reducer';
import LanguagesReducers from './GetLanguagesTranslate/reducer';
import CountriesReducers from './GetCountries/reducer';
// import ContactVendorSendReducers from './ContactVendorSend/reducer';
const reducers = {
    // Local Data Reducers
}

function extraReducers(builder) {

}

const CommonReducer = combineReducers({
    Slider: SlidersReducers,
    GetLanguagesTranslate: LanguagesReducers,
    GetCountries:CountriesReducers,
    // ContactVendorSend:ContactVendorSendReducers,
})

export default CommonReducer;