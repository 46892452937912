import { createAsyncThunk } from '@reduxjs/toolkit';
import SavePaymentInfo from './api';
let body
export function setRequest(Body) {
    body = Body
}
export const fetchData = createAsyncThunk('SavePaymentInfo/fetchData', async () => {
    const response = await SavePaymentInfo(body);
    return response.data
})
