import React from "react";
import NavBar from "../Layout/NavBar";
import Container from "react-bootstrap/Container";
import { Outlet } from 'react-router-dom';
export default function ClientLayout(props) {
  return (
    <div>
      <Container>
        <NavBar />
        <Outlet />
      </Container>
    </div>
  );
}
