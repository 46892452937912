//ToCardFroWishList
export const Type = 'Object';

export const WishListModel = {
    Params: { customerGuid: "00000000-0000-0000-0000-000000000000" },
    Body: {}
}

export const ToCardModel = {
    Params: {
        itemquantity: 0,
        addtocart: 0
    },
    Body: {}
}

