import * as APIS from './api'
import * as ACTIONS from './action'
import * as COMPONENTS from './component'
import * as SELECTORS from './selector'
import MODELS from './models'

const COMPARE_PRODUCT = {
    APIS,
    ACTIONS,
    SELECTORS,
    COMPONENTS,
    MODELS,
}

export default COMPARE_PRODUCT;
