import * as ResponseModel from "./models/response";
import {CoreInitialState} from "../../../Models";
import {createSlice} from "@reduxjs/toolkit";
// import SearchProducts from "./middleware";

const initialState = {
   ...CoreInitialState,
   data: ResponseModel.Type === "ArrayOfObject" ? [ResponseModel.Model] : ResponseModel.Model,
};
const reducers = {
   StoreModel(state, action) {
      state.data = action.payload;
   },
};
// function extraReducers(builder) {
//    builder
//    .addCase(SearchProducts.pending, (state, action) => {
//       state.status = "loading";
//    })
//    .addCase(SearchProducts.fulfilled, (state, action) => {
//       state.status = "succeeded";
//       state.data = action.payload;
//    })
//    .addCase(SearchProducts.rejected, (state, action) => {
//       state.status = "failed";
//       state.error = action.error.message;
//    });
// }
export const Categories = createSlice({
   name: "Categories",
   initialState,
   reducers: {...reducers},
   //    extraReducers,
});
export const {StoreModel} = Categories.actions;
export default Categories.reducer;
