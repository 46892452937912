import React from "react";
import PropTypes from "prop-types";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from "../Button";
import * as app from "../../Services/AppService";
import { Link } from "react-router-dom";

function NavDropdown(props) {
    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(value, props.Options, props.ID);
    }
    return (
        <div className={props.containerClass}>
            {props.Label && (
                <label className={props.LabelClass}>{app.translate(props.Label)}</label>
            )}

            <div className={`border rounded bg-light input-group  `} >
                <Dropdown className="w-100" align={"center"} >
                    <Dropdown.Toggle className="w-100 p-2 e-caret-hide" variant="" id="dropdown-basic" disabled={props.Disabled}
                        onChange={handler}
                        value={props.Model}>
                        <a className={"text-dark link-primary text-decoration-none mx-3 " + (props.Class)}>
                            {props.Options.find((item) => +item[props.Key] === 0) === undefined &&
                                props.FirstSelect
                            }
                        </a>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2 w-100">
                        {props.Options.length > 0 && props.Options.map((item) => {
                            return (
                                <Dropdown.Item className={"w-auto text-wrap text-lg-start ms-1 text-md-center text-dark link-primary p-1 " + (props.Class)} href="" key={item[props.Key]} value={item[props.Key]}>
                                    <Link to={`/categoryDetails/Categorie/${item.id}`} className='text-decoration-none text-dark'>
                                        {item[props.Value]}
                                    </Link>
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                {props.Icon &&
                    <Button Icon={props.Icon} Class=" text-white btn-secondary" />
                }
            </div>
        </div>

    );
}

NavDropdown.propTypes = {
    Label: PropTypes.string,
    LabelClass: PropTypes.string,
    Options: PropTypes.array.isRequired,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Key: PropTypes.string,
    ID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Value: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    OnChange: PropTypes.func,
    containerClass: PropTypes.string,
    FirstSelect: PropTypes.string
};

NavDropdown.defaultProps = {
    Class: " ",
    Key: "Key",
    ID: "ID",
    Value: "Value",
    LabelClass: "text-primary ",
    Disabled: false,
    containerClass: " mt-2",
    Model: false,
    FirstSelect: "Please Select"
};

export default NavDropdown;
