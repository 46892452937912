import { createAsyncThunk } from '@reduxjs/toolkit';
import GetVendorsById from './api';
let id;
let body;
let params;
export function setRequestVendor(Id){
    id=Id;
}
export const fetchData = createAsyncThunk('CategoryProducts/fetchData', async () => {
    const response = await GetVendorsById(id);
    return response.data
})