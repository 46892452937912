import { Card } from "../../../../Components";
import PageHandle from "../../../../Services/UI/PageHandling";
import F_FEATURED from "..";
import * as http from "../../../../Services/http"
function FeaturedProducts() {
   return (
      <PageHandle
         selectAll={F_FEATURED.SELECTORS.selectAll}
         selectError={F_FEATURED.SELECTORS.selectError}
         selectStatus={F_FEATURED.SELECTORS.selectStatus}
         ParentFolder="Products"
         ChildFolder="FeaturedProducts"
         Child={(data) => Design(data)}
      />
   );
}
function Design(apiData) {

      let content;
      if (apiData) {
         content = apiData.map((product, index) => {
            return (
               <div className="col-md-4 col-lg-3 col-sm-6 col-10" key={index}>
                  {
                     <Card
                        old_price={product.product_price.old_price ? product.product_price.old_price : 0}
                        Tooltip={product.name}
                        productId={product.id}
                        LinkTo={product.se_name !== "" ? product.se_name : product.name}
                        Id={product.id}
                        Rating={product.review_overview_model.product_rating}
                        Title={product.name}
                        Picture={
                           product.default_picture_model.image_url != null ? product.default_picture_model.image_url : ""
                        }
                        Price={product.product_price.price}
                        Product={true}
                        se_name={product.se_name}
                     />
                  }
               </div>
            );
         });
      }
      return (
         <>
            <div className="row justify-content-center align-items-center gx-2 gy-5 py-5">{content}</div>
         </>
      );
   }
   export default FeaturedProducts;
