import { combineReducers, createSlice } from '@reduxjs/toolkit';
import AllVendorsReducer from './All/reducer';
import FacturersByIdReducers from './GetById/reducer';
const reducers = {
    // Local Data Reducers
}
const VendorsReducer = combineReducers({
    AllVendors: AllVendorsReducer,
    GetByIdVendor:FacturersByIdReducers
})
export default VendorsReducer;