import { createAsyncThunk } from '@reduxjs/toolkit';
import * as APIs from './api'
import * as app from "../../../Services/AppService"


export const fetchData = createAsyncThunk('Cart/fetchData', async () => {
    const response = await APIs.fetchData();
    return response.data
})

export const AddToShoppingCart = (Params, OnSuccessFunc, OnErrorFunc) => {
    app.ChangeLinesSpinnerStatus(true)
    APIs.AddToShoppingCart(Params)
        .then(result => {
            if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
                OnSuccessFunc(result)
        })
        .catch(error => {
            if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
                OnErrorFunc(error)
        })
    app.ChangeLinesSpinnerStatus(false)
}
