import React from "react";
import PropTypes from "prop-types";
import * as app from '../../Services/AppService'
function Months(props) {
    let Months = [
        {Value:"0", title:app.translate("common.month")},
        {Value:"1", title:"January"},
        {Value:"2", title:"February"},
        {Value:"3", title:"March"},
        {Value:"4", title:"April"},
        {Value:"5", title:"May"},
        {Value:"6", title:"June"},
        {Value:"7", title:"July"},
        {Value:"8", title:"August"},
        {Value:"9", title:"September"},
        {Value:"10", title:"October"},
        {Value:"11", title:"November"},
        {Value:"12", title:"December"},
    ]
    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(value, props.Options, props.ID);
    }
    return (
        <div className={props.containerClass}>
            <div className={`border rounded bg-light input-group `} >
                <select
                    className={`form-select form-select-md rounded border border-0  ${props.Class}`}
                    disabled={props.Disabled}
                    onChange={handler}
                    value={props.Model}
                    name="Months"
                > 
                {Months.map((item,index)=> {
                    return (
                        <option key={`${index}-${item.Value}`} value={item.Value}>{item.title}</option>
                    )
                })}
                </select>
            </div>
        </div>

    );
}

Months.propTypes = {
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Key: PropTypes.string,
    ID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Value: PropTypes.string,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]).isRequired,
    OnChange: PropTypes.func,
    containerClass: PropTypes.string,
    Clicked: PropTypes.func.isRequired,
};

Months.defaultProps = {
    Class: " ",
    Key: "Key",
    ID: "ID",
    Value: "Value",
    Disabled: false,
    containerClass: " mt-2",
};

export default Months;
