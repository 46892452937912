import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';


export const Model = {
    Params: {
        shoppingCartType: 'ShoppingCart'
    },
    Body: {...CustomProperties}
}