import { createAsyncThunk } from '@reduxjs/toolkit';
import LoginGetToken from './api';
let body;
export function setRequest(Body) {
    body = Body;
}
export const fetchData = createAsyncThunk('LoginUser/fetchData', async () => {
    const response = await LoginGetToken(body);
    return response;
})
