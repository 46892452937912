import React from "react";
import PageHandle from "../../Services/UI/PageHandling";
import { Link } from "react-router-dom";
import * as app from '../../Services/AppService'
import { Button } from "../../Components";
import { useNavigate } from "react-router-dom";

// import { setRequest } from "../../Features/ShoppingCart/Cart/action";
// import { selectAll, selectStatus, selectError } from "../../Features/ShoppingCart/Cart/selector";

import CART from "../../Features/ShoppingCart/Cart";



let lang = app.getCookie("language", "").split(',');
var IsAr = lang[0] == 'ar';
function Card(props) {
    return (
        <div className={`d-flex border-1 border-light shadow rounded-2 align-items-center my-2 p-2 ${!IsAr ? 'text-start' : 'text-end'}`}>
            <div className="col-lg-3 ms-4"><img className="mw-100 w-auto mh-100 h-auto" src={props.Picture} alt="" /></div>
            <div className="col-lg-8">
                <p className=" fw-bolder fs-6">{props.Name}</p>
                <p>{app.translate("shoppingcart.mini.unitprice") + " : " + props.DetailsPrice}<br />{app.translate('products.qty') + " : " + props.Quantity}</p>
            </div>
        </div>
    )
}

export default function ShoppingCart(props) {
    // setRequest('get', {});
    return (
        <PageHandle selectAll={CART.SELECTORS.selectAll}
            selectStatus={CART.SELECTORS.selectStatus}
            selectError={CART.SELECTORS.selectError}
            oldData={true}
            ParentFolder="ShoppingCart"
            ChildFolder="Cart"
            Child={(data) => Design(data, props)
            }
        />
    )
}
function Design(apiData, props) {

    var sub_total = 0;
    var total = 0;
    var tax = 0;
    apiData.items.length > 0 && apiData.items.map(item => { sub_total += +item.sub_total.replace(/[^0-9]/g, "") })
    total = sub_total + tax;
    {
        return (
            <div className="">
                {apiData.items.length > 0 && <p className={`border-bottom border-primary py-2 ${!IsAr ? 'text-start' : 'text-end'}`}>
                    {app.translate('shoppingcart.mini.itemstext').replace('{0}', !IsAr ? `${props.CardItems}` + " Products " : `${props.CardItems}` + " منتج ")}
                </p>}
                {apiData.items.length > 0 && apiData.items.map(product => {
                    return (
                        <Card
                            Name={product.product_name}
                            Picture={product.picture.image_url}
                            DetailsPrice={product.sub_total}
                            Quantity={product.quantity}
                        />
                    )
                })}
                {apiData.items.length > 0 &&
                    <div className="mt-3 row border-top border-primary py-2">
                        <div className='text-muted text-end'>
                            <label className="fw-bolder">{app.translate('messages.order.subtotal') + "  " + total}</label>
                        </div>
                        <div className='justify-content-center d-flex mt-3 gap-2'>
                            <Link className="btn-primary shadow-sm text-decoration-none px-2 py-1 bg-primary text-dark fw-bold" to="/checkout" state={apiData.items.length}>{app.translate('checkout')}</Link>
                            <Link className="btn-primary shadow-sm text-decoration-none px-2 py-1 bg-primary text-dark fw-bold mx-2" to="/checkout">{app.translate('shoppingcart.mini.viewcart')}</Link>
                        </div>
                    </div>
                }
                {!apiData.items.length > 0 && <div className="text-center">{app.translate('shoppingcart.cartisempty')}</div>}
            </div>
        )
    }
}