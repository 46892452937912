import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'ArrayOfObject';
export const Model = [
    {
        name: "",
        short_description: "",
        full_description: "",
        se_name: "",
        sku: "",
        product_type: "",
        mark_as_new: true,
        product_price: {
            old_price: null,
            price: "",
            price_value: 0,
            base_price_p_ang_v: null,
            disable_buy_button: false,
            disable_wishlist_button: false,
            disable_add_to_compare_list_button: false,
            available_for_pre_order: false,
            pre_order_availability_start_date_time_utc: null,
            is_rental: false,
            force_redirection_after_adding_to_cart: false,
            display_tax_shipping_info: false,
            custom_properties: { ...CustomProperties }
        },
        default_picture_model: {
            image_url: "",
            thumb_image_url: null,
            full_size_image_url: "",
            title: "",
            alternate_text: "",
            custom_properties: { ...CustomProperties }
        },
        product_specification_model: {
            groups: [],
            custom_properties: { ...CustomProperties }
        },
        review_overview_model: {
            product_id: 0,
            product_rating: 0,
            rating_sum: 0,
            total_reviews: 0,
            allow_customer_reviews: true,
            can_add_new_review: true,
            stars_details: [
                {
                    star_type: 1,
                    percentage_rating: 0,
                    raters_count: 0,
                    custom_properties: {}
                }
            ],
            custom_properties: { ...CustomProperties }
        },
        id: 0,
        custom_properties: { ...CustomProperties }
    }
]

