import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';
export const Model = {
  Params: {},
  Body: {
    price: "",
    specification_option_ids: [
      0
    ],
    manufacturer_ids: [
      0
    ],
    order_by: 0,
    view_mode: "",
    page_index: 0,
    page_number: 0,
    page_size: 0,
    total_items: 0,
    total_pages: 0,
    first_item: 0,
    last_item: 0,
    has_previous_page: true,
    has_next_page: true,
    custom_properties: { ...CustomProperties }
  }
}