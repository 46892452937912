import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'Object';
export const Model = {
    errors: [
        ""
    ],
    success: true,
    message: "",
    model: {
        items: [
            {
                product_id: 0,
                product_name: "",
                product_se_name: "",
                quantity: 0,
                unit_price: "",
                attribute_info: "",
                picture: {
                    image_url: "",
                    thumb_image_url: "",
                    full_size_image_url: "",
                    title: "",
                    alternate_text: "",
                    custom_properties: {...CustomProperties}
                },
                id: 0,
                custom_properties: {...CustomProperties}
            }
        ],
        total_products: 0,
        sub_total: "",
        display_shopping_cart_button: true,
        display_checkout_button: true,
        current_customer_is_guest: true,
        anonymous_checkout_allowed: true,
        show_product_images: true,
        custom_properties: {...CustomProperties}
    }
}