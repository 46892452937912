import { createAsyncThunk } from '@reduxjs/toolkit';
import GetManufacturerById from './api';
let id;
let body;
let params;
export function setRequest(Id){
    id=Id;
}
export const fetchData = createAsyncThunk('CategoryProducts/fetchData', async () => {
    const response = await GetManufacturerById(id);
    return response.data
})