import CustomProperties from "../../../../Models/Picture"


export const Type = 'ArrayOfObject';

export const Model = [{
    language: {
        Name: "",
        LanguageCulture: "",
        UniqueSeoCode: "",
        FlagImageFileName: "",
        Rtl: false,
        LimitedToStores: false,
        DefaultCurrencyId: 0,
        Published: true,
        DisplayOrder: 0,
        Id: 0
    },
    resources: {},
    custom_properties: CustomProperties
}]



