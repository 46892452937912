import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

//Services
import * as app from "../Services/AppService";

//Components
import Form from "react-bootstrap/Form";
import { Button, Input, Select } from "../Components";
import ShoppingCartDialog from "../Pages/ShoppingCartDialog";

// Icons
import { BiLogIn } from "react-icons/bi";
import { AiFillHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { MdAppRegistration } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

//Features Helpers
import LOGIN from "../Features/Auth/Login";
import { selectAll as selectLanguages } from "../Features/Common/GetLanguagesTranslate/selector";
import LogOutButton from "../Features/Auth/Logout/component/LogOutButton";

//Quality of Card & Wishlist
import CART from "../Features/ShoppingCart/Cart";
import WISHLIST from "../Features/WishList/WishList";
import { BASE_SITE_URL } from "../Utils/constants";

export default function NavBar() {
   const navigate = useNavigate();
   let languages = useSelector((state) => selectLanguages(state));
   let loginState = useSelector((state) => LOGIN.SELECTORS.selectAll(state));

   let lang = app.getCookie("language", "").split(",");
   var IsAr = lang[0] == "ar";
   let isLoggedIn = loginState && !loginState.customer_roles.includes("Guests");

   const [currency, setCurrency] = useState();
   const [control, setControl] = useState({ Show: false });
   const [model, setModel] = useState({
      search: ""
   });



   async function changeLang(langName, langId) {
      app.setCookie("language", langName + "," + langId);
      window.location.reload();
   }

   var Language = (
      <div className="d-flex mt-3">
         {languages.map((lang, index) => (
            <img
               key={index}
               onClick={() => changeLang(lang.language.UniqueSeoCode, lang.language.Id)}
               className="mx-1"
               data-bs-toggle="tooltip"
               title={lang.language.FlagImageFileName !== "us.png" ? "AR-eg" : "EN"}
               src={"https://sawa.express//images/flags/" + lang.language.FlagImageFileName}
               alt=""
               style={{ cursor: "pointer" }}
            />
         ))}
      </div>
   );

   let Cards = useSelector((state) => CART.SELECTORS.selectAll(state)).items;
   let Wishlist = useSelector((state) => WISHLIST.SELECTORS.selectAll(state)).items;
   let CardItems = 0;
   let WishlistItems = 0;
   Cards.map((t) => (CardItems += t.quantity));
   Wishlist.map((t) => (WishlistItems += t.quantity));

   return (
      <>
         <div className="row py-2 border-bottom border-primary">
            <div className="col-md-auto">
               <div className="row justify-content-lg-start justify-content-center">
                  <div className="w-auto">
                     <Select
                        Options={[
                           { Key: "0", Vlaue: "Us Dollar" },
                           { Key: "1", Vlaue: "United Arab Emirates Dirham" },
                        ]}
                        Model={currency}
                        Clicked={() => { }}
                        OnChange={(currency) => {
                           setCurrency(currency);
                        }}
                        Key="Key"
                        Value="Vlaue"
                     />
                  </div>
                  <div className="w-auto">{Language}</div>
               </div>
               <hr className="d-md-none" />
            </div>
            <div className="row col p-0 p-sm-auto mx-auto justify-content-center justify-content-md-end mt-1 gap-2">
               {!isLoggedIn && (
                  <Link
                     to="/Register"
                     className="text-center my-1 col-auto text-decoration-none text-muted link-primary p-0 p-sm-auto"
                  >
                     <MdAppRegistration size="25" className="text-dark mx-auto mx-sm-auto" />
                     <span className="fs-6"> {app.translate("account.register.button")}</span>
                  </Link>
               )}
               <Link
                  to={isLoggedIn ? "/MyAccount" : "/Login"}
                  className="text-center my-1 col-auto text-decoration-none text-muted link-primary p-0 p-sm-auto"
               >
                  {isLoggedIn ? (
                     <BsFillPersonFill size="25" className="text-dark mx-auto mx-sm-auto" />
                  ) : (
                     <BiLogIn size="25" className="text-dark mx-2" />
                  )}
                  <span className="fs-6"> {app.translate(isLoggedIn ? "account.myaccount" : "account.login")}</span>
               </Link>
               {isLoggedIn && (
                  <div className="my-1 col-auto p-0 ">
                     <LogOutButton />
                  </div>
               )}
               <Link
                  to="/wishList"
                  className="text-center my-1 col-auto text-decoration-none text-muted link-primary p-0 p-sm-auto"
               >
                  <AiFillHeart size="25" className="text-dark mx-auto mx-sm-auto" />
                  <span className="fs-6">{app.translate("wishlist")}</span>
                  {"( " + WishlistItems + " )"}
               </Link>
               <div
                  style={{ cursor: "pointer" }}
                  className="d-flex text-center col-auto my-1 text-decoration-none text-muted link-primary bg-light rounded-1 show "
                  onClick={() => navigate("/cart")}
               >
                  <FaShoppingCart size="25" className="text-dark mx-2" />
                  <span className="fs-6">{app.translate("shoppingcart")}</span>
                  {"( " + CardItems + " )"}
                  <div className="show1 text-dark card shadow p-2 " style={IsAr ? { left: "6.5%" } : { right: "5%" }}>
                     <ShoppingCartDialog CardItems={CardItems} />
                  </div>
               </div>
            </div>
         </div>
         <div className="row my-2 border-bottom border-primary py-1">
            <div className="w-auto col-lg m-auto">
               <img
                  src={`${BASE_SITE_URL}/images/thumbs/0001079_Component%2036%20%E2%80%93%2026.png`}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  alt="sawa.express Logo"
               />
            </div>
            <div className="w-auto my-1 m-auto">
               <div className="border rounded bg-light input-group border-primary position-relative">
                  <Form.Control
                     type="search"
                     placeholder={app.translate("search.searchbox.text.label")}
                     className=" border-1 rounded-1 border-primary"
                     aria-label="Search"
                     style={{ zIndex: "0", paddingRight: "2cm" }}
                     onChange={(e) => {
                        setModel(old => ({ ...old, search: e.target.value }))
                     }}
                  />
                  <Button
                     Label={app.translate("search")}
                     Class={
                        "h-100 w-25 rounded-1 position-absolute top-0 bg-primary text-dark " +
                        (lang === "ar" ? "start-0" : "end-0")
                     }
                     Clicked={() => navigate('/Search', { state: model.search })}
                  />
               </div>
            </div>
         </div>
      </>
   );
}
