import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'ArrayOfObject';
export const Model = {
  template_view_path: "",
  manufacturer_model: {
    name: "",
    description: "",
    meta_keywords: "",
    meta_description: "",
    meta_title: "",
    se_name: "",
    picture_model: {
      image_url: "",
      thumb_image_url: "",
      full_size_image_url: "",
      title: "",
      alternate_text: "",
      custom_properties: { ...CustomProperties }
    },
    featured_products: [
      {
        name: "",
        short_description: "",
        full_description: "",
        se_name: "",
        sku: "",
        product_type: "",
        mark_as_new: true,
        product_price: {
          old_price: "",
          price: "",
          price_value: 0,
          base_price_p_ang_v: "",
          disable_buy_button: true,
          disable_wishlist_button: true,
          disable_add_to_compare_list_button: true,
          available_for_pre_order: true,
          pre_order_availability_start_date_time_utc: "",
          is_rental: true,
          force_redirection_after_adding_to_cart: true,
          display_tax_shipping_info: true,
          custom_properties: { ...CustomProperties }
        },
        default_picture_model: {
          image_url: "",
          thumb_image_url: "",
          full_size_image_url: "",
          title: "",
          alternate_text: "",
          custom_properties: { ...CustomProperties }
        },
        product_specification_model: {
          groups: [
            {
              name: "",
              attributes: [
                {
                  name: "",
                  values: [
                    {
                      attribute_type_id: 0,
                      value_raw: "",
                      color_squares_rgb: "",
                      custom_properties: { ...CustomProperties }
                    }
                  ],
                  id: 0,
                  custom_properties: { ...CustomProperties }
                }
              ],
              id: 0,
              custom_properties: { ...CustomProperties }
            }
          ],
          custom_properties: { ...CustomProperties }
        },
        review_overview_model: {
          product_id: 0,
          product_rating: 0,
          rating_sum: 0,
          total_reviews: 0,
          allow_customer_reviews: true,
          can_add_new_review: true,
          stars_details: [
            {
              star_type: 0,
              percentage_rating: 0,
              raters_count: 0,
              custom_properties: { ...CustomProperties }
            }
          ],
          custom_properties: { ...CustomProperties }
        },
        id: 0,
        custom_properties: { ...CustomProperties }
      }
    ],
    catalog_products_model: {
      use_ajax_loading: true,
      warning_message: "",
      no_result_message: "",
      price_range_filter: {
        enabled: true,
        selected_price_range: {
          from: 0,
          to: 0,
          custom_properties: { ...CustomProperties }
        },
        available_price_range: {
          from: 0,
          to: 0,
          custom_properties: { ...CustomProperties }
        },
        custom_properties: { ...CustomProperties }
      },
      specification_filter: {
        enabled: true,
        attributes: [
          {
            name: "",
            values: [
              {
                name: "",
                color_squares_rgb: "",
                selected: true,
                id: 0,
                custom_properties: { ...CustomProperties }
              }
            ],
            id: 0,
            custom_properties: { ...CustomProperties }
          }
        ],
        custom_properties: { ...CustomProperties }
      },
      manufacturer_filter: {
        enabled: true,
        manufacturers: [
          {
            disabled: true,
            group: {
              disabled: true,
              name: ""
            },
            selected: true,
            text: "",
            value: ""
          }
        ],
        custom_properties: { ...CustomProperties }
      },
      allow_product_sorting: true,
      available_sort_options: [
        {
          disabled: true,
          group: {
            disabled: true,
            name: ""
          },
          selected: true,
          text: "",
          value: ""
        }
      ],
      allow_product_view_mode_changing: true,
      available_view_modes: [
        {
          disabled: true,
          group: {
            disabled: true,
            name: ""
          },
          selected: true,
          text: "",
          value: ""
        }
      ],
      allow_customers_to_select_page_size: true,
      page_size_options: [
        {
          disabled: true,
          group: {
            disabled: true,
            name: ""
          },
          selected: true,
          text: "",
          value: ""
        }
      ],
      order_by: 0,
      view_mode: "",
      products: [
        {
          name: "",
          short_description: "",
          full_description: "",
          se_name: "",
          sku: "",
          product_type: "",
          mark_as_new: true,
          product_price: {
            old_price: "",
            price: "",
            price_value: 0,
            base_price_p_ang_v: "",
            disable_buy_button: true,
            disable_wishlist_button: true,
            disable_add_to_compare_list_button: true,
            available_for_pre_order: true,
            pre_order_availability_start_date_time_utc: "",
            is_rental: true,
            force_redirection_after_adding_to_cart: true,
            display_tax_shipping_info: true,
            custom_properties: { ...CustomProperties }
          },
          default_picture_model: {
            image_url: "",
            thumb_image_url: "",
            full_size_image_url: "",
            title: "",
            alternate_text: "",
            custom_properties: { ...CustomProperties }
          },
          product_specification_model: {
            groups: [
              {
                name: "",
                attributes: [
                  {
                    name: "",
                    values: [
                      {
                        attribute_type_id: 0,
                        value_raw: "",
                        color_squares_rgb: "",
                        custom_properties: { ...CustomProperties }
                      }
                    ],
                    id: 0,
                    custom_properties: { ...CustomProperties }
                  }
                ],
                id: 0,
                custom_properties: { ...CustomProperties }
              }
            ],
            custom_properties: { ...CustomProperties }
          },
          review_overview_model: {
            product_id: 0,
            product_rating: 0,
            rating_sum: 0,
            total_reviews: 0,
            allow_customer_reviews: true,
            can_add_new_review: true,
            stars_details: [
              {
                star_type: 0,
                percentage_rating: 0,
                raters_count: 0,
                custom_properties: { ...CustomProperties }
              }
            ],
            custom_properties: { ...CustomProperties }
          },
          id: 0,
          custom_properties: { ...CustomProperties }
        }
      ],
      page_index: 0,
      page_number: 0,
      page_size: 0,
      total_items: 0,
      total_pages: 0,
      first_item: 0,
      last_item: 0,
      has_previous_page: true,
      has_next_page: true,
      custom_properties: { ...CustomProperties }
    },
    id: 0,
    custom_properties: { ...CustomProperties }
  }

}





