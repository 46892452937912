import React from "react";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";

export default function RadioBox(props) {
  function handler(event) {
    const { checked } = event.target;
    if (props.OnChange && typeof props.OnChange === "function")
      props.OnChange(checked);
  }
  return (
    <>

    <div className="">
      <input
        type="radio"
        name={props.Name}
        disabled={props.Disabled}
        className="form-check-input"
        onChange={handler}
        checked={props.Value}
        id={props.Key}
      />
      <label className={"mx-1 " + props.Class} htmlFor={props.Key}>
        {app.translate(props.Label)}
        
      </label>
    </div>
    </>
  );
}

RadioBox.propTypes = {
  Address: PropTypes.string,
  OnChange: PropTypes.func.isRequired,
  Name: PropTypes.string,
  Disabled: PropTypes.bool,
  Class: PropTypes.string,
  Label: PropTypes.string,
  Key: PropTypes.string,
};
RadioBox.defaultProps = {
  Class: "",
  Name: "chk",
  Label: "",
  Disabled: false,
};