import PageHandle from "../../../../Services/UI/PageHandling";
import {selectAll, selectStatus, selectError} from "../selector";
import {Card} from "../../../../Components";
import * as app from "../../../../Services/AppService";
import {setRequest as setId} from "../middleware";
function DisplayRelatedProducts(props) {
   setId(props.productID);
   return (
      <PageHandle
         selectAll={selectAll}
         selectError={selectError}
         selectStatus={selectStatus}
         ParentFolder="Products"
         ChildFolder="RelatedProducts"
         Child={(data) => Design(data)}
      />
   );
}
function Design(data) {
   let lang = app.getCookie("language", "ar");
   if (data) {
      return (
         <div className="mt-5">
            <h3 className={"fw-bold" + (lang.substr(0, 2) === "ar" ? "text-end" : "text-start")}>
               {app.translate("products.relatedproducts")}
            </h3>
            <hr className="border border-primary" />
            <div className="d-md-flex d-sm-block flex-wrap">
               {data.map((apiData, index) => {
                  return (
                     <div className="col-lg-3 col-md-4 col-sm-12 px-2 my-5">
                        <Card
                           Product={true}
                           Tooltip={apiData.name}
                           productId={apiData.id}
                           LinkTo={apiData.se_name !== "" ? apiData.se_name : apiData.name}
                           Id={apiData.id}
                           Rating={apiData.review_overview_model.product_rating}
                           Title={apiData.name}
                           Picture={
                              apiData.default_picture_model.image_url != null
                                 ? apiData.default_picture_model.image_url
                                 : ""
                           }
                           Price={apiData.product_price.price}
                           se_name={apiData.se_name}
                        />
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }
}
export default DisplayRelatedProducts;
