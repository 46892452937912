import * as http from '../../../Services/http'

const url = `/Product/CompareProducts`;
const addUrl = "/Product/AddProductToCompareList/";
const deleteUrl = `/Product/RemoveProductFromCompareList`;
const deleteAll = `/Product/ClearCompareList`;

export function CompareProducts() {
  return http.Get(url)
}

export function AddProductToCompareList(id) {
  return http.Get(addUrl + id)
}

export function DeleteProducts(id) {
  return http.Get(deleteUrl + id)
}

export function ClearCompareList() {
  return http.Get(deleteAll)
}
