import * as ACTIONS from "./action";
import * as APIS from "./api";
import * as COMPONENTS from "./component";
import * as SELECTORS from "./selector";
import MODELS from "./models";
const COUNTRIES = {
   APIS,
   SELECTORS,
   COMPONENTS,
   MODELS,
   ACTIONS,
};
export default COUNTRIES;
