export const Type = "object";
// export const Model = {
//    q: "",
//    cid: 0,
//    isc: true,
//    mid: 0,
//    vid: 0,
//    sid: true,
//    advs: true,
//    asv: true,
//    catalog_products_model: {
//       use_ajax_loading: true,
//       warning_message: "",
//       no_result_message: "",
//       price_range_filter: {
//          enabled: true,
//          selected_price_range: {
//             from: 0,
//             to: 0,
//             custom_properties: {
//                additionalProp1: "",
//                additionalProp2: "",
//                additionalProp3: "",
//             },
//          },
//          available_price_range: {
//             from: 0,
//             to: 0,
//             custom_properties: {
//                additionalProp1: "",
//                additionalProp2: "",
//                additionalProp3: "",
//             },
//          },
//          custom_properties: {
//             additionalProp1: "",
//             additionalProp2: "",
//             additionalProp3: "",
//          },
//       },
//       specification_filter: {
//          enabled: true,
//          attributes: [
//             {
//                name: "",
//                values: [
//                   {
//                      name: "",
//                      color_squares_rgb: "",
//                      selected: true,
//                      id: 0,
//                      custom_properties: {
//                         additionalProp1: "",
//                         additionalProp2: "",
//                         additionalProp3: "",
//                      },
//                   },
//                ],
//                id: 0,
//                custom_properties: {
//                   additionalProp1: "",
//                   additionalProp2: "",
//                   additionalProp3: "",
//                },
//             },
//          ],
//          custom_properties: {
//             additionalProp1: "",
//             additionalProp2: "",
//             additionalProp3: "",
//          },
//       },
//       manufacturer_filter: {
//          enabled: true,
//          manufacturers: [
//             {
//                disabled: true,
//                group: {
//                   disabled: true,
//                   name: "",
//                },
//                selected: true,
//                text: "",
//                value: "",
//             },
//          ],
//          custom_properties: {
//             additionalProp1: "",
//             additionalProp2: "",
//             additionalProp3: "",
//          },
//       },
//       allow_product_sorting: true,
//       available_sort_options: [
//          {
//             disabled: true,
//             group: {
//                disabled: true,
//                name: "",
//             },
//             selected: true,
//             text: "",
//             value: "",
//          },
//       ],
//       allow_product_view_mode_changing: true,
//       available_view_modes: [
//          {
//             disabled: true,
//             group: {
//                disabled: true,
//                name: "",
//             },
//             selected: true,
//             text: "",
//             value: "",
//          },
//       ],
//       allow_customers_to_select_page_size: true,
//       page_size_options: [
//          {
//             disabled: true,
//             group: {
//                disabled: true,
//                name: "",
//             },
//             selected: true,
//             text: "",
//             value: "",
//          },
//       ],
//       order_by: 0,
//       view_mode: "",
//       products: [
//          {
//             name: "",
//             short_description: "",
//             full_description: "",
//             se_name: "",
//             sku: "",
//             product_type: "SimpleProduct",
//             mark_as_new: true,
//             product_price: {
//                old_price: "",
//                price: "",
//                price_value: 0,
//                base_price_p_ang_v: "",
//                disable_buy_button: true,
//                disable_wishlist_button: true,
//                disable_add_to_compare_list_button: true,
//                available_for_pre_order: true,
//                pre_order_availability_start_date_time_utc: "2023-04-19T09:14:31.859Z",
//                is_rental: true,
//                force_redirection_after_adding_to_cart: true,
//                display_tax_shipping_info: true,
//                custom_properties: {
//                   additionalProp1: "",
//                   additionalProp2: "",
//                   additionalProp3: "",
//                },
//             },
//             default_picture_model: {
//                image_url: "",
//                thumb_image_url: "",
//                full_size_image_url: "",
//                title: "",
//                alternate_text: "",
//                custom_properties: {
//                   additionalProp1: "",
//                   additionalProp2: "",
//                   additionalProp3: "",
//                },
//             },
//             product_specification_model: {
//                groups: [
//                   {
//                      name: "",
//                      attributes: [
//                         {
//                            name: "",
//                            values: [
//                               {
//                                  attribute_type_id: 0,
//                                  value_raw: "",
//                                  color_squares_rgb: "",
//                                  custom_properties: {
//                                     additionalProp1: "",
//                                     additionalProp2: "",
//                                     additionalProp3: "",
//                                  },
//                               },
//                            ],
//                            id: 0,
//                            custom_properties: {
//                               additionalProp1: "",
//                               additionalProp2: "",
//                               additionalProp3: "",
//                            },
//                         },
//                      ],
//                      id: 0,
//                      custom_properties: {
//                         additionalProp1: "",
//                         additionalProp2: "",
//                         additionalProp3: "",
//                      },
//                   },
//                ],
//                custom_properties: {
//                   additionalProp1: "",
//                   additionalProp2: "",
//                   additionalProp3: "",
//                },
//             },
//             review_overview_model: {
//                product_id: 0,
//                product_rating: 0,
//                rating_sum: 0,
//                total_reviews: 0,
//                allow_customer_reviews: true,
//                can_add_new_review: true,
//                stars_details: [
//                   {
//                      star_type: 0,
//                      percentage_rating: 0,
//                      raters_count: 0,
//                      custom_properties: {
//                         additionalProp1: "",
//                         additionalProp2: "",
//                         additionalProp3: "",
//                      },
//                   },
//                ],
//                custom_properties: {
//                   additionalProp1: "",
//                   additionalProp2: "",
//                   additionalProp3: "",
//                },
//             },
//             id: 0,
//             custom_properties: {
//                additionalProp1: "",
//                additionalProp2: "",
//                additionalProp3: "",
//             },
//          },
//       ],
//       page_index: 0,
//       page_number: 0,
//       page_size: 0,
//       total_items: 0,
//       total_pages: 0,
//       first_item: 0,
//       last_item: 0,
//       has_previous_page: true,
//       has_next_page: true,
//       custom_properties: {
//          additionalProp1: "",
//          additionalProp2: "",
//          additionalProp3: "",
//       },
//    },
//    available_categories: [
//       {
//          disabled: true,
//          group: {
//             disabled: true,
//             name: "",
//          },
//          selected: true,
//          text: "",
//          value: "",
//       },
//    ],
//    available_manufacturers: [
//       {
//          disabled: true,
//          group: {
//             disabled: true,
//             name: "",
//          },
//          selected: true,
//          text: "",
//          value: "",
//       },
//    ],
//    available_vendors: [
//       {
//          disabled: true,
//          group: {
//             disabled: true,
//             name: "",
//          },
//          selected: true,
//          text: "",
//          value: "",
//       },
//    ],
//    custom_properties: {
//       additionalProp1: "",
//       additionalProp2: "",
//       additionalProp3: "",
//    },
// };
export const Model = {
   q: "",
   cid: 0,
   isc: true,
   mid: 0,
   vid: 0,
   sid: true,
   advs: true,
   asv: true,
   catalog_products_model: {
      use_ajax_loading: true,
      warning_message: null,
      no_result_message: null,
      price_range_filter: {
         enabled: true,
         selected_price_range: {
            from: 0,
            to: 0,
            custom_properties: {},
         },
         custom_properties: {},
      },
      specification_filter: {
         enabled: false,
         attributes: [],
         custom_properties: {},
      },
      manufacturer_filter: {
         enabled: false,
         manufacturers: [],
         custom_properties: {},
      },
      allow_product_sorting: true,
      available_sort_options: [
         {
            disabled: false,
            group: null,
            selected: true,
            text: "",
            value: "0",
         },
      ],
      allow_product_view_mode_changing: true,
      available_view_modes: [{}],
      allow_customers_to_select_page_size: true,
      page_size_options: [{}],
      order_by: 0,
      view_mode: "string",
      products: [
         {
            name: "",
            short_description: "",
            full_description: "",
            se_name: "",
            sku: "",
            product_type: "",
            mark_as_new: false,
            product_price: {
               old_price: null,
               price: "",
               price_value: 0,
               base_price_p_ang_v: null,
               disable_buy_button: false,
               disable_wishlist_button: false,
               disable_add_to_compare_list_button: false,
               available_for_pre_order: false,
               pre_order_availability_start_date_time_utc: null,
               is_rental: false,
               force_redirection_after_adding_to_cart: false,
               display_tax_shipping_info: false,
               custom_properties: {},
            },
            default_picture_model: {
               image_url: "",
               thumb_image_url: null,
               full_size_image_url: "",
               title: "",
               alternate_text: "",
               custom_properties: {},
            },
            product_specification_model: {
               groups: [],
               custom_properties: {},
            },
            review_overview_model: {
               product_id: 0,
               product_rating: 0,
               rating_sum: 0,
               total_reviews: 0,
               allow_customer_reviews: true,
               can_add_new_review: true,
               stars_details: [
                  {
                     star_type: 1,
                     percentage_rating: 0,
                     raters_count: 0,
                     custom_properties: {},
                  },
               ],
               custom_properties: {},
            },
            id: 0,
            custom_properties: {},
         },
      ],
      page_index: 0,
      page_number: 1,
      page_size: 6,
      total_items: 1,
      total_pages: 1,
      first_item: 1,
      last_item: 1,
      has_previous_page: false,
      has_next_page: false,
      custom_properties: {},
   },
   available_categories: [
      {
         disabled: true,
         group: {
            disabled: true,
            name: "string",
         },
         selected: true,
         text: "string",
         value: "string",
      },
   ],
   custom_properties: {
      additionalProp1: "string",
      additionalProp2: "string",
      additionalProp3: "string",
   },
};
