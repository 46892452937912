import { createAsyncThunk } from '@reduxjs/toolkit';
import * as app from '../../../Services/AppService';
import * as APIs from './api'


export const fetchData = createAsyncThunk('CompareProducts/fetchData', async () => {
  const response = await APIs.CompareProducts();
  return response.data
})

export const AddProductToCompareList = (Id, OnSuccessFunc, OnErrorFunc) => {
  app.ChangeLinesSpinnerStatus(true)
  APIs.AddProductToCompareList(Id)
    .then(result => {
      app.ShowTopMessageAlert(`${result.data}`, '')
      // store.dispatch(AddAddress(result.data.model.address))
      if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
        OnSuccessFunc(result)
    })
    .catch(error => {
      app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
      if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
        OnErrorFunc(error)
    })
  app.ChangeLinesSpinnerStatus(false)
}

export const DeleteProducts = (Id, OnSuccessFunc, OnErrorFunc) => {
  app.ChangeLinesSpinnerStatus(true)
  APIs.DeleteProducts(Id)
    .then(result => {
      // app.ShowTopMessageAlert(`${result.data}`, '')
      // store.dispatch(DeleteAddress(Id))
      if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
        OnSuccessFunc(result)
    })
    .catch(error => {
      // app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
      if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
        OnErrorFunc(error)
    })
  app.ChangeLinesSpinnerStatus(false)
}

export const ClearCompareList = (OnSuccessFunc, OnErrorFunc) => {
  app.ChangeLinesSpinnerStatus(true)
  APIs.ClearCompareList()
    .then(result => {
      // app.ShowTopMessageAlert(`${result.data}`, '')
      // store.dispatch(DeleteAddress(Id))
      if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
        OnSuccessFunc(result)
    })
    .catch(error => {
      // app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
      if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
        OnErrorFunc(error)
    })
  app.ChangeLinesSpinnerStatus(false)
}


