import CustomProperties from '../../../../Models/CustomProperties'
import Address from '../../Models/Address'
export const Type = 'ArrayOfObject';

export const Model = [
    {
        addresses: [Address],
        custom_properties: CustomProperties
    }
]

export const AddOrUpdateAddressModel = {
    model: {
        address: Address,
        custom_properties: CustomProperties,
    },
    errors: [""]
}

export const DeleteAddressMessage = ''