import {Button, Rating} from "../index";
import PropTypes from "prop-types";
import {DiGitCompare} from "react-icons/di";
import * as app from "../../Services/AppService";
import {Link, useLocation} from "react-router-dom";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {AddProductButton} from "../../Features/ShoppingCart/AddProduct/component";
import COMPARE_PRODUCT from "../../Features/Products/CompareProducts";
import {useDispatch} from "react-redux";
import ApiStatusManager from "../../Services/ApiStatusManager";
import "./Card.css";

function Card(props) {
   const location = useLocation();
   const dispatch = useDispatch();

   function handleImageOnClick() {
      if (props.ImgClicked && typeof props.ImgClicked === "function") props.ImgClicked();
   }
   function blur1(e) {
      e.target.style.filter = "opacity(100%)";
   }
   function blur0(e) {
      e.target.style.filter = "opacity(80%)";
   }

   async function AddToCompare(Id) {
      COMPARE_PRODUCT.ACTIONS.AddProductToCompareList(
         Id,
         (resSuccess) => {
            app.ShowTopMessageAlert("products.producthasbeenaddedtocomparelist.link", "compareProducts", "success");
            dispatch(ApiStatusManager("Products", "Compare_Prdoucts"));
         },
         (resError) => app.ShowTopMessageAlert("error", "", "danger")
      );
   }

   return (
      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">{app.translate(props.Tooltip)}</Tooltip>}>
         {({ref, ...triggerHandler}) => (
            <div
               className={"card border border-1 border-light shadow shadow-1 rounded rounded-2 align-items-center mb-5 bg-transparent "+props.Class}
               style={{height: "12cm"}}
            >
               <>
                  <div className="row w-100 h-50">
                     <div style={{zIndex: 2}}>
                        <AddProductButton type="Wishlist" ProductID={props.productId} ProductName={props.se_name} />
                     </div>
                     <Link
                        to={`/ProductDetails/${props.LinkTo}/${props.productId}`}
                        state={{id: props.productId, from: location.pathname}}
                        className={"w-auto mx-auto p-0 h-100 d-flex justify-content-center align-items-center"}
                        {...triggerHandler}
                     >
                        <img
                           onMouseOver={blur0}
                           onMouseLeave={blur1}
                           src={props.Picture}
                           onClick={() => {
                              handleImageOnClick();
                           }}
                           className="mw-100 mh-100 w-auto h-auto mt-5"
                           alt=""
                           style={{filter: "opacity(90%)", zIndex: 1}}
                           ref={ref}
                        />
                     </Link>
                  </div>
                  <div className="h-50 w-100 d-flex flex-column justify-content-center align-items-center">
                     <div className="row justify-content-center align-items-center">
                        <Link
                           to={`/ProductDetails/${props.LinkTo}/${props.productId}`}
                           state={{id: props.productId}}
                           className="text-decoration-none"
                        >
                           <h5 className="card-text h-50 text-dark" style={{fontSize: "100%"}}>
                              {app.translate(props.Title)}
                           </h5>
                        </Link>
                     </div>
                     <div className="row w-100 justify-content-center align-items-center mt-5">
                        <Rating Class="d-flex justify-content-center" Model={props.Rating} />
                        {props.old_price !== 0 && (
                           <p className="text-decoration-line-through text-muted fw-light text-center">
                              {props.old_price}
                           </p>
                        )}
                        <p className="card-text text-center text-muted"> {props.Price}</p>
                     </div>
                  </div>
               </>
               <div className=" position-absolute top-100 start-50 translate-middle btn-group card-body w-100">
                  <Button
                     Icon=""
                     Label=""
                     Class=" mt-2 bg-light shadow mx-1 d-flex justify-content-center align-items-center"
                     Clicked={() => AddToCompare(props.Id)}
                  >
                     <DiGitCompare className="m-1 fs-4" />
                  </Button>

                  <AddProductButton type="ShoppingCart" ProductID={props.productId} ProductName={props.se_name} />
               </div>
            </div>
         )}
      </OverlayTrigger>
   );
}
export default Card;

Card.propTypes = {
   Clicked: PropTypes.func,
   Class: PropTypes.string,
   Disabled: PropTypes.bool,
   Title: PropTypes.string,
   Icon: PropTypes.string,
   Rating: PropTypes.number,
   Price: PropTypes.string,
   Picture: PropTypes.string,
   ImgClicked: PropTypes.func,
   LinkTo: PropTypes.string,
   productId: PropTypes.number,
   Product: PropTypes.bool,
   Tooltip: PropTypes.string,
};
Card.defaultProps = {
   Class: "btn-primary shadow-sm ",
   Disabled: false,
   Title: "title",
   Label: "",
   Icon: "",
   Picture: "Group 4.png",
   Rating: 2,
   Price: "121",
   Tooltip: "",
};
