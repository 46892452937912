import CustomProperties from "../../../../Models/CustomProperties";
export const Type = "Object";
export const Model = {
   name: "",
   short_description: "",
   full_description: "",
   se_name: "",
   sku: "",
   product_type: "SimpleProduct",
   mark_as_new: true,
   product_price: {
      old_price: "",
      price: "",
      price_value: 0,
      base_price_p_ang_v: "",
      disable_buy_button: true,
      disable_wishlist_button: true,
      disable_add_to_compare_list_button: true,
      available_for_pre_order: true,
      pre_order_availability_start_date_time_utc: "",
      is_rental: true,
      force_redirection_after_adding_to_cart: true,
      display_tax_shipping_info: true,
      custom_properties: {...CustomProperties},
   },
   default_picture_model: {
      image_url: "",
      thumb_image_url: "",
      full_size_image_url: "",
      title: "",
      alternate_text: "",
      custom_properties: {...CustomProperties},
   },
   product_specification_model: {
      groups: [
         {
            name: "",
            attributes: [
               {
                  name: "",
                  values: [
                     {
                        attribute_type_id: 0,
                        value_raw: "",
                        color_squares_rgb: "",
                        custom_properties: {...CustomProperties},
                     },
                  ],
                  id: 0,
                  custom_properties: {...CustomProperties},
               },
            ],
            id: 0,
            custom_properties: {...CustomProperties},
         },
      ],
      custom_properties: {...CustomProperties},
   },
   review_overview_model: {
      product_id: 0,
      product_rating: 0,
      rating_sum: 0,
      total_reviews: 0,
      allow_customer_reviews: true,
      can_add_new_review: true,
      stars_details: [
         {
            star_type: 0,
            percentage_rating: 0,
            raters_count: 0,
            custom_properties: {...CustomProperties},
         },
      ],
      custom_properties: {...CustomProperties},
   },
   id: 0,
   custom_properties: {...CustomProperties},
};
