import CustomProperties from '../../../../Models/CustomProperties'
export const Type = 'Object';

const Picture = {
    image_url: "",
    thumb_image_url: "",
    full_size_image_url: "",
    title: "",
    alternate_text: "",
    custom_properties: CustomProperties
}
const Items = [{
    order_item_guid: "",
    sku: "",
    product_id: 0,
    product_name: "",
    picture: Picture,
    product_se_name: "",
    unit_price: "",
    sub_total: "",
    quantity: 0,
    attribute_info: "",
    rental_info: null,
    vendor_name: "",
    download_id: 0,
    license_id: 0,
    id: 0,
    custom_properties: CustomProperties
}]
const PickupAddress = {
    first_name: null,
    email: null,
    company_enabled: false,
    company_required: false,
    company: null,
    country_enabled: false,
    country_id: null,
    country_name: null,
    state_province_enabled: false,
    state_province_id: null,
    state_province_name: null,
    county_enabled: false,
    county_required: false,
    county: null,
    city_enabled: false,
    city_required: false,
    city: null,
    street_address_enabled: false,
    street_address_required: false,
    address1: null,
    street_address2_enabled: false,
    street_address2_required: false,
    address2: null,
    zip_postal_code_enabled: false,
    zip_postal_code_required: false,
    zip_postal_code: null,
    phone_enabled: false,
    phone_required: false,
    phone_number: null,
    fax_enabled: false,
    fax_required: false,
    fax_number: null,
    latitude: null,
    longitude: null,
    available_countries: [],
    available_states: [],
    formatted_custom_address_attributes: null,
    custom_address_attributes: [],
    id: 0,
    custom_properties:CustomProperties
}
const ShippingAddress =
{
    first_name: "",
    email: "",
    company_enabled: false,
    company_required: false,
    company: null,
    country_enabled: true,
    country_id: 715,
    country_name: "",
    state_province_enabled: false,
    state_province_id: null,
    state_province_name: null,
    county_enabled: false,
    county_required: false,
    county: null,
    city_enabled: true,
    city_required: false,
    city: null,
    street_address_enabled: true,
    street_address_required: false,
    address1: null,
    street_address2_enabled: true,
    street_address2_required: false,
    address2: null,
    zip_postal_code_enabled: true,
    zip_postal_code_required: false,
    zip_postal_code: null,
    phone_enabled: true,
    phone_required: true,
    phone_number: "",
    fax_enabled: false,
    fax_required: false,
    fax_number: null,
    latitude: "",
    longitude: "",
    available_countries: [],
    available_states: [],
    formatted_custom_address_attributes: "",
    custom_address_attributes: [],
    id: 3185,
    custom_properties:CustomProperties
}


const BillingAddress = {
    first_name: "",
    email: "",
    company_enabled: false,
    company_required: false,
    company: null,
    country_enabled: true,
    country_id: 0,
    country_name: "",
    state_province_enabled: false,
    state_province_id: null,
    state_province_name: null,
    county_enabled: false,
    county_required: false,
    county: null,
    city_enabled: true,
    city_required: false,
    city: null,
    street_address_enabled: true,
    street_address_required: false,
    address1: null,
    street_address2_enabled: true,
    street_address2_required: false,
    address2: null,
    zip_postal_code_enabled: true,
    zip_postal_code_required: false,
    zip_postal_code: null,
    phone_enabled: true,
    phone_required: true,
    phone_number: "",
    fax_enabled: false,
    fax_required: false,
    fax_number: null,
    latitude: "",
    longitude: "",
    available_countries: [],
    available_states: [],
    formatted_custom_address_attributes: "",
    custom_address_attributes: [],
    id: 0,
    custom_properties: CustomProperties
}
export const Model = {
    model: {
        print_mode: false,
        pdf_invoice_disabled: false,
        custom_order_number: "",
        created_on: "",
        order_status: "",
        is_re_order_allowed: false,
        is_return_request_allowed: false,
        is_shippable: true,
        pickup_in_store: false,
        pickup_address: PickupAddress,
        shipping_status_enum: 0,
        shipping_status: "",
        shipping_address: ShippingAddress,
        shipping_method: "",
        shipments: [],
        billing_address: BillingAddress,
        vat_number: null,
        latitude: null,
        longitude: null,
        payment_method: "",
        payment_method_status: "",
        can_re_post_process_payment: false,
        custom_values: {},
        order_subtotal: "",
        order_sub_total_discount: null,
        order_shipping: "",
        payment_method_additional_fee: null,
        checkout_attribute_info: "",
        prices_include_tax: true,
        display_tax_shipping_info: false,
        tax: "",
        tax_rates: [],
        display_tax: true,
        display_tax_rates: false,
        order_total_discount: null,
        redeemed_reward_points: 0,
        redeemed_reward_points_amount: null,
        order_total: "",
        gift_cards: [],
        show_sku: true,
        items: Items,
        order_notes: [],
        show_vendor_name: true,
        id: 0,
        custom_properties: CustomProperties
    }
}







