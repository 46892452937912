
import Select from "../Form/Select";
import PropTypes from "prop-types";
import * as app from '../../Services/AppService'
import PageHandling from "../../Services/UI/PageHandling";
import { selectAll, selectStatus, selectError } from "../../Features/Auth/GetRegister/selector";

function TimeUtc(props) {
    return (
        <PageHandling
            selectAll={selectAll}
            selectStatus={selectStatus}
            selectError={selectError}
            ParentFolder="Auth"
            ChildFolder="GetRegister"
            oldData={true}
            Child={(data) => Design(data)}
        />
    )

    function Design(TimeUtc) {
        return (
            <Select
                Options={TimeUtc}
                Key='value'
                Value='text'
                OnChange={(newValue) => props.OnChange(newValue)}
                Disabled={props.Disabled}
                Model={props.Model}
            />
        )
    }

}

export default TimeUtc;
TimeUtc.propTypes = {
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    OnChange: PropTypes.func,
};
TimeUtc.defaultProps = {
};
