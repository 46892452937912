import React from "react";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService"

export default function Checkbox(props) {
  function handler(event) {
    const { checked } = event.target;
    if (props.OnChange && typeof props.OnChange == "function")
      props.OnChange(checked);
  }
  return (
    <>

    <div className="">
      <input
        type="checkbox"
        name={props.Name}
        disabled={props.Disabled}
        className="form-check-input"
        onChange={handler}
        checked={props.Value}
      />
      <label htmlFor={props.Name}   className={"ms-2 " + props.Class}>
        {app.translate(props.Label)}
      </label>
    </div>
    </>
  );
}

Checkbox.propTypes = {
  Address: PropTypes.string,
  OnChange: PropTypes.func.isRequired,
  Name: PropTypes.string,
  Disabled: PropTypes.bool,
  Class: PropTypes.string,
  Label: PropTypes.string,
  Value: PropTypes.bool
};
Checkbox.defaultProps = {
  Class: "",
  Name: "chk",
  Label: "",
  Disabled: false,
  Value: false
};