import { createSlice } from '@reduxjs/toolkit';
import * as ResponseModel from './models/response';
import * as AddModel from './models/request';
import { fetchData } from './middleware';
import { CoreInitialState } from '../../../Models/CoreInitialState';

const initialState =
{
    ...CoreInitialState,
    data: ResponseModel.GetType === 'ArrayOfObject' ?
        [ResponseModel.GetModel] : ResponseModel.GetModel,
    PostData: AddModel.PostType === 'ArrayOfObject' ?
        [AddModel.PostModel] : AddModel.PostModel,
}

const reducers = {
    // Local Data Reducersz
    setData(state, action) {
        state.PostData = action.payload
    }
}

function extraReducers(builder) {
    builder
        .addCase(fetchData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(fetchData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.data = action.payload
        })
        .addCase(fetchData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
}
export const AvatarSlice = createSlice({
    name: 'Avatar',
    initialState,
    reducers: { ...reducers },
    extraReducers
})
export const { setData } = AvatarSlice.actions;
export default AvatarSlice.reducer;
