import productId from '../../../Pages/ProductDetails/ProductDetails';
import * as http from '../../../Services/http'
// import RequestModel from './models/index'
import F_Product_Details from '.';
const url = `/Product/GetProductDetails/`;

function GetProductDetails(ProductId) {
  return http.Get(url + ProductId, { updateCartItemId: F_Product_Details.MODELS.Request.updateCartItemId })
}

export default GetProductDetails;