import { combineReducers } from '@reduxjs/toolkit';
import AddressesReducer from './Addresses/reducer';
import CustomerInfoReducer from './CustomerInfo/reducer';
import ProductReviewReducer from './ProductReview/reducer';
import AvatarReducer from './Avatar/reducer';

const CustomerReducer = combineReducers({
    Addresses: AddressesReducer,
    CustomerInfo: CustomerInfoReducer,
    ProductReview: ProductReviewReducer,
    Avatar: AvatarReducer,
})

export default CustomerReducer;