import pictureModel from "../../../../Models/Picture"
import CustomProperties from "../../../../Models/CustomProperties";

export const Type = 'ArrayOfObject';

export const Model = [{
    name: "",
    se_name: "",
    number_of_products: 0,
    include_in_top_menu: true,
    sub_categories: [],// Nothing 
    have_sub_categories: true,
    route: "",
    picture: pictureModel,
    id: 0,
    custom_properties: { ...CustomProperties }
}]


