import React, {useState} from "react";
import {useSelector} from "react-redux";
import Spinner from "../../Utils/Spinner";

import ApiStatusManager from "../ApiStatusManager";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import MessageAlert from "../../Components/MessageAlert";
import * as app from "../AppService";
function ButtonHandling(props) {
   const [control, setControl] = useState({
      showAlert: false,
   });
   const data = useSelector((state) => props.selectAll(state));
   const status = useSelector((state) => props.selectStatus(state));
   const error = useSelector((state) => props.selectError(state));

   const dispatch = useDispatch();

   async function handleClick() {
      if (props.setRequestModel && typeof props.setRequestModel == "function") props.setRequestModel();
      await dispatch(ApiStatusManager(props.ParentFolder, props.ChildFolder));
      if (status == "failed") handleFailed();
      else if (status == "succeeded") handleSucceeded();
   }

   function handleFailed() {
      if (props.handleFailed && typeof props.handleFailed == "function") props.handleFailed(error);
      else if (props.FailedGlobal) setControl((old) => ({...old, showAlert: true}));
   }

   function handleSucceeded() {
      if (props.handleSucceeded && typeof props.handleSucceeded == "function") props.handleSucceeded(data);
      else if (props.SucceededGlobal) setControl((old) => ({...old, showAlert: true}));
   }

   useEffect(() => {
     if (status == 'failed') handleFailed();
     else if (status == 'succeeded') handleSucceeded();
   }, [status])
   return status == "loading" ? (
      <i className={props.SpinnerClass}></i>
   ) : (
      <>
         <button type="button" className={props.Class} onClick={handleClick} disabled={props.Disabled}>
            <span className="mx-2">{props.children}</span>
            {app.translate(props.Label)}
         </button>
         <MessageAlert
            Show={control.showAlert}
            Class={status == "failed" ? "bg-success" : "bg-success"}
            Close={() => setControl((old) => ({...old, showAlert: false}))}
         />
      </>
   );
}

export default ButtonHandling;
