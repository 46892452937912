export const PostType = 'Object';
export const PostModel = {
    Params: {
        fileName: "",
        contentType: ""
    },
    Body: {
        ImageBase64: ""
    }
}








