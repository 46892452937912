import React, { useState, useEffect } from "react";
import PropTypes, { func } from "prop-types";
import * as app from '../../Services/AppService';
import "./Table.css";
import TablePagination from "./Pagination";

export default function Table(props) {
    //---- State ----//
    const [paginationModel, setPaginationModel] = useState({
        totalCount: 0,
        totalPages: 0,
        pageSize: 0,
        pageIndex: 0,
        possiblePageSizes: [
            { Key: 10, Value: 10 },
            { Key: 50, Value: 50 },
            { Key: 100, Value: 100 },
            { Key: 1000, Value: 1000 },
        ],
        pageData: [],
    });
    //---- Change Page Index ----//
    function PageIndexHandle(newIndex) {
        if (newIndex <= paginationModel.totalPages && newIndex>0)
            setPaginationModel((old) => ({ ...old, pageIndex: newIndex }));
    }

    //---- Change Page Size ----//     
    function PageSizeHandle(newSize) {
        setPaginationModel((old) => ({
            ...old,
            pageSize: Number(newSize),
            pageIndex: 1,
            totalPages:
                paginationModel.totalCount < newSize
                    ? 1
                    : Math.ceil(paginationModel.totalCount / newSize),
        }));
    }
    function RowClickedHandler(row) {
        if (props.RowClicked && typeof props.RowClicked == "function") {
            props.RowClicked(row);
        }
    }
    //---- Update data for current page index ----//
    function UpdatePageData() {
        if (props.HidePagination) {
            setPaginationModel((old) => ({ ...old, pageData: props.Data }));
            return;
        }
        const pData = [];

        let remainingCount =
            paginationModel.pageIndex == 1
                ? paginationModel.totalCount
                : paginationModel.totalCount -
                (paginationModel.pageIndex - 1) * paginationModel.pageSize;
        // Check if remaining count of 'data' is positive or not , this meaning there is remaining data to show on table or not ?
        remainingCount = remainingCount <= 0 ? 0 : remainingCount;

        let startIndex =
            paginationModel.pageIndex <= 1
                ? 0
                : (paginationModel.pageIndex - 1) * paginationModel.pageSize;

        let endIndex =
            remainingCount == 0
                ? 0
                : remainingCount <= paginationModel.pageSize
                    ? startIndex + remainingCount
                    : startIndex + paginationModel.pageSize;

        for (var i = startIndex; i < endIndex; i++) {
            pData.push(props.Data[i]);
        }

        setPaginationModel((old) => ({ ...old, pageData: pData }));
    }
    function RowColor(row) {
        if (props.RowColor && typeof props.RowColor == "function")
            return props.RowColor(row);
    }
    //---- Use Effect to load data for first time  ----//

    useEffect(() => {
        let tCount = props.Data.length;
        if (paginationModel.totalCount == props.Data.length) {
            UpdatePageData();
        } else {
            setPaginationModel((old) => ({
                ...old,
                totalCount: tCount,
                pageSize: 10,
                pageIndex: 1,
            }));
        }
    }, [props.Data, props.Data.length]);

    //---- Use Effect to update data when changed  ----//
    useEffect(() => {
        UpdatePageData();
    }, [paginationModel.pageIndex, paginationModel.pageSize]);

    useEffect(() => {
        let totalPages =
            paginationModel.totalCount < paginationModel.pageSize
                ? 1
                : Math.ceil(paginationModel.totalCount / paginationModel.pageSize);
        setPaginationModel((old) => ({ ...old, totalPages }));
        UpdatePageData();
    }, [paginationModel.totalCount]);

    function ColName(col){
        
        if (typeof (col) == "function")
        return col();
        return app.translate(col);
    }

    let rowCountElement = (
        <div className="col-auto mx-2 pt-1 ms-auto text-center bg-light border border-1 rounded">
            <span className="fw-normal px-2">
                {app.translate("CountRecord")} :
                <small className="fw-bold">
                    {props.Data && " " + props.Data.length}
                </small>
            </span>
        </div>);
    //---- RETURN ----//
    return (
        <>
            <div
                className={
                    props.ContainerClass && (" overflow-auto ")   + props.ContainerClass
                }
            >
                <table className={` table table-sm  table-bordered ${props.RowColor ? '' : ''} align-middle table-hover m-0 ${props.Class}`}
                    aria-labelledby="tabelLabel"
                    ref={props.tableRef}
                >
                    <thead className="position-sticky">
                        <tr className={props.TheadTrClass}>
                            {props.Cols.map((col, index) => {
                                return (
                                    <th key={index} scope="col-2"
                                        className={paginationModel.pageData.length > 0 && typeof props.Rows[index] == "function" &&
                                            ((props.Rows[index](paginationModel.pageData[0]).type && props.Rows[index](paginationModel.pageData[0]).type.name === 'Button') ||
                                                (props.Rows[index](paginationModel.pageData[0]).props && props.Rows[index](paginationModel.pageData[0]).props.children &&
                                                 props.Rows[index](paginationModel.pageData[0]).props.children[0] 
                                                && props.Rows[index](paginationModel.pageData[0]).props.children[0].type
                                                  &&  props.Rows[index](paginationModel.pageData[0]).props.children[0].type.name === 'Button'))
                                            ? "text-center position-sticky end-0 bg-body text-secondary" : "text-center fw-normal text-secondary"}
                                    >
                                        {ColName(col)}
                                    </th>
                                )
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {paginationModel.pageData &&
                            paginationModel.pageData.map((row, Rowindex) => (
                                <tr
                                    key={props.Key ? row[props.Key] : Rowindex}
                                    className="fw-lighter rounded text-center "
                                    onClick={() => RowClickedHandler(row)}
                                    style={{ background: RowColor(row) }}
                                >
                                    {props.Rows &&
                                        props.Rows.map((name, index) => {
                                            return (typeof name == "function" && name(row).type == 'td' ? name(row) :
                                                <td key={index + '|' + Rowindex} className={typeof name == "function" && 
                                                ((name(row).type && name(row).type.name == 'Button') ||
                                                 (name(row).props && name(row).props.children &&name(row).props.children[0].type&& name(row).props.children[0].type.name == 'Button'))
                                                    ? "text-center position-sticky end-0 bg-body  " : "text-center fw-normal"}>
                                                    {typeof name == "function" ? name(row, index + '|' + Rowindex) : row[name]}
                                                </td>
                                            )
                                        })}
   
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            {!props.HidePagination && (
                <TablePagination
                    model={paginationModel}
                    PageIndexHandle={PageIndexHandle}
                    PageSizeHandle={PageSizeHandle}
                    HideTotalCount={props.HideTotalCount}
                    rowCountElement={rowCountElement}
                    Count={props.Data && " " + props.Data.length}
                />
            )}
            {!props.HideTotalCount && props.HidePagination && (

                <div className="row g-0 my-2">
                    {rowCountElement}
                </div>
            )}
        </>
    );
}

Table.propTypes = {
    Cols: PropTypes.array.isRequired,
    Rows: PropTypes.array.isRequired,
    Data: PropTypes.array.isRequired,
    Key: PropTypes.string,
    Class: PropTypes.string,
    //Buttons:
    TableElements: PropTypes.array,
    OnTableElmChanged:PropTypes.func,
    TheadTrClass: PropTypes.string,
    ElementClass: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    RowClicked: PropTypes.func,
    HideTotalCount: PropTypes.bool,
    HidePagination: PropTypes.bool,
    RowColor: PropTypes.func,
    TableElementsDisable:PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ]),
};
Table.defaultProps = {
    Class: " ",
    ContainerClass: "tableWrap-md ",
    
    //Buttons: [],
    TheadTrClass: "text-primary bg-light rounded ",
    ElementClass:"",
    HideTotalCount: false,
    HidePagination: false,
    TableElementsDisable:false,
    RowColor: null
};
