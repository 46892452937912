import * as http from "../../../Services/http";

const GetUrl = `/Checkout/ShippingMethod`;
const PostUrl = `/Checkout/OpcSaveShippingMethod`;

export function GetShippingMethod() {
   return http.Get(GetUrl, {}, {});
}

export function SaveShippingMethod(model) {
   return http.Post(PostUrl, model.Body, model.Params);
}
