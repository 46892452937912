import * as http from '../../../Services/http'

const GetUrl = `/Product/CustomerProductReviews`;
const PostUrl = '/Product/AddProductReview'

export function GetCustomerProductReview() {
  return http.Get(GetUrl)
}

export function AddProductReview(model) {
  return http.Post(PostUrl, model.Body, model.Params)
}


