import { useState, useEffect, useRef } from "react";
import { Gallery, Input, Rating, Button } from "../../Components";
import { Link, useParams } from "react-router-dom";
import * as app from "../../Services/AppService";
// Set & API
import PageHandle from "../../Services/UI/PageHandling";
import { setQuantity as setQuantityInProDet } from "../../Features/Products/ProductDetails/reducer";
// Products
import F_ADD_PRODUCT from "../../Features/ShoppingCart/AddProduct";
import F_Product_Details from "../../Features/Products/ProductDetails";
import F_ALSOPURCHASED from "../../Features/Products/AlsoPurchasedProducts";
import F_RELATED from "../../Features/Products/RelatedProducts";

// Redux
import { useDispatch, useSelector } from "react-redux";
// Icons
import { FaShoppingCart } from "react-icons/fa";
import { BiHeart } from "react-icons/bi";
import { DiGitCompare } from "react-icons/di";
import { TfiEmail } from "react-icons/tfi";
import { RxCodesandboxLogo } from "react-icons/rx";
// style
import "./index.css";
import ApiStatusManager from "../../Services/ApiStatusManager";

export default function ProductDetails() {

   let ProductID = useParams().id;
   F_Product_Details.ACTIONS.setRequest(ProductID);

   const dispatch = useDispatch();

   let firstRender = useRef(true);

   let RequestModel = useSelector((state) => F_ADD_PRODUCT.SELECTORS.selectRequest(state));

   useEffect(() => {
      if (!firstRender.current) {
         setControl((old) => ({ ...old, reload: !old.reload }));
      } else {
         firstRender.current = false;
      }
      window.scrollTo(0, 0);
   }, [ProductID]);

   const [control, setControl] = useState({
      reload: true,
      changedValue: false,
   });

   return (
      <PageHandle
         selectAll={F_Product_Details.SELECTORS.selectAll}
         selectError={F_Product_Details.SELECTORS.selectError}
         selectStatus={F_Product_Details.SELECTORS.selectStatus}
         oldData={false}
         Reload={control.reload}
         ParentFolder="Products"
         ChildFolder="ProductDetails"
         Child={(data) => Design(data.product_details_model)}
      />
   );

   async function PostApi(apiData, type) {
      let model = { ...RequestModel };
      if (apiData.product_attributes.length === 0 && !control.changedValue) {
         model[`addtocart_${apiData.id}.EnteredQuantity`] = parseInt(apiData.add_to_cart.entered_quantity);
      }

      let TempModel = { model: model, params: { shoppingCartType: type }, productId: ProductID }

      F_ADD_PRODUCT.ACTIONS.AddProductFromDetails(TempModel, (resSuccess) => {
         app.ShowTopMessageAlert(`${resSuccess.data.message}`);
         if (type === "WishList") dispatch(ApiStatusManager("WishList", "WishList"));
         else if (type === "ShoppingCart") dispatch(ApiStatusManager("ShoppingCart", "Cart"));
      });
   }

   function Design(apiData) {
      function Path() {
         let path = [];
         apiData?.breadcrumb?.category_breadcrumb.map((item, index) => {
            path.push({ Name: item.name, ID: item.id });
         });
         return [{ Name: app.translate("products.breadcrumb.top"), ID: -1 }, ...path].map((item, index) => (
            <Link
               to={`${item.ID == -1 ? "/" : "/categoryDetails/Categorie/" + item.ID}`}
               className="text-decoration-none text-dark"
            >
               {item.Name} <span className="mx-1">/</span>
            </Link>
         ));
      }
      if (Object.keys(apiData).length > 0) {
         return (
            <>
               {apiData.breadcrumb.enabled && (
                  <div className="d-flex align-items-center bg-light rounded w-100" style={{ height: 45, width: 310 }}>
                     <h6 className="h-100 d-inline-block p-1 px-3 pt-3 text-muted">
                        {Path()}
                        {apiData.name}
                     </h6>
                  </div>
               )}
               <div className="container">
                  <div className="row mt-5">
                     <div className="col-lg-6 d-flex flex-wrap justify-content-center">
                        <Gallery
                           ExpandedImg={apiData.default_picture_model && apiData.default_picture_model}
                           Images={apiData.picture_models.length > 1 ? apiData.picture_models : []}
                        />
                     </div>
                     <div className="col-lg-6 text-center">
                        <h3 className="pb-3 border-bottom border-muted">{apiData.name}</h3>
                        <p>{apiData.short_description}</p>
                        {apiData.product_review_overview.allow_customer_reviews && (
                           <Rating
                              Class={"d-flex justify-content-center my-3"}
                              Model={apiData.product_review_overview.product_rating}
                           />
                        )}
                        {apiData.product_review_overview.total_reviews > 0 &&
                           apiData.product_review_overview.allow_customer_reviews && (
                              <p>
                                 <Link
                                    to={"/productReviews/" + apiData.product_review_overview.product_id}
                                    state={{ Name: apiData.name }}
                                    className="text-dark"
                                 >
                                    {apiData.product_review_overview.total_reviews}{" "}
                                    {app.translate("reviews.overview.reviews")}
                                 </Link>
                                 |
                                 <Link
                                    to={"/productReviews/" + apiData.product_review_overview.product_id}
                                    state={{ Name: apiData.name }}
                                    className="text-dark"
                                 >
                                    {app.translate("reviews.overview.addnew")}
                                 </Link>
                              </p>
                           )}
                        {apiData.product_review_overview.total_reviews === 0 &&
                           apiData.product_review_overview.allow_customer_reviews && (
                              <p>
                                 <Link
                                    to={"/productReviews/" + apiData.product_review_overview.product_id}
                                    state={{ Name: apiData.name, Id: apiData.product_review_overview.product_id }}
                                    className="text-dark"
                                 >
                                    {app.translate("reviews.overview.first")}
                                 </Link>
                              </p>
                           )}
                        {apiData.product_manufacturers.length > 0 && (
                           <p>
                              <span className="text-muted">{app.translate("products.manufacturer")}:</span>
                              {apiData.product_manufacturers.map((item, index) => {
                                 return (
                                    <Link
                                       to={"/categoryDetails/manufacturer/" + apiData.product_manufacturers[index].id}
                                       className="text-info text-decoration-none mx-1"
                                       key={index}
                                    >
                                       {apiData.product_manufacturers[index].name}
                                    </Link>
                                 );
                              })}
                           </p>
                        )}
                        {apiData.stock_availability && (
                           <p>
                              <span className="text-muted mx-1">{app.translate("products.availability")}:</span>
                              {apiData.stock_availability}
                           </p>
                        )}
                        {apiData.show_sku && apiData.sku !== null && (
                           <p>
                              <span className="text-muted mx-1">{app.translate("products.sku")}:</span>
                              {apiData.sku}
                           </p>
                        )}
                        {apiData.vendor_model.name !== null && apiData.show_vendor && (
                           <p>
                              <span className={"text-muted mx-1"}>{app.translate("products.vendor") + " : "}</span>
                              <Link
                                 to={"/categoryDetails/vendor/" + apiData.vendor_model.id}
                                 state={{ name: apiData.vendor_model.name || apiData.vendor_model.se_name }}
                                 className="text-info text-decoration-none"
                              >
                                 {apiData.vendor_model.name}
                              </Link>
                           </p>
                        )}
                        {apiData.product_attributes.length > 0 && (
                           <div>
                              <F_Product_Details.COMPONENTS.ProductAttributes
                                 Attributes={apiData.product_attributes}
                                 apiData={apiData}
                              />
                           </div>
                        )}
                        {apiData.is_free_shipping && (
                           <p>
                              <RxCodesandboxLogo className="mx-2 h6 mt-2" />
                              <span className="text-muted">{app.translate("products.freeshipping")}</span>
                           </p>
                        )}
                        {apiData.product_price.old_price && (
                           <p className="text-decoration-line-through text-muted fw-light">
                              <span className="mx-1">{app.translate("products.price.oldprice")}</span>:
                              {apiData.product_price.old_price}
                           </p>
                        )}
                        <h4>
                           <p>
                              {apiData.product_price.call_for_price
                                 ? app.translate("products.callforprice")
                                 : apiData.product_price.price}
                           </p>
                           {apiData.product_price.call_for_price &&
                              apiData.product_price.call_for_pricing_phone_number && (
                                 <p>{apiData.product_price.call_for_pricing_phone_number}</p>
                              )}
                        </h4>
                        {apiData.add_to_cart.minimum_quantity_notification && (
                           <p className="small fw-bold fst-italic mt-4">
                              {apiData.add_to_cart.minimum_quantity_notification}
                           </p>
                        )}
                        <div className="d-flex justify-content-center gap-1">
                           <div style={{ width: "50px" }}>
                              <Input
                                 ContainerClass="mt-0 me-1 border-0"
                                 LabelClass={"d-none"}
                                 Class="text-center border-1 border-dark fw-bold py-2"
                                 Model={
                                    RequestModel[`addtocart_${apiData.id}.EnteredQuantity`] ||
                                    apiData.add_to_cart.entered_quantity
                                 }
                                 OnChange={(newValue) => {
                                    setControl((old) => ({ ...old, changedValue: true }));
                                    let temp = { ...RequestModel };
                                    temp[`addtocart_${apiData.id}.EnteredQuantity`] = parseInt(newValue);
                                    dispatch(F_ADD_PRODUCT.ACTIONS.setRequest(temp));
                                    dispatch(setQuantityInProDet(Number(newValue)));
                                 }}
                              />
                           </div>
                           <Button
                              Class="btn btn-primary px-4 mt-2"
                              Label="shoppingcart.addtocart"
                              Clicked={() => {
                                 PostApi(apiData, "ShoppingCart");
                              }}
                           >
                              <FaShoppingCart className="fs-2" />
                           </Button>
                        </div>
                        <div className="mt-3 d-flex flex-column flex-sm-row flex-wrap justify-content-center gap-2">
                           <Button
                              Label="products.wishlist.addtowishlist"
                              Class="border-0 gray-bg text-muted rounded"
                              Clicked={() => {
                                 PostApi(apiData, "Wishlist");
                              }}
                           >
                              <BiHeart className="fs-5 text-dark my-2" />
                           </Button>
                           {apiData.compare_products_enabled && (
                              <Button
                                 Label={"products.compare.addtocomparelist"}
                                 Class={"gray-bg text-muted rounded"}
                                 Clicked={() => { }}
                              >
                                 <DiGitCompare className="fs-5 me-2 text-dark my-2" />
                              </Button>
                           )}
                           {apiData.email_a_friend_enabled && (
                              <Button
                                 Label={"products.emailafriend"}
                                 Class={"gray-bg text-muted rounded"}
                                 Clicked={() => { }}
                              >
                                 <TfiEmail className="fs-5 me-2 text-dark my-2" />
                              </Button>
                           )}
                        </div>
                     </div>
                  </div>
                  {apiData.full_description && (
                     <div className="text-muted mt-5">
                        <div dangerouslySetInnerHTML={{ __html: apiData.full_description }}></div>
                     </div>
                  )}
                  <hr className="border border-primary" />
                  {apiData.product_tags.length > 0 && (
                     <div>
                        <p>{app.translate("products.tags")}</p>
                        <div className="d-flex gap-3">
                           {apiData.product_tags.map((item, index) => {
                              return (
                                 <p className="small" key={index}>
                                    <Link
                                       to={`/categoryDetails/Product/${item.id}`}
                                       className="text-decoration-none text-muted me-1"
                                    >
                                       {item.name}
                                    </Link>
                                    <span className="small text-muted">({item.product_count})</span>
                                 </p>
                              );
                           })}
                        </div>
                     </div>
                  )}
                  <F_ALSOPURCHASED.COMPONENTS.AlsoPurchasedProducts productID={ProductID} />
                  <F_RELATED.COMPONENTS.DisplayRelatedProducts productID={ProductID} />
               </div>
            </>
         );
      } else {
         return <h1>Loading...</h1>;
      }
   }
}
