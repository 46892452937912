import {createSlice} from "@reduxjs/toolkit";
import * as ResponseModel from "./models/response";
import {fetchData} from "./middleware";
import {CoreInitialState} from "../../../Models/CoreInitialState";
import F_RECENT_VIEWED from ".";

const initialState = {
   ...CoreInitialState,
   data:
      ResponseModel.Type === "ArrayOfObject"
         ? [F_RECENT_VIEWED.MODELS.Response.Model]
         : F_RECENT_VIEWED.MODELS.Response.Model,
};

const reducers = {
   // Local Data Reducers
};

function extraReducers(builder) {
   builder
   .addCase(F_RECENT_VIEWED.ACTIONS.fetchData.pending, (state, action) => {
      state.status = "loading";
   })
   .addCase(F_RECENT_VIEWED.ACTIONS.fetchData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
   })
   .addCase(F_RECENT_VIEWED.ACTIONS.fetchData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
   });
}

export const RecentlyProductsSlice = createSlice({
   name: "RecentlyProducts",
   initialState,
   reducers: {...reducers},
   extraReducers,
});
//test

export default RecentlyProductsSlice.reducer;
