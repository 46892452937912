import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { publicRoutes, PrivateRoutes } from './RouteList';
import ClientLayout from "../Layout/ClientLayout";
import AdminLayout from "../Layout/AdminLayout"
import { RoutesRenderer } from "./RouterRenderer"
import { useSelector } from 'react-redux';
import { selectAll as selectUser } from '../Features/Auth/Login/selector';
export default function AppRoutes() {
    let user = useSelector(state => selectUser(state))
    return (

        <Routes>
            <Route element={(user != null && user.customer_roles.includes('Admin')) ?
                <AdminLayout /> : <ClientLayout />}>
                {RoutesRenderer(publicRoutes)}
            </Route>
        </Routes>

    );

}