import React from "react";
import { useNavigate } from "react-router-dom";
import * as app from '../../../../Services/AppService'
import Logout from "../api";
import { Button } from "../../../../Components";
import { FiLogOut } from "react-icons/fi";


export default function LogOutButton(props) {

    const navigate = useNavigate();

    async function handler() {
        app.ChangeLinesSpinnerStatus(true)
        await Logout(app.getCookie('jwt-Auzhorization'))
            .then(result => {
                app.setCookie("jwt-Auzhorization", '');
                localStorage.setItem('user', null);
                //app.ShowTopMessageAlert(`${result.data}`)
                window.location.reload(true)
                navigate('/')
            }).catch(error => {
                app.ShowTopMessageAlert(`Error: ${error}`, '', 'danger')
            });
        app.ChangeLinesSpinnerStatus(false)
    }

    return (
        <div className="d-flex" style={{ cursor: "pointer" }} onClick={handler} >
            <span className="fs-6 text-muted mx-2 link-primary mt-1"> {app.translate('account.logout')}</span>
            <FiLogOut size='25' />
        </div>
    )
}