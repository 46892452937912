// import { createAsyncThunk } from '@reduxjs/toolkit';
// import Fetch from './api';
// let Type,Params
// export function setRequest(type,params){
//     Type=type;
//     Params=params
// }
// export const fetchData = createAsyncThunk('WishList/fetchData', async () => {
//     const response = await Fetch(Type,Params);
//     return response.data
// })


import { createAsyncThunk } from '@reduxjs/toolkit';
import * as APIs from './api';
import * as app from "../../../Services/AppService"
//WishList

export const fetchData = createAsyncThunk('WishList/fetchData', async () => {
    const response = await APIs.fetchData();
    return response.data
})

export const AddToWishList = (Params, OnSuccessFunc, OnErrorFunc) => {
    app.ChangeLinesSpinnerStatus(true)
    APIs.AddToWishList(Params)
        .then(result => {
            if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
                OnSuccessFunc(result)
        })
        .catch(error => {
            if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
                OnErrorFunc(error)
        })
    app.ChangeLinesSpinnerStatus(false)
}

//ToCardFRomWishList

export const AddToCardFromWishList = (Body, OnSuccessFunc, OnErrorFunc) => {
    app.ChangeLinesSpinnerStatus(true)
    APIs.AddToCardFromWishList(Body)
        .then(result => {
            if (OnSuccessFunc && typeof (OnSuccessFunc === 'function'))
                OnSuccessFunc(result)
        })
        .catch(error => {
            if (OnErrorFunc && typeof (OnErrorFunc === 'function'))
                OnErrorFunc(error)
        })
    app.ChangeLinesSpinnerStatus(false)
}
