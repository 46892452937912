import * as http from "../../../Services/http";
const Url = "/Customer/Info";

export function GetCustomerInfo() {
   return http.Get(Url);
}

export function PostCustomerInfo(model) {
   return http.Post(Url, model.Body,model.Params);
}
