import { createAsyncThunk } from '@reduxjs/toolkit';
import GetShipmentMapTrackingModel from './api';
let id;
export function setRequestId(shipmentId) {
    id = shipmentId
}
export const fetchData = createAsyncThunk('ShipmentMapTrackingModel/fetchData', async () => {
    const response = await GetShipmentMapTrackingModel(id);
    return response.data
})

