import { LineWave } from 'react-loader-spinner'
//import Spinner from 'react-bootstrap/Spinner';
export default function Spinner() {
    
    return (
        <div className="d-flex justify-content-center">
        {/* <img src="Group 105.png" style={{width:"100px"}}/> */}
        <LineWave
            height="250"
            width="250"
            color="#FFCD00 "
            ariaLabel="line-wave"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
        />
        </div>
    )
}