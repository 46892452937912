import {createSlice} from "@reduxjs/toolkit";
import {CoreInitialState} from "../../../Models/CoreInitialState";
import F_TAG_PRODUCTS from ".";
const initialState = {
   ...CoreInitialState,
   data:
      F_TAG_PRODUCTS.MODELS.Response.Type === "ArrayOfObject"
         ? [F_TAG_PRODUCTS.MODELS.Response.Model]
         : F_TAG_PRODUCTS.MODELS.Response.Model,
};

const reducers = {
   // Local Data Reducers
};

function extraReducers(builder) {
   builder
   .addCase(F_TAG_PRODUCTS.ACTIONS.fetchData.pending, (state, action) => {
      state.status = "loading";
   })
   .addCase(F_TAG_PRODUCTS.ACTIONS.fetchData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
   })
   .addCase(F_TAG_PRODUCTS.ACTIONS.fetchData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
   });
}

export const GetProductsByTagSlice = createSlice({
   name: "GetProductsByTag",
   initialState,
   reducers: {...reducers},
   extraReducers,
});
//test

export default GetProductsByTagSlice.reducer;
